import React from 'react'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

export default function Player({ closeVideo, videoSrc }) {
    return (
        <div className="video-player" >
            <div className="video-player-opa" onClick={closeVideo} ></div>
            <div className="close">
            <CloseOutlinedIcon style={{color:"#5fb597",fontSize:'40px',cursor:'pointer'}} onClick={closeVideo} />
            </div>
            <div className="video-player-content">
                <video src={videoSrc} controls ></video>
            </div>
        </div>
    )
}
