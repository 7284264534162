import React, { useEffect, useState } from 'react'
import axios from 'axios'
import CloseIcon from '@material-ui/icons/Close';

export default function FotoPacient({ fazaId, pacientId, closeFotoPacient }) {

    const [fotot, setFotot] = useState([])
    const [bigFoto, setBigFoto] = useState("")


    useEffect(() => {

        axios.post('https://panel.at-aligners.com/server/lab/getFotoPacient', { pacient_id: pacientId, faza_id: fazaId }).then(res => {
            setFotot(res.data)
            if (res.data.length !== 0) {
                setBigFoto(res.data[0].foto)
            } else {
                setBigFoto("")
            }
        })

    }, [])

    return (
        <div className="lab-foto-pacient" >
            <div className="lab-foto-pacient-opa" onClick={closeFotoPacient} ></div>
            <div className="lab-foto-pacient-content">
                {fotot.length === 0 ? <h1>Pacienti nuk ka foto</h1> :
                    <>
                        <CloseIcon style={{ top: '5px', right: '5px', position: 'absolute', cursor: 'pointer', color: 'red', fontSize: '30px' }} onClick={closeFotoPacient} />
                        <div className="lab-foto-pacient-content-big-foto">
                            <img className="img-res" src={`https://panel.at-aligners.com/server/files/${bigFoto}`} alt="" />
                        </div>
                        <div className="lab-foto-pacient-content-galeria">
                            {fotot.map(foto => (
                                <div className="lab-foto-pacient-content-galeria-item" onClick={() => setBigFoto(foto.foto)} >
                                    <img className="img-res" src={`https://panel.at-aligners.com/server/files/${foto.foto}`} alt="" />
                                </div>
                            ))}
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

