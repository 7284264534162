import React from 'react'
import { Switch, Route } from "react-router-dom";
import Pacientet from './Pacientet';
import ShtoPaciente from './ShtoPaciente';
import Profili from './Profili';
import SinglePacient from './SinglePacient';

export default function MjekBody() {
    return (
        <div  className="dash-body" >
        <Switch>
            <Route exact path="/mjek/pacientet" component={Pacientet} />
            <Route exact path="/mjek/shtoPaciente" component={ShtoPaciente} />
            <Route exact path="/mjek/profili"  component={Profili}/>
            <Route exact path="/mjek/pacientet/:id" component={SinglePacient}  />
        </Switch>
    </div>
    )
}
