import React, { useEffect } from 'react'
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import LabBody from './components/LabBody';
import axios from 'axios';
import Alerts from '../../utilities/Alerts';
export default function Lab({ history }) {

    const checkLogin = () => {
        if (
            !localStorage.getItem('auth') ||
            localStorage.getItem('token') === "" ||
            JSON.parse(localStorage.getItem('token')) === null ||
            localStorage.getItem('auth') === null ||
            localStorage.getItem('id') === null ||
            localStorage.getItem('id') === ""
        ) {
            logout();
        }
    }


    const checkIsLoggedIn = () => {


        if (localStorage.getItem('token') !== "" || JSON.parse(localStorage.getItem('token')) !== null) {
            axios.post('https://panel.at-aligners.com/server/user/checkLogin', { user_id: JSON.parse(localStorage.getItem('id')), user_token: JSON.parse(localStorage.getItem('token')) }).then(res => {


                if (res.data.status === 0) {
                    history.push('/')
                    localStorage.removeItem('token');
                    localStorage.removeItem('auth');
                }

            })
        }



    }

    useEffect(() => {

        setInterval(() => checkIsLoggedIn(), 60000)

    }, [])


    // useEffect(() => {
    //     checkLogin()

    //     setInterval(() => checkLogin(), 10000)
    // }, [])

    const logout = () => {


        axios.post('https://panel.at-aligners.com/server/user/logout', { user_id: JSON.parse(localStorage.getItem('id')) }).then(res => {
            if (res.data.status === 1) {
                history.push('/')
                localStorage.removeItem('token');
                localStorage.removeItem('auth');
            }
        })
    }


    return (
        <>
            <Alerts />
            <Sidebar logout={logout} />
            <Header logout={logout} />
            <LabBody />
        </>
    )
}
