import React, { useState, useContext } from 'react'
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import axios from 'axios';
import Alerts from '../../utilities/Alerts';
import AlertContext from '../../context/AlertContext';


function Login({ history }) {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false)
    const alertContext = useContext(AlertContext);

    const passwordHide = () => {
        setShowPassword(!showPassword)
    }


    const onSubmit = (e) => {
        e.preventDefault();
        axios.post('https://panel.at-aligners.com/server/user/login', { username: userName, password }).then((res) => {
            if (res.data.status === 1 && res.data.role === "2") {
                localStorage.setItem("token", JSON.stringify(res.data.token));
                localStorage.setItem("auth", true);
                localStorage.setItem("id", JSON.stringify(res.data.id));
                alertContext.setAlert(`${res.data.message}`, "success");
                setTimeout(() => history.push("/lab"), 2000);
            } else if (res.data.status === 1 && res.data.role === "3") {
                localStorage.setItem("token", JSON.stringify(res.data.token));
                localStorage.setItem("auth", true);
                localStorage.setItem("id", JSON.stringify(res.data.id));
                alertContext.setAlert(`${res.data.message}`, "success");
                setTimeout(() => history.push("/mjek/pacientet"), 2000);
            } else {
                alertContext.setAlert(`${res.data.message}`, "error");
            }
        });
    }

    return (
        <>
            <Alerts />
            <div className="at-login">
                <div className="at-login-left" >
                    <img className="login-foto-desktop img-res" src="/images/login-form-foto.jpg" alt="login" />
                    <img  className="login-foto-mobile img-res" src="/images/login-foto-mob.jpg" alt="" />
                </div>
                <div className="at-login-right" >
                    <div>
                        <img className="img-res" src="/images/at-logo-login.png" alt="" />
                    </div>

                    <form className="at-login-form" onSubmit={onSubmit} >
                        <TextField
                            style={{ margin: '50px 0 20px' }}
                            variant="filled"
                            label="Username"
                            value={userName}
                            onChange={(e) => {
                                setUserName(e.target.value)
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon />
                                    </InputAdornment>
                                ),
                            }} />
                        <TextField
                            variant="filled"
                            label="Password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                            type={showPassword ? 'text' : 'password'}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {showPassword ? <Visibility style={{ cursor: 'pointer' }} onClick={() => passwordHide()} /> : <VisibilityOff style={{ cursor: 'pointer' }} onClick={() => passwordHide()} />}
                                    </InputAdornment>
                                )
                            }}
                        />
                        <div className="form-utilities">
                            <div className="form-utilities-left">
                      
                            </div>

                            {/* <p className="form-utilities-forget fs-12 fw-300" >Keni harruar fjalekalimin?</p> */}

                        </div>
                        <button className="form-login-sbumit fs-14 fw-600" >
                            kyçu
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Login;