import React, { useState, useContext } from 'react'
import LogoForm from '../../../images/forma-lab-logo.png'
import Njolla from '../../../images/form-faza-number-back.png'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import axios from 'axios';
import AlertContext from '../../../context/AlertContext'
import NofullaSiper from '../../../utilities/NofullaSiper'
import NofullaPosht from '../../../utilities/NofullaPosht'



export default function FormaLab({ emriPac, mbiemriPac, pacientId, fazaId, closeTrajtimi, getPacient, openLoading, closeLoading }) {


    const alertContext = useContext(AlertContext)

    const [maskerinat, setMaskerinat] = useState(
        [
            {
                id: 1,
                color: 'red',
                nofulla_siper: true,
                nofulla_posht: true,
                name: 'Maskerina 1',
            }
        ]
    )

    const addMaskerin = () => {
        setMaskerinat(prev => [...prev, { nofulla_siper: true, nofulla_posht: true, color: '#E60019', name: `Makserina ${maskerinat.length + 1}`, id: maskerinat.length + 1 }])
    }

    const updateColor = (index, colors) => {
        let newData = [...maskerinat];
        newData[index].color = colors
        setMaskerinat(newData)
    }

    const updateNofullaPosht = (index, state) => {
        let newData = [...maskerinat];
        newData[index].nofulla_posht = state
        setMaskerinat(newData)
    }

    const updateNofullaSiper = (index, state) => {
        let newData = [...maskerinat];
        newData[index].nofulla_siper = state
        setMaskerinat(newData)
    }

    const makePdf = () => {
        openLoading()
        const input = document.getElementById('divToPrint')
        html2canvas(input).then((canvas) => {
            const pdf = new jsPDF('p', 'pt', 'a4');
            const imageData = canvas.toDataURL('image/png')
            const pageheight = pdf.internal.pageSize.getHeight();
            const imgwidth = 595;
            const imgheight = canvas.height * imgwidth / canvas.width;
            let position = 0;
            let heightleft = imgheight;
            pdf.addImage(imageData, 'JPEG', 0, 0, imgwidth, imgheight);
            heightleft -= pageheight;

            while (heightleft >= 0) {
                position = heightleft - imgheight;
                pdf.addPage();
                pdf.addImage(imageData, 0, position, imgwidth, imgheight)
                heightleft -= pageheight
            }

            let blob = pdf.output('blob')

            var formData = new FormData();
            formData.append('pdf', blob);
            formData.append('emri', emriPac);
            formData.append('mbiemri', mbiemriPac);
            formData.append('fazaId', fazaId);
            formData.append('pacientId', pacientId);


            axios.post('https://panel.at-aligners.com/server/lab/addPlaniTrajtimit', formData).then(res => {

                if (res.status === 200) {
                    closeLoading()
                }

                if (res.data.status === 1) {
                    closeTrajtimi()
                    getPacient()
                    alertContext.setAlert(`${res.data.message}`, "success");
                } else {
                    alertContext.setAlert(`${res.data.message}`, "error");
                }
            })

        })
    }
    return (
        <div className="forma-lab" >
            <div className="forma-lab-opa" onClick={closeTrajtimi} ></div>
            <div className="forma-lab-content">

                <div id="divToPrint" className="forma-lab-content-body">


                    <div className="forma-lab-content-header">
                        <div className="forma-lab-content-header-logo">
                            <img className="img-res" src={LogoForm} alt="" />
                        </div>
                    </div>

                    <div className="forma-lab-content-body-first-form">

                        <div className="forma-lab-content-body-first-form-inputs">

                            <div className="forma-lab-content-body-first-form-inputs-content">
                                <label className="fs-8 fw-normal" htmlFor="doctor">Doctor</label>
                                <input type="text" id="doctor" />
                            </div>


                            <div className="forma-lab-content-body-first-form-inputs-content">
                                <label className="fs-8 fw-normal" htmlFor="data">Data</label>
                                <input type="text" id="data" />
                            </div>

                        </div>


                        <div className="forma-lab-content-body-first-form-inputs">

                            <div className="forma-lab-content-body-first-form-inputs-content">
                                <label className="fs-8 fw-normal" htmlFor="pac">Pacienti</label>
                                <input type="text" id="pac" />
                            </div>


                            <div className="forma-lab-content-body-first-form-inputs-content">
                                <label className="fs-8 fw-normal" htmlFor="kli">Klinika</label>
                                <input type="text" id="kli" />
                            </div>

                        </div>

                        <div className="forma-lab-content-body-first-form-inputs">

                            <div className="forma-lab-content-body-first-form-inputs-content forma-lab-content-body-first-form-inputs-content-radios ">
                                <label className="fs-8 fw-normal" htmlFor="pac">Niveli</label>

                                <div className="forma-lab-content-body-first-form-inputs-content-radios-inputs">
                                    <label className="fs-8" htmlFor="a">A</label>
                                    <input type="radio" id="a" name="nivel" />
                                </div>

                                <div className="forma-lab-content-body-first-form-inputs-content-radios-inputs">
                                    <label className="fs-8" htmlFor="b">B</label>
                                    <input type="radio" id="b" name="nivel" />
                                </div>

                                <div className="forma-lab-content-body-first-form-inputs-content-radios-inputs">
                                    <label className="fs-8" htmlFor="c">C</label>
                                    <input type="radio" id="c" name="nivel" />
                                </div>

                                <div className="forma-lab-content-body-first-form-inputs-content-radios-inputs">
                                    <label className="fs-8" htmlFor="plus">Plus</label>
                                    <input type="radio" id="plus" name="nivel" />
                                </div>

                            </div>

                        </div>


                    </div>


                    <div className="forma-lab-content-body-second-form">
                        <p className="forma-lab-content-body-second-form-title fs-10 fw-600" ><span className="fs-24 fw-normal">1</span>Faza
                            <img src={Njolla} alt="" />
                        </p>
                        <div className="forma-lab-content-body-second-form-trajtimit">
                            <div className="forma-lab-content-body-second-form-trajtimit-left">
                                <p className="fs-9" >Nofullat <br /> e trjatuara</p>
                            </div>
                            <svg className="logic-binary" xmlns="http://www.w3.org/2000/svg" width="19" height="34" viewBox="0 0 19 34">
                                <g id="Group_1456" data-name="Group 1456" transform="translate(0.158 0.421)">
                                    <rect id="Rectangle_490" data-name="Rectangle 490" width="1" height="34" transform="translate(-0.158 -0.421)" fill="#888" />
                                    <rect id="Rectangle_491" data-name="Rectangle 491" width="1" height="19" transform="translate(-0.158 33.579) rotate(-90)" fill="#888" />
                                    <rect id="Rectangle_492" data-name="Rectangle 492" width="1" height="19" transform="translate(-0.158 0.579) rotate(-90)" fill="#888" />
                                </g>
                            </svg>
                            <div className="forma-lab-content-body-second-form-trajtimit-right">
                                <div className="forma-lab-content-body-second-form-trajtimit-right-top">
                                    <label className="fs-9" htmlFor="#">
                                        Nofulla siperme

                                        <input type="checkbox" style={{ borderRadius: '50%', marginLeft: '2px' }} name="" id="" />
                                    </label>
                                    <svg className="dash" xmlns="http://www.w3.org/2000/svg" width="43" height="1" viewBox="0 0 43 1">
                                        <rect id="Rectangle_490" data-name="Rectangle 490" width="1" height="43" transform="translate(0 1) rotate(-90)" fill="#888" />
                                    </svg>
                                    <label className="fs-9" htmlFor="#">
                                        Numri maskerinave

                                        <input type="text" name="" id="" />
                                    </label>
                                    <svg className="dash" xmlns="http://www.w3.org/2000/svg" width="43" height="1" viewBox="0 0 43 1">
                                        <rect id="Rectangle_490" data-name="Rectangle 490" width="1" height="43" transform="translate(0 1) rotate(-90)" fill="#888" />
                                    </svg>
                                    <label className="fs-9" htmlFor="#">
                                        Shembull

                                        <input type="checkbox" style={{ borderRadius: '50%', marginLeft: '2px' }} name="" id="" />
                                    </label>
                                </div>
                                <div className="forma-lab-content-body-second-form-trajtimit-right-bottom">
                                    <label className="fs-9" htmlFor="#">
                                        Nofulla poshtme

                                        <input type="checkbox" style={{ borderRadius: '50%', marginLeft: '2px' }} name="" id="" />
                                    </label>
                                    <svg className="dash" xmlns="http://www.w3.org/2000/svg" width="43" height="1" viewBox="0 0 43 1">
                                        <rect id="Rectangle_490" data-name="Rectangle 490" width="1" height="43" transform="translate(0 1) rotate(-90)" fill="#888" />
                                    </svg>
                                    <label className="fs-9" htmlFor="#">
                                        Numri maskerinave

                                        <input type="text" name="" id="" />
                                    </label>
                                    <svg className="dash" xmlns="http://www.w3.org/2000/svg" width="43" height="1" viewBox="0 0 43 1">
                                        <rect id="Rectangle_490" data-name="Rectangle 490" width="1" height="43" transform="translate(0 1) rotate(-90)" fill="#888" />
                                    </svg>
                                    <label className="fs-9" htmlFor="#">
                                        Shembull

                                        <input type="checkbox" style={{ borderRadius: '50%', marginLeft: '2px' }} name="" id="" />
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="forma-lab-content-body-maskerinat">


                        {maskerinat.map((maskerina, index) => (
                            <>
                                <div key={maskerina.id} className="forma-lab-content-body-maskerinat-item">
                                    <div className="forma-lab-content-body-maskerinat-item-titles">
                                        <p className="fs-14" > {maskerina.name}  </p>

                                    </div>

                                    <div className="forma-lab-content-body-maskerinat-item-top">
                                        <div className="forma-lab-content-body-maskerinat-item-top-titles">
                                            <p className="fs-9 fw-600" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22.067" height="21.6" viewBox="0 0 22.067 21.6">
                                                    <g id="Group_1819" data-name="Group 1819" transform="translate(-30.322 -285)">
                                                        <path id="Path_657" data-name="Path 657" d="M41.332,310.273a11.036,11.036,0,0,0,7.857,10.566v-7.979h-3.22a.945.945,0,0,1-.792-.377.566.566,0,0,1,.073-.719l6.4-6.857a1.046,1.046,0,0,1,1.437,0l6.4,6.857a.594.594,0,0,1,.169.4.568.568,0,0,1-.1.314.941.941,0,0,1-.791.377h-3.22v7.979a11.033,11.033,0,1,0-14.21-10.566" transform="translate(-11.01 -14.24)" fill="#3a4348" />
                                                    </g>
                                                </svg>
                                                Nofulla e siperme
                                                <input type="checkbox" style={{ marginLeft: '6px' }} onChange={(e) => {
                                                    updateNofullaSiper(index, e.target.checked)
                                                }} checked={maskerina.nofulla_siper} />
                                            </p>
                                            <div className="ngjyrat">

                                                <p className="fs-9" >0.10 <input type="radio" name="color" id="" onClick={() => updateColor(index, '#E60019')} /> <span  ></span></p>
                                                <p className="fs-9" >0.20 <input type="radio" name="color" id="" onClick={() => updateColor(index, '#144295')} /> <span ></span></p>
                                                <p className="fs-9" >0.30 <input type="radio" name="color" id="" onClick={() => updateColor(index, '#9BC347')} /><span  ></span></p>

                                            </div>

                                        </div>

                                        {maskerina.nofulla_siper === true &&
                                            <NofullaSiper color={maskerina.color} />

                                        }




                                        <div className="forma-lab-content-body-maskerinat-item-top-ndrysho" style={{ display: maskerina.nofulla_siper === false ? 'none' : '' }} >
                                            <p className="fs-9 forma-lab-content-body-maskerinat-item-top-ndrysho-title" htmlFor="#">Ndrysho <br /> Maskerinat</p>
                                            <svg className="logic-binary" xmlns="http://www.w3.org/2000/svg" width="19" height="34" viewBox="0 0 19 34">
                                                <g id="Group_1456" data-name="Group 1456" transform="translate(0.158 0.421)">
                                                    <rect id="Rectangle_490" data-name="Rectangle 490" width="1" height="34" transform="translate(-0.158 -0.421)" fill="#888" />
                                                    <rect id="Rectangle_491" data-name="Rectangle 491" width="1" height="19" transform="translate(-0.158 33.579) rotate(-90)" fill="#888" />
                                                    <rect id="Rectangle_492" data-name="Rectangle 492" width="1" height="19" transform="translate(-0.158 0.579) rotate(-90)" fill="#888" />
                                                </g>
                                            </svg>

                                            <div className="forma-lab-content-body-maskerinat-item-top-ndrysho-inputs">
                                                <div className="forma-lab-content-body-maskerinat-item-top-ndrysho-inputs-input">
                                                    <p className="fs-8" >2 JAVE</p>
                                                    <input type="checkbox" name="" id="" />
                                                </div>
                                                <div className="forma-lab-content-body-maskerinat-item-top-ndrysho-inputs-input">
                                                    <p className="fs-8">Tjeter</p>
                                                    <input type="text" name="" id="" />
                                                </div>
                                            </div>


                                            <div className="forma-lab-content-body-maskerinat-item-top-shenime">
                                                <p className="forma-lab-content-body-maskerinat-item-top-shenime-title fs-7">Shenime</p>
                                                <textarea name="" id="" cols="30" rows="3"></textarea>
                                            </div>

                                        </div>



                                    </div>



                                    <div className="forma-lab-content-body-maskerinat-item-bottom">
                                        <div className="forma-lab-content-body-maskerinat-item-top-titles">
                                            <p className="fs-9 fw-600" >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22.067" height="21.6" viewBox="0 0 22.067 21.6">
                                                    <g id="Group_1819" data-name="Group 1819" transform="translate(-30.322 -285)">
                                                        <path id="Path_657" data-name="Path 657" d="M41.332,310.273a11.036,11.036,0,0,0,7.857,10.566v-7.979h-3.22a.945.945,0,0,1-.792-.377.566.566,0,0,1,.073-.719l6.4-6.857a1.046,1.046,0,0,1,1.437,0l6.4,6.857a.594.594,0,0,1,.169.4.568.568,0,0,1-.1.314.941.941,0,0,1-.791.377h-3.22v7.979a11.033,11.033,0,1,0-14.21-10.566" transform="translate(-11.01 -14.24)" fill="#3a4348" />
                                                    </g>
                                                </svg>
                                                Nofulla e poshtme
                                                <input type="checkbox" style={{ marginLeft: '6px' }} onChange={(e) => {
                                                    updateNofullaPosht(index, e.target.checked)
                                                }} checked={maskerina.nofulla_posht} />
                                            </p>
                                            <div className="ngjyrat">

                                                <p className="fs-9" >0.10 <input type="radio" name="color" id="" onClick={() => updateColor(index, '#E60019')} /> <span  ></span></p>
                                                <p className="fs-9" >0.20 <input type="radio" name="color" id="" onClick={() => updateColor(index, '#144295')} /> <span ></span></p>
                                                <p className="fs-9" >0.30 <input type="radio" name="color" id="" onClick={() => updateColor(index, '#9BC347')} /><span  ></span></p>

                                            </div>
                                        </div>

                                        {
                                            maskerina.nofulla_posht === true &&
                                            <NofullaPosht color={maskerina.color} />
                                        }

                                        <div className="forma-lab-content-body-maskerinat-item-top-ndrysho" style={{ display: maskerina.nofulla_posht === false ? 'none' : '' }} >
                                            <p className="fs-9 forma-lab-content-body-maskerinat-item-top-ndrysho-title" htmlFor="#">Ndrysho <br /> Maskerinat</p>
                                            <svg className="logic-binary" xmlns="http://www.w3.org/2000/svg" width="19" height="34" viewBox="0 0 19 34">
                                                <g id="Group_1456" data-name="Group 1456" transform="translate(0.158 0.421)">
                                                    <rect id="Rectangle_490" data-name="Rectangle 490" width="1" height="34" transform="translate(-0.158 -0.421)" fill="#888" />
                                                    <rect id="Rectangle_491" data-name="Rectangle 491" width="1" height="19" transform="translate(-0.158 33.579) rotate(-90)" fill="#888" />
                                                    <rect id="Rectangle_492" data-name="Rectangle 492" width="1" height="19" transform="translate(-0.158 0.579) rotate(-90)" fill="#888" />
                                                </g>
                                            </svg>

                                            <div className="forma-lab-content-body-maskerinat-item-top-ndrysho-inputs">
                                                <div className="forma-lab-content-body-maskerinat-item-top-ndrysho-inputs-input">
                                                    <p className="fs-8" >2 JAVE</p>
                                                    <input type="checkbox" name="" id="" />
                                                </div>
                                                <div className="forma-lab-content-body-maskerinat-item-top-ndrysho-inputs-input">
                                                    <p className="fs-8">Tjeter</p>
                                                    <input type="text" name="" id="" />
                                                </div>
                                            </div>

                                            <div className="forma-lab-content-body-maskerinat-item-top-shenime">
                                                <p className="forma-lab-content-body-maskerinat-item-top-shenime-title fs-7">Shenime</p>
                                                <textarea name="" id="" cols="30" rows="3"></textarea>
                                            </div>

                                        </div>




                                    </div>
                                </div>
                            </>
                        ))}


                        <div className="forma-lab-content-body-maskerinat-shto">
                            <button className="forma-lab-content-body-maskerinat-shto-btn" onClick={() => addMaskerin()} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                    <g id="Group_1477" data-name="Group 1477" transform="translate(-1327.92 -3421.92)">
                                        <circle id="Ellipse_159" data-name="Ellipse 159" cx="7" cy="7" r="7" transform="translate(1327.92 3421.92)" fill="#fff" />
                                        <g id="Group_1476" data-name="Group 1476" transform="translate(1332 3426)">
                                            <path id="Path_818" data-name="Path 818" d="M-86.332,272.518H-88.8v2.489h-1.039v-2.489h-2.455v-.97h2.455v-2.5H-88.8v2.5h2.466Z" transform="translate(92.292 -269.048)" fill="#242424" />
                                        </g>
                                    </g>
                                </svg>
                                Shto maskerin
                            </button>
                        </div>

                    </div>

                    <div className="shenime-rendesishme">
                        <p className="shenime-rendesishme-title">Shenime te rendesishme</p>
                        <textarea name="" id="" cols="30" rows="10"></textarea>
                    </div>

                </div>

                <div className="forma-lab-content-buttons">
                    <button className="forma-lab-content-buttons-btn-anullo fs-14 fw-500" onClick={closeTrajtimi}  >Anullo</button>
                    <button onClick={makePdf} className="forma-lab-content-buttons-btn-ruaj fs-14 fw-500 ">Ruaj</button>
                </div>

            </div>

        </div>
    )
}
