import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Person from '../../../images/person.svg';
import List from '../../../images/list-text.svg';
import Add from '../../../images/add.svg';
import Logout from '../../../images/logout.svg';
import ArrowDown from '../../../images/down-arrow.svg';
import axios from 'axios'
import UserLogo from '../../../images/icon-login.png'
import CollapseContext from '../../../context/CollapseContex';
import AlertContext from '../../../context/AlertContext';
import Loading from '../../../utilities/Loading';

export default function Sidebar({ logout }) {


    const alertContext = useContext(AlertContext)
    const collapseContex = useContext(CollapseContext)
    const { value } = collapseContex;
    const [activeLink, setActiveLink] = useState('lab')
    const [dropdownLinks, showDropdownLinks] = useState(false);
    const [user, setUser] = useState({})
    const [imageProfile, setImageProfile] = useState("")
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        if (window.location.pathname === '/lab') {
            setActiveLink('lab')
        } else if (window.location.pathname === '/lab/pacientet') {
            setActiveLink('pacient')
        } else if (window.location.pathname === '/lab/shtoKlinike') {
            setActiveLink('shtoKli')
        } else if (window.location.pathname === '/lab/shtoPaciente') {
            setActiveLink('shtoPac')
        } else if (window.location.pathname === '/lab/profili') {
            setActiveLink('Profili')
        }

        let token = JSON.parse(localStorage.getItem('token'));

        axios.post('https://panel.at-aligners.com/server/lab/getProfile', { token: token }).then(res => {
            if (res.status === 200) {
                setLoading(false)
            }
            setUser(res.data[0])
        })

    }, [])

    console.log(user)

    const updateProfilePhoto = () => {

        const foto = new FormData();
        foto.append('foto', imageProfile);
        foto.append('user_id', JSON.parse(localStorage.getItem('id')))

        setLoading(true)

        axios.post('https://panel.at-aligners.com/server/user/uploadProfilePicture', foto).then(res => {
            if (res.data.status === 1) {
                setLoading(false)
                setImageProfile("")
                alertContext.setAlert(`${res.data.message}`, 'success')
                axios.post('https://panel.at-aligners.com/server/mjek/getProfile', { token: JSON.parse(localStorage.getItem('token')) }).then(res => {
                    setUser(res.data[0])
                })
            }

        })


    }

    return (
        <>
            {loading && <Loading />}
            {value === true &&
                <div className="sidebar-opa" onClick={() => collapseContex.setFalse()} ></div>
            }
            <div className={value === true ? "sidebar sidebar-open " : "sidebar"} >

                <div style={{ width: '100%' }} >
                    <div className="sidebar-profile">



                        <label className="sidebar-profile-image" htmlFor="profile" >
                            <input type="file" name="" hidden id="profile" onChange={(e) => {
                                setImageProfile(e.target.files[0])
                            }} />

                            <img className="img-res" src={user.user_image_profile === "" ? UserLogo : `https://panel.at-aligners.com/server/files/${user.user_image_profile}`} alt="" />
                        </label>
                        {imageProfile !== "" &&
                            <div className="foto-buttons">
                                <button className="foto-buttons-ngarko fs-14 fw-500" onClick={() => {
                                    updateProfilePhoto()
                                }} > Ngarko Foton</button>
                                <button className="foto-buttons-anullo fs-14 fw-500" onClick={() => {
                                    setImageProfile("")
                                }} >Anullo</button>
                            </div>
                        }

                        <p className="sidebar-profile-name fs-16 fw-bold" >{user.laborator_pergjegjes} {user.laborator_mbiemer}</p>
                        <p className="sidebar-profile-msg fs-12 fw-300" >Mire se vini!</p>
                    </div>

                    <div className="sidebar-links">
                        <li className={activeLink === 'lab' || activeLink === 'pacient' ? 'sidebar-link-active' : ''}  >
                            <img src={List} alt="" />

                            {activeLink !== 'lab' && activeLink !== 'pacient' ? <Link onClick={() => {
                                collapseContex.setFalse()
                                setActiveLink('lab')
                            }} className="sidebar-links-link fs-16 fw-normal " to="/lab" >Lista e Doktoreve</Link> : ''}
                            {activeLink === 'lab' && <Link onClick={() => {
                                setActiveLink('lab')
                                collapseContex.setFalse()
                            }}
                                className="sidebar-links-link fs-16 fw-normal " to="/lab" >Lista e Doktoreve</Link>}
                            {activeLink === 'pacient' && <Link onClick={() => {
                                setActiveLink('pacient')
                                collapseContex.setFalse()
                            }} className="sidebar-links-link fs-16 fw-normal " to="/lab/pacientet" >Lista e Pacienteve</Link>}

                            <img onClick={() => showDropdownLinks(!dropdownLinks)} className="sidebard-dropdown" style={{ marginLeft: '15px', cursor: 'pointer' }} src={ArrowDown} alt="" />
                            {dropdownLinks &&
                                <div className="dropdown-links">
                                    <Link onClick={() => {
                                        showDropdownLinks(false)
                                        setActiveLink('lab')
                                    }} className="fs-16 fw-normal " to="/lab" >Lista e Doktoreve</Link>
                                    <Link

                                        onClick={() => {
                                            showDropdownLinks(false)
                                            setActiveLink('pacient')
                                        }}
                                        className="fs-16 fw-normal " to="/lab/pacientet" >Lista e Pacienteve</Link>
                                </div>}
                        </li>

                        <li className={activeLink === 'shtoKli' ? 'sidebar-link-active' : ''} onClick={() => collapseContex.setFalse()}>
                            <img src={Add} alt="" />
                            <Link onClick={() => setActiveLink('shtoKli')} className="sidebar-links-link fs-16 fw-normal" to="/lab/shtoKlinike" >Shto Klinik</Link></li>
                        <li className={activeLink === 'shtoPac' ? 'sidebar-link-active' : ''} onClick={() => collapseContex.setFalse()}>

                            <img src={Add} alt="" />
                            <Link onClick={() => setActiveLink('shtoPac')} className="sidebar-links-link fs-16 fw-normal" to="/lab/shtoPaciente" >Shto Pacient</Link></li>
                        <li className={activeLink === 'Profili' ? 'sidebar-link-active' : ''} onClick={() => collapseContex.setFalse()}>
                            <img src={Person} alt="" />
                            <Link onClick={() => setActiveLink('Profili')} className="sidebar-links-link fs-16 fw-normal" to="/lab/profili" >Profili Im</Link></li>
                    </div>
                </div>

                <div className="sidebar-logout"  >
                    <li onClick={logout} > <img src={Logout} alt="" /> <p className="fs-16 fw-normal" >Logut</p></li>
                </div>

            </div>
        </>
    )

}
