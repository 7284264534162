import React, {useState,useContext,useEffect} from 'react'
import Footer from '../../../utilities/footer';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import LogoTransparent from '../../../images/at-transparent.png'
import axios from 'axios';
import Alert from '../../../utilities/Alerts';
import AlertContext from '../../../context/AlertContext';

export default function ShtoPaciente() {

    const alertContext = useContext(AlertContext);
    const [emri,setEmri] = useState("");
    const [mbiemri,setMbiemri] = useState("");
    const [mosha,setMosha] = useState(0);
    const [gjinia,setGjinia] = useState("")
    const [klinika,setKlinika] = useState(0);
    const [dataRegj,setDataRegj] =  useState("")
    const [klinikat,setKlinikat] = useState([]);


    useEffect(() => {
        axios.post('https://panel.at-aligners.com/server/lab/getClinics', { lab_id: localStorage.getItem('id') }).then(res => {
            setKlinikat(res.data)
        })
    },[])


    const onSubmit = (e) => {
        e.preventDefault();
        axios.post('https://panel.at-aligners.com/server/lab/addPacient',{

                emri,
                mbiemer : mbiemri,
                mosha,
                gjinia,
                data_regj : dataRegj,
                mjek_id : klinika


        }).then(res => {
            if(res.data.status === 0){
                alertContext.setAlert(`${res.data.message}`, "error");
                
            }else{
                alertContext.setAlert(`${res.data.message}`, "success");
                axios.post('https://panel.at-aligners.com/server/user/addNotification', { to_user : JSON.parse(klinika) , link : "" ,message : "Laboratori shtoi nje pacient te ri per ju" , type : 3  } )
                setEmri("")
                setMbiemri("")
                setMosha("")
                setKlinika("")
                setDataRegj("")
                setGjinia("")
                
            }
        })
    }

    return (
        <>
        <Alert />
        <div className="shtopacient">
            <div className="shtopacient-header">
                <p className="shtopacient-header-title fs-22 fw-bold">Regjistro nje pacient</p>
            </div>
            <section className="shtopacient-content" >
                <form className="shtopacient-content-form" onSubmit={onSubmit}>

                    <div className="shtopacient-content-form-items" >
                        <div className="shtopacient-content-form-col-left">
                            <div className="shtopacient-content-form-col-left-item">
                                <label htmlFor="">Emri</label>
                                <TextField variant="outlined" onChange={(e) => setEmri(e.target.value)} value={emri} />
                            </div>
                            <div className="shtopacient-content-form-col-left-item">
                                <label htmlFor="">Mosha</label>
                                <TextField type="number" variant="outlined" onChange={(e) => setMosha(e.target.value)} value={mosha} />
                            </div>
                            <div className="shtopacient-content-form-col-left-item">
                                <label htmlFor="">Klinika pergjegjese</label>
                                <Select variant="outlined" className="klinika-select" onChange={(e) => setKlinika(e.target.value)} value={klinika} >
                                    <MenuItem value="" >None</MenuItem>
                                    {klinikat.map(klinika => (
                                                <MenuItem value={klinika.user_id}>{klinika.klinika}</MenuItem>

                                    ))}

                                </Select>
                            </div>
                        </div>



                        <div className="shtopacient-content-form-col-right">
                            <div className="shtopacient-content-form-col-left-item">
                                <label htmlFor="">Mbiemri</label>
                                <TextField variant="outlined" onChange={(e) => setMbiemri(e.target.value)} value={mbiemri}/>
                            </div>
                            <div className="shtopacient-content-form-col-left-item">
                                <label htmlFor="">Gjinia</label>
                                <Select variant="outlined" className="klinika-select" onChange={(e) => setGjinia(e.target.value)} value={gjinia} >
                                    <MenuItem value="" >None</MenuItem>
                                    <MenuItem value={"mashkull"}>Mashkull</MenuItem>
                                    <MenuItem value={"femer"}>Femer</MenuItem>
                                </Select>
                            </div>
                            <div className="shtopacient-content-form-col-left-item">
                                <label htmlFor="">Dita e regjistrimit</label>
                                <TextField style={{ width: '100%' }} type="date" variant="outlined" onChange={(e) => setDataRegj(e.target.value)} value={dataRegj}/>
                            </div>
                        </div>
                    </div>

                    <div className="shtopacient-content-form-submit">
                        <button className="shtopacient-content-form-items-submit-btn fs-14 fw-500">
                            Ruaj
                        </button>
                    </div>

                </form>
                <div className="logo-transparent" >
                    <img className="img-res" src={LogoTransparent} alt="" />
                </div>
            </section>
            <Footer />
        </div>
        </>
    )
}
