import React , {useEffect,useState,useContext} from 'react'
import Footer from '../../../utilities/footer';
import TextField from '@material-ui/core/TextField';
import axios from 'axios'
import AlertContext from '../../../context/AlertContext';
import Loading from '../../../utilities/Loading';

export default function Profili() {


    const alertContext =  useContext(AlertContext)
    const [loading,setLoading] = useState(true)
    const onSubmit = (e) => {
        e.preventDefault();

        
        const payload = {

            user_id: user.user_id,
            emer,
            mbiemer,
            email,
            telefon,
            klinika,
            adresa,
            nipt,
            qyteti

        }

        axios.post('https://panel.at-aligners.com/server/lab/updateProfile',payload).then(res => {
            if(res.data.status === 1){

                let token = JSON.parse(localStorage.getItem('token'));

                axios.post('https://panel.at-aligners.com/server/lab/getProfile', {token: token }).then(res => {
                    setUser(res.data[0])
                    setEmer(res.data[0].laborator_pergjegjes)
                    setMbiemer(res.data[0].laborator_mbiemer)
                    setEmail(res.data[0].laborator_email)
                    setTelefon(res.data[0].laborator_telefon)
                    setKlinika(res.data[0].laborator_name)
                    setAdresa(res.data[0].laborator_adresa)
                    setQyteti(res.data[0].laborator_qyteti)
                    setNipt(res.data[0].laborator_nipt)
                })
                alertContext.setAlert(`${res.data.message}`,'success')

            }else{
                alertContext.setAlert(`${res.data.message}`,'error')
            }
        })
    }

    const [user,setUser] = useState({})
    const [emer,setEmer] = useState();
    const [mbiemer,setMbiemer] = useState();
    const [email,setEmail] = useState();
    const [telefon,setTelefon] = useState();
    const [klinika,setKlinika] = useState();
    const [adresa,setAdresa] = useState();
    const [qyteti,setQyteti] = useState();
    const [nipt,setNipt] = useState();

    useEffect(() => {

        let token = JSON.parse(localStorage.getItem('token'));

        axios.post('https://panel.at-aligners.com/server/lab/getProfile', {token: token }).then(res => {
            if(res.status === 200){
                setLoading(false)
            }
            setUser(res.data[0])
            setEmer(res.data[0].laborator_pergjegjes)
            setMbiemer(res.data[0].laborator_mbiemer)
            setEmail(res.data[0].laborator_email)
            setTelefon(res.data[0].laborator_telefon)
            setKlinika(res.data[0].laborator_name)
            setAdresa(res.data[0].laborator_adresa)
            setQyteti(res.data[0].laborator_qyteti)
            setNipt(res.data[0].laborator_nipt)
        })

    },[])

    console.log(user)

    return (
        <>
        {loading && <Loading /> }
        <div className="profili" >
            <div className="profili-header">
                <p className="profili-header-title fs-22 fw-bold">Profili im</p>
            </div>
            <section className="shtoklinike-content">
                <form className="shtoklinike-content-form" onSubmit={onSubmit}>
                    

                    <div className="shtoklinike-content-form-item">
                        <label for="emri" className="shtoklinike-content-form-item-label">Emer</label>
                        <TextField variant="outlined" id="emri" placeholder="Filan" value={emer} onChange={(e) => setEmer(e.target.value)} />
                    </div>

                    <div className="shtoklinike-content-form-item">
                        <label for="mbiemri" className="shtoklinike-content-form-item-label">Mbiemer</label>
                        <TextField variant="outlined" id="mbiemri" placeholder="Fisteku" value={mbiemer} onChange={(e) => setMbiemer(e.target.value)} />
                    </div>

                    <div className="shtoklinike-content-form-item">
                        <label className="shtoklinike-content-form-item-label" for="email">Email</label>
                        <TextField variant="outlined" id="email" placeholder="filani@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>

                    <div className="shtoklinike-content-form-item">
                        <label for="cel" className="shtoklinike-content-form-item-label">Telefon</label>
                        <TextField variant="outlined" id="cel" placeholder="+355 65 253 236 55" value={telefon}  onChange={(e) => setTelefon(e.target.value)} />
                    </div>

                    <div className="shtoklinike-content-form-item">
                        <label for="klinik" className="shtoklinike-content-form-item-label">Klinika</label>
                        <TextField variant="outlined" id="klinik" placeholder="X Dental" value={klinika} onChange={(e) => setKlinika(e.target.value)} />
                    </div>

                    <div className="shtoklinike-content-form-item">
                        <label for="adresa" className="shtoklinike-content-form-item-label">Adresa</label>
                        <TextField variant="outlined" id="adresa" placeholder="Rruga ...." value={adresa} onChange={(e) => setAdresa(e.target.value)} />
                    </div>

                    <div className="shtoklinike-content-form-item">
                        <label for="qytet" className="shtoklinike-content-form-item-label">Qyteti</label>
                        <TextField variant="outlined" id="qytet" placeholder="Tiron" value={qyteti} onChange={(e) => setQyteti(e.target.value)} />
                    </div>

                    <div className="shtoklinike-content-form-item">
                        <label for="nipt" className="shtoklinike-content-form-item-label">NIPT</label>
                        <TextField variant="outlined" id="nipt" placeholder="xxx - xxx - xxx" value={nipt} onChange={(e) => setNipt(e.target.value)} />
                    </div>

                    <div className="shtoklinike-content-form-sub">
                        <button className="shtoklinike-content-form-sub-btn fs-14 fw-500">
                            Ruaj
                        </button>
                    </div>

                </form>
            </section>
            <Footer />
        </div>

        </>
    )
}
