import React , {useContext} from 'react'
import Logo from '../../../images/forma-doktro-logo.png';
import Dhembet from '../../../images/doktor-forma-dhembe.png';
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import axios from 'axios'
import AlertContext from '../../../context/AlertContext';


export default function FormaDoktorit({closeForma,fazaID,emriPac,mbiemriPac,refreshData,labId,pacId,klinika,openLoading,closeLoading}) {


    const alertContext = useContext(AlertContext);

    const makePdf = () => {
        openLoading()

        const input = document.getElementById('divToPrint')
        html2canvas(input).then((canvas) => {
            const pdf = new jsPDF('p', 'pt', 'a4');
            const imageData = canvas.toDataURL('image/png')
            const pageheight = pdf.internal.pageSize.getHeight();
            const imgwidth = 595;
            const imgheight = canvas.height * imgwidth / canvas.width;
            let position = 0;
            let heightleft = imgheight;
     
            pdf.addImage(imageData, 'JPEG', 0, 0, imgwidth, imgheight);
            heightleft -= pageheight;

            while (heightleft >= 0) {
                position = heightleft - imgheight;
                pdf.addPage();
                pdf.addImage(imageData, 0, position, imgwidth, imgheight)
                heightleft -= pageheight
            }

            // pdf.save('1.pdf')

            let blob = pdf.output('blob')

            var formData = new FormData();
            formData.append('pdf', blob);
            formData.append('emri', emriPac);
            formData.append('mbiemri', mbiemriPac);
            formData.append('fazaId', fazaID);
            // formData.append('pacientId', pacientId);
 

            axios.post('https://panel.at-aligners.com/server/mjek/addFormaMjekut', formData).then(res => {
                if(res.status === 200){
                    closeLoading()
                }
                if(res.data.status === 1){
                    closeLoading()
                    closeForma()
                    refreshData()
                    alertContext.setAlert(`${res.data.message}`, "success");
                    axios.post('https://panel.at-aligners.com/server/user/addNotification', 
                    {
                        to_user : labId , 
                        message : `${klinika} poltesoi formen  (${emriPac} ${mbiemriPac}) ` , 
                        link : `/lab/pacientet/${pacId}` , 
                        type : 3 
                    })
                }else{
                    alertContext.setAlert(`${res.data.message}`, "error");
                    closeLoading()
                }
            })

        })
    }





    return (
        <div className="forma-doktorit" >
            <div className="forma-doktorit-opa" onClick={closeForma} ></div>
            <div className="forma-doktorit-opa-content">
                <div className="forma-doktorit-opa-content-body" id="divToPrint">


                    <div className="forma-doktorit-opa-content-body-header">
                        <div className="forma-doktorit-opa-content-body-header-logo">
                            <img className="img-res" src={Logo} alt="" />
                        </div>
                    </div>


                    <div className="forma-doktorit-opa-content-body-forma-1">

                        <div className="forma-doktorit-opa-content-body-forma-1-inputs">
                            <div className="forma-doktorit-opa-content-body-forma-1-inputs-left">
                                <label className="forma-doktorit-opa-content-body-forma-1-inputs-left-label fs-8" htmlFor="#">Doctor</label>
                                <input type="text" className="forma-doktorit-opa-content-body-forma-1-inputs-left-input" />
                            </div>
                            <div className="forma-doktorit-opa-content-body-forma-1-inputs-right">
                                <label htmlFor="#" className="fs-8" >Klinika</label>
                                <input type="text" className="forma-doktorit-opa-content-body-forma-1-inputs-right-input" />
                            </div>
                        </div>


                        <div className="forma-doktorit-opa-content-body-forma-1-inputs-2">

                            <div className="forma-doktorit-opa-content-body-forma-1-inputs-2-left">
                                <div className="forma-doktorit-opa-content-body-forma-1-inputs-2-left-item">
                                    <label htmlFor="#" className="forma-doktorit-opa-content-body-forma-1-inputs-2-left-item-label fs-8" >Pacient</label>
                                    <input type="text" style={{ width: '100%' }} />
                                </div>

                                <div className="forma-doktorit-opa-content-body-forma-1-inputs-2-left-item">
                                    <label htmlFor="#" className="fs-8">Mosha</label>
                                    <input type="text" style={{ width: '100%' }} />
                                </div>

                                <div className="forma-doktorit-opa-content-body-forma-1-inputs-2-left-item">
                                    <label htmlFor="#" className="fs-8" >Data</label>
                                    <input type="text" style={{ width: '100%' }} />
                                </div>

                            </div>
                            <div className="forma-doktorit-opa-content-body-forma-1-inputs-2-right">
                                <label htmlFor="#" className="fs-8">Distributori</label>
                                <input type="text" />
                            </div>

                        </div>





                        <div className="forma-doktorit-opa-content-body-forma-1-inputs-3">
                            <div className="forma-doktorit-opa-content-body-forma-1-inputs-3-top">


                                <div className="forma-doktorit-opa-content-body-forma-1-inputs-3-top-item">
                                    <label htmlFor="#" className="fs-8" >Adresa</label>
                                    <input type="text" />
                                </div>


                                <div className="forma-doktorit-opa-content-body-forma-1-inputs-3-top-item">
                                    <label htmlFor="#" className="fs-8">Qyteti</label>
                                    <input type="text" />
                                </div>


                                <div className="forma-doktorit-opa-content-body-forma-1-inputs-3-top-item">
                                    <label htmlFor="#" className="fs-8">Kodi Postar</label>
                                    <input type="text" />
                                </div>


                            </div>

                            <div className="forma-doktorit-opa-content-body-forma-1-inputs-3-top">


                                <div className="forma-doktorit-opa-content-body-forma-1-inputs-3-top-item">
                                    <label htmlFor="#" className="fs-8" >Email</label>
                                    <input type="text" />
                                </div>


                                <div className="forma-doktorit-opa-content-body-forma-1-inputs-3-top-item">
                                    <label htmlFor="#" className="fs-8">Nr.Tel</label>
                                    <input type="text" />
                                </div>


                                <div className="forma-doktorit-opa-content-body-forma-1-inputs-3-top-item">
                                    <label htmlFor="#" className="fs-8">MB</label>
                                    <input type="text" />
                                </div>


                            </div>

                        </div>

                    </div>

                    <div className="forma-doktorit-opa-content-body-forma-1-info">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="2" viewBox="0 0 149 2">
                            <rect id="Rectangle_409" data-name="Rectangle 409" width="149" height="2" fill="#5fb597" />
                        </svg>

                        <p className="fs-8">JU LUTEM PLOTËSONI FORMULARIN ME QËLLIMET / OBJEKTIVAT E TRAJTIMIT</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="2" viewBox="0 0 149 2">
                            <rect id="Rectangle_409" data-name="Rectangle 409" width="149" height="2" fill="#5fb597" />
                        </svg>

                    </div>


                    <div className="forma-doktorit-opa-content-body-forma-2">
                        <div className="forma-doktorit-opa-content-body-forma-2-shiriti">
                            <p className="fs-8 fw-600" >MATERIALI I DERGUAR</p>
                        </div>
                        <div className="forma-doktorit-opa-content-body-forma-2-items">
                            <label className="fs-6" >Masa</label>


                            <div className="forma-doktorit-opa-content-body-forma-2-items-item">
                                <p className="fs-6">Nofulla siperme</p>
                                <input type="checkbox" name="" id="" />
                            </div>

                            <div className="forma-doktorit-opa-content-body-forma-2-items-item">
                                <p className="fs-6">Nofulla poshtme</p>
                                <input type="checkbox" name="" id="" />
                            </div>

                            <div className="forma-doktorit-opa-content-body-forma-2-items-item">
                                <p className="fs-6">Disinfected</p>
                                <input type="checkbox" name="" id="" />
                            </div>

                            <div className="forma-doktorit-opa-content-body-forma-2-items-item">
                                <p className="fs-6">Regj. Kafshimit</p>
                                <input type="checkbox" name="" id="" />
                            </div>



                        </div>
                        <div className="forma-doktorit-opa-content-body-forma-2-items">
                            <label className="fs-6" >Modeli</label>


                            <div className="forma-doktorit-opa-content-body-forma-2-items-item">
                                <p className="fs-6">Nofulla siperme</p>
                                <input type="checkbox" name="" id="" />
                            </div>

                            <div className="forma-doktorit-opa-content-body-forma-2-items-item">
                                <p className="fs-6">Nofulla poshtme</p>
                                <input type="checkbox" name="" id="" />
                            </div>

                            <div className="forma-doktorit-opa-content-body-forma-2-items-item">
                                <p className="fs-6">X-Ray</p>
                                <input type="checkbox" name="" id="" />
                            </div>

                            <div className="forma-doktorit-opa-content-body-forma-2-items-item">
                                <p className="fs-6">Skaner Oral</p>
                                <input type="checkbox" name="" id="" />
                            </div>

                        </div>
                    </div>

                    <div className="forma-doktorit-opa-content-body-forma-3-shiriti">
                        <p className="fs-8 fw-600" >Mos i leviz keta dhembe</p>
                    </div>
                    <div className="forma-doktorit-opa-content-body-forma-3">

                        <div className="forma-doktorit-opa-content-body-forma-3-left">

                            <div className="forma-doktorit-opa-content-body-forma-3-left-image">
                                <img className="img-res" src={Dhembet} alt="" />
                            </div>

                            <div className="forma-doktorit-opa-content-body-forma-3-left-items">
                                <label className="fs-8" >Trajtimi</label>
                                <div className="forma-doktorit-opa-content-body-forma-3-left-items-item">
                                    <p className="fs-6" >Nofulla siperme</p>
                                    <input type="checkbox" name="" id="" />
                                </div>
                                <div className="forma-doktorit-opa-content-body-forma-3-left-items-item">
                                    <p className="fs-6" >Nofulla poshtme</p>
                                    <input type="checkbox" name="" id="" />
                                </div>
                            </div>

                            <div className="forma-doktorit-opa-content-body-forma-3-left-items">
                                <label className="fs-8" >Trajtimi</label>
                                <div className="forma-doktorit-opa-content-body-forma-3-left-items-item">
                                    <p className="fs-6" >Nofulla siperme</p>
                                    <input type="checkbox" name="" id="" />
                                </div>
                                <div className="forma-doktorit-opa-content-body-forma-3-left-items-item">
                                    <p className="fs-6" >Nofulla poshtme</p>
                                    <input type="checkbox" name="" id="" />
                                </div>
                            </div>


                        </div>
                        <div className="forma-doktorit-opa-content-body-forma-3-right">
                            <div className="forma-doktorit-opa-content-body-forma-3-right-top">
                                <p className="fs-8">Informacioni Pergjithshem:</p>
                                <textarea name="" id="" cols="30" rows="3"></textarea>
                            </div>
                            <div className="forma-doktorit-opa-content-body-forma-3-right-bottom">
                                <label className="fs-8" >Overjet</label>
                                <div className="forma-doktorit-opa-content-body-forma-3-right-bottom-item">
                                    <p className="fs-6">Mos e korigjo</p>
                                    <input type="checkbox" />
                                </div>
                                <div className="forma-doktorit-opa-content-body-forma-3-right-bottom-item">
                                    <p className="fs-6">Korigjo me IPR</p>
                                    <input type="checkbox" />
                                </div>

                            </div>
                        </div>
                    </div>



                    <div className="forma-doktorit-opa-content-body-forma-4-shiriti">
                        <p className="fs-8 fw-600">Rastet e dhëmbëve të grumbulluara</p>
                    </div>

                    <div className="forma-doktorit-opa-content-body-forma-4">
                        <div className="forma-doktorit-opa-content-body-forma-4-left">
                            <label className="fs-8">Expansion</label>
                            <label className="fs-8">IPR</label>
                            <label className="fs-8">Procline (tip)</label>
                        </div>


                        <div className="forma-doktorit-opa-content-body-forma-4-center">
                            <div className="forma-doktorit-opa-content-body-forma-4-center-left">
                                <p className="fs-6" >Pjesa siperme</p>


                                <div className="forma-doktorit-opa-content-body-forma-4-center-left-item">
                                    <div className="forma-doktorit-opa-content-body-forma-4-center-left-item-input">
                                        <p className="fs-6" >Po</p>
                                        <input type="radio" name="po1" id="" />
                                    </div>
                                    <div className="forma-doktorit-opa-content-body-forma-4-center-left-item-input">
                                        <p className="fs-6" >Jo</p>
                                        <input type="radio" name="po1" id="" />
                                    </div>
                                </div>


                                <div className="forma-doktorit-opa-content-body-forma-4-center-left-item">
                                    <div className="forma-doktorit-opa-content-body-forma-4-center-left-item-input">
                                        <p className="fs-6" >Po</p>
                                        <input type="radio" name="po2" id="" />
                                    </div>
                                    <div className="forma-doktorit-opa-content-body-forma-4-center-left-item-input">
                                        <p className="fs-6" >Jo</p>
                                        <input type="radio" name="po2" id="" />
                                    </div>
                                </div>


                                <div className="forma-doktorit-opa-content-body-forma-4-center-left-item">
                                    <div className="forma-doktorit-opa-content-body-forma-4-center-left-item-input">
                                        <p className="fs-6" >Po</p>
                                        <input type="radio" name="po3" id="" />
                                    </div>
                                    <div className="forma-doktorit-opa-content-body-forma-4-center-left-item-input">
                                        <p className="fs-6" >Jo</p>
                                        <input type="radio" name="po3" id="" />
                                    </div>
                                </div>




                            </div>
                            <div className="forma-doktorit-opa-content-body-forma-4-center-left">
                                <p className="fs-6" >Pjesa poshtme</p>


                                <div className="forma-doktorit-opa-content-body-forma-4-center-left-item">
                                    <div className="forma-doktorit-opa-content-body-forma-4-center-left-item-input">
                                        <p className="fs-6" >Po</p>
                                        <input type="radio" name="po4" id="" />
                                    </div>
                                    <div className="forma-doktorit-opa-content-body-forma-4-center-left-item-input">
                                        <p className="fs-6" >Jo</p>
                                        <input type="radio" name="po4" id="" />
                                    </div>
                                </div>


                                <div className="forma-doktorit-opa-content-body-forma-4-center-left-item">
                                    <div className="forma-doktorit-opa-content-body-forma-4-center-left-item-input">
                                        <p className="fs-6" >Po</p>
                                        <input type="radio" name="po5" id="" />
                                    </div>
                                    <div className="forma-doktorit-opa-content-body-forma-4-center-left-item-input">
                                        <p className="fs-6" >Jo</p>
                                        <input type="radio" name="po5" id="" />
                                    </div>
                                </div>


                                <div className="forma-doktorit-opa-content-body-forma-4-center-left-item">
                                    <div className="forma-doktorit-opa-content-body-forma-4-center-left-item-input">
                                        <p className="fs-6" >Po</p>
                                        <input type="radio" name="po6" id="" />
                                    </div>
                                    <div className="forma-doktorit-opa-content-body-forma-4-center-left-item-input">
                                        <p className="fs-6" >Jo</p>
                                        <input type="radio" name="po6" id="" />
                                    </div>
                                </div>




                            </div>
                        </div>


                        <div className="forma-doktorit-opa-content-body-forma-4-right">
                            <p  className="fs-6">Informacion i pergjitshem</p>
                            <textarea name="" id="" cols="30" rows="4"></textarea>
                        </div>
                    </div>


                    <div className="forma-doktorit-opa-content-body-forma-5-shiriti">
                            <p className="fs-8 fw-600" >Rastet e dhembeve te grumbulluar</p>
                    </div>

                <div className="forma-doktorit-opa-content-body-forma-5">
                    <div className="forma-doktorit-opa-content-body-forma-5-items">
                        <label className="fs-8" >Nofulla siperme</label>
                        <div className="forma-doktorit-opa-content-body-forma-5-items-item">
                            <p className="fs-6" >Mbyll te gjitha hapesirat</p>
                            <input type="checkbox" name="" id="" />
                        </div>
                        <div className="forma-doktorit-opa-content-body-forma-5-items-item">
                            <p className="fs-6">Ler hapesir</p>
                            <input type="checkbox" name="" id="" />
                        </div>
                        <div className="forma-doktorit-opa-content-body-forma-5-items-item">
                            <p className="fs-6">Percakto ne MM</p>
                            <input type="text" name="" id="" />
                        </div>
                    </div>

                    <div className="forma-doktorit-opa-content-body-forma-5-items">
                        <label className="fs-8" >Nofulla poshtme</label>
                        <div className="forma-doktorit-opa-content-body-forma-5-items-item">
                            <p className="fs-6" >Mbyll te gjitha hapesirat</p>
                            <input type="checkbox" name="" id="" />
                        </div>
                        <div className="forma-doktorit-opa-content-body-forma-5-items-item">
                            <p className="fs-6">Ler hapesire</p>
                            <input type="checkbox" name="" id="" />
                        </div>
                        <div className="forma-doktorit-opa-content-body-forma-5-items-item">
                            <p className="fs-6">Percakto ne MM</p>
                            <input type="text" name="" id="" />
                        </div>
                    </div>
                </div>



                <div className="forma-doktorit-opa-content-body-forma-5-shiriti">
                            <p className="fs-8 fw-600" >KORRIGJIMI I HAPESIRES MES DHEMBEVE</p>
                    </div>
                
                <div className="forma-doktorit-opa-content-body-forma-5">
                    <div className="forma-doktorit-opa-content-body-forma-5-items">
                        <label className="fs-8" >Nofulla siperme</label>
                        <div className="forma-doktorit-opa-content-body-forma-5-items-item">
                            <p className="fs-6" >Leviz djathtas</p>
                            <input type="checkbox" name="" id="" />
                        </div>
                        <div className="forma-doktorit-opa-content-body-forma-5-items-item">
                            <p className="fs-6">Leviz Majtas</p>
                            <input type="checkbox" name="" id="" />
                        </div>
                        <div className="forma-doktorit-opa-content-body-forma-5-items-item">
                            <p className="fs-6">Percakto ne MM</p>
                            <input type="text" name="" id="" />
                        </div>
                    </div>

                    <div className="forma-doktorit-opa-content-body-forma-5-items">
                        <label className="fs-8" >Nofulla poshtme</label>
                        <div className="forma-doktorit-opa-content-body-forma-5-items-item">
                            <p className="fs-6" >Leviz djathtas</p>
                            <input type="checkbox" name="" id="" />
                        </div>
                        <div className="forma-doktorit-opa-content-body-forma-5-items-item">
                            <p className="fs-6">Leviz majtas</p>
                            <input type="checkbox" name="" id="" />
                        </div>
                        <div className="forma-doktorit-opa-content-body-forma-5-items-item">
                            <p className="fs-6">Percakto ne MM</p>
                            <input type="text" name="" id="" />
                        </div>
                    </div>
                </div>


                <div className="forma-doktorit-opa-content-body-forma-5-shiriti">
                            <p className="fs-8 fw-600" >Vija Medane</p>
                    </div>

                
                <div className="forma-doktorit-opa-content-body-forma-5">
                    <div className="forma-doktorit-opa-content-body-forma-5-items">
                        <label className="fs-8" >Nofulla siperme</label>
                        <div className="forma-doktorit-opa-content-body-forma-5-items-item">
                            <p className="fs-6" >Ne qender</p>
                            <input type="checkbox" name="" id="" />
                        </div>
                        <div className="forma-doktorit-opa-content-body-forma-5-items-item">
                            <p className="fs-6">E devijuar majtas</p>
                            <input type="checkbox" name="" id="" />
                        </div>
                        <div className="forma-doktorit-opa-content-body-forma-5-items-item">
                            <p className="fs-6">Percakto ne MM</p>
                            <input type="text" name="" id="" />
                        </div>
                    </div>

                    <div className="forma-doktorit-opa-content-body-forma-5-items">
                        <label className="fs-8" >Nofulla poshtme</label>
                        <div className="forma-doktorit-opa-content-body-forma-5-items-item">
                            <p className="fs-6" >Ne qender</p>
                            <input type="checkbox" name="" id="" />
                        </div>
                        <div className="forma-doktorit-opa-content-body-forma-5-items-item">
                            <p className="fs-6">E devijuar djathtas</p>
                            <input type="checkbox" name="" id="" />
                        </div>
                        <div className="forma-doktorit-opa-content-body-forma-5-items-item">
                            <p className="fs-6">Percakto ne MM</p>
                            <input type="text" name="" id="" />
                        </div>
                    </div>
                </div>

                    <div className="forma-doktorit-opa-content-body-forma-6">
                        <p className="fs-8" >Duke vendosur emrin/mbiemrin tuaj në hapësirën më poshtë ju jeni duke rënë dakord me Termat dhe Kushtet e laboratorit. </p>
                        <div className="forma-doktorit-opa-content-body-forma-6-items">
                            <div className="forma-doktorit-opa-content-body-forma-6-items-item">
                                <label className="fs-8 fw-600" >Emer</label>
                                <input type="text" />
                            </div>

                            <div className="forma-doktorit-opa-content-body-forma-6-items-item">
                                <label className="fs-8 fw-600" >Mbiemer</label>
                                <input type="text" />
                            </div>

                        </div>
                    </div>


                <div className="forma-doktorit-opa-content-body-forma-7">
                    <div className="forma-doktorit-opa-content-body-forma-7-item">
                            <label className="fs-6" > Firma e Doktorit </label>
                            <input type="text" />
                    </div>
                    <div className="forma-doktorit-opa-content-body-forma-7-item">
                            <label className="fs-6"> Data </label>
                            <input type="text" />
                    </div>
                    <div className="forma-doktorit-opa-content-body-forma-7-item">
                            <label className="fs-6"> Dergesa </label>
                            <input type="text" />
                    </div>
                </div>

                </div>
                <div className="forma-lab-content-buttons">
                    <button className="forma-lab-content-buttons-btn-anullo fs-14 fw-500" onClick={closeForma}  >Anullo</button>
                    <button  className="forma-lab-content-buttons-btn-ruaj fs-14 fw-500" onClick={makePdf}>Ruaj</button>
                </div>
            </div>
        </div>
    )
}
