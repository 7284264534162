import React from 'react'
import { Switch, Route } from "react-router-dom";
import Klinikat from './Klinikat';
import Pacientet from './Pacientet';
import ShtoKlinike from './ShtoKlinike';
import ShtoPacientet from './ShtoPaciente';
import Profili from './Profili';
import SinglePacient from './SinglePacient';
import KlinikaSingle from './KlinikaSingle';

export default function LabBody() {
    return (
        <div  className="dash-body" >
            <Switch>
                <Route exact path="/lab" component={Klinikat} />
                <Route exact path="/lab/klinika/:id" component={KlinikaSingle} />
                <Route exact path="/lab/pacientet" component={Pacientet} />
                <Route exact path="/lab/shtoKlinike" component={ShtoKlinike} />
                <Route exact path="/lab/shtoPaciente" component={ShtoPacientet} />
                <Route exact path="/lab/profili"  component={Profili}/>
                <Route exact path="/lab/pacientet/:id" component={SinglePacient} />
            </Switch>
        </div>
    )
}
