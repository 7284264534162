import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import axios from 'axios';

export default function Notifications({ closeNot }) {


    const [notfications, setNotifications] = useState([]);

    useEffect(() => {

        axios.post('https://panel.at-aligners.com/server/user/getNotifications', { user_id: JSON.parse(localStorage.getItem("id")) }).then(res => {

            setNotifications(res.data)

        })

        setInterval(() => {
            
            axios.post('https://panel.at-aligners.com/server/user/getNotifications', { user_id: JSON.parse(localStorage.getItem("id")) }).then(res => {

                setNotifications(res.data)
    
            })



        }, 60000);

    }, [])

    const colors = {

        mbyllur: '#ff0000',
        green: '#6ac0a8',
        blue: '#2677f1'
    };


    const renderIcon = (status) => {

        if (status === 1) {
            return <CheckCircleOutlineIcon style={{ color: colors.green }} />
        } else if (status === 2) {
            return <ErrorOutlineIcon style={{ color: colors.mbyllur }} />
        } else {
            return <InfoOutlinedIcon style={{ color: colors.blue }} />
        }

    }


    return (
        <ClickAwayListener onClickAway={closeNot} >
            <div className="notifications" >

                {notfications.length === 0 ? <p className="notifications-message" >Nuk ka njoftime</p> :
                    <>
                        {notfications.map(njfotim => (

                            <>
                                {njfotim.link === "" ? <p className="notifications-message-link fs-16 fw-500" >
                                    {renderIcon(njfotim.type)}

                                    {njfotim.message}
                                </p> : <Link to={njfotim.link}   className="notifications-message-link fs-16 fw-500" >

                                    {renderIcon(njfotim.type)}

                                    {njfotim.message}
                                </Link>}

                            </>
                        ))}
                    </>

                }




            </div>
        </ClickAwayListener>
    )
}
