import React, { useContext, useState } from 'react'
import Footer from '../../../utilities/footer';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import LogoTransparent from '../../../images/at-transparent.png'
import axios from 'axios';
import AlertContext from '../../../context/AlertContext';
import Loading from '../../../utilities/Loading';

export default function ShtoPaciente() {


    const [emri, setEmri] = useState('');
    const [mbiemri, setMbiemri] = useState('');
    const [mosha, setMosha] = useState(0);
    const [gjinia, setGjinia] = useState('')
    const [dataRegj, setDataRegj] = useState('');
    const [loading,setLoading] = useState(false)
    const alertContext = useContext(AlertContext);
    const onSubmit = (e) => {
        e.preventDefault();

        const payload = {
            emri,
            mbiemer: mbiemri,
            mosha,
            gjinia,
            data_regj: dataRegj,
            mjek_id: JSON.parse(localStorage.getItem('id'))
        }
        setLoading(true)

        axios.post('https://panel.at-aligners.com/server/mjek/addPacient', payload).then(res => {

            if (res.data.status === 1) {
                alertContext.setAlert(`${res.data.message}`, 'success')
                setEmri('')
                setMbiemri('')
                setMosha('')
                setGjinia('')
                setDataRegj('')
                setLoading(false)
            } else {
                alertContext.setAlert(`${res.data.message}`, 'error')
                setLoading(false)
            }

        })
    }

    return (
        <>
        {loading && <Loading /> }
        <div className="shtopacient">
            <div className="shtopacient-header">
                <p className="shtopacient-header-title fs-22 fw-bold">Rigjistro nje pacient</p>
            </div>
            <section className="shtopacient-content" >
                <form className="shtopacient-content-form" onSubmit={onSubmit}>

                    <div className="shtopacient-content-form-items" >
                        <div className="shtopacient-content-form-col-left">
                            <div className="shtopacient-content-form-col-left-item">
                                <label htmlFor="">Emri</label>
                                <TextField variant="outlined" value={emri} onChange={(e) => setEmri(e.target.value)} />
                            </div>
                            <div className="shtopacient-content-form-col-left-item">
                                <label htmlFor="">Mosha</label>
                                <TextField variant="outlined" value={mosha} onChange={(e) => setMosha(e.target.value)} />
                            </div>
                            <div className="shtopacient-content-form-col-left-item">
                                <label htmlFor="">Dita e regjistrimit</label>
                                <TextField style={{ width: '100%' }} type="date" variant="outlined" value={dataRegj} onChange={(e) => setDataRegj(e.target.value)} />
                            </div>
                        </div>



                        <div className="shtopacient-content-form-col-right">
                            <div className="shtopacient-content-form-col-left-item">
                                <label htmlFor="">Mbiemri</label>
                                <TextField variant="outlined" value={mbiemri} onChange={(e) => setMbiemri(e.target.value)} />
                            </div>
                            <div className="shtopacient-content-form-col-left-item">
                                <label htmlFor="">Gjinia</label>
                                <Select variant="outlined" className="klinika-select" value={gjinia} onChange={(e) => setGjinia(e.target.value)} >
                                    <MenuItem value="" >None</MenuItem>
                                    <MenuItem value={'mashkull'}>Mashkull</MenuItem>
                                    <MenuItem value={'femer'}>Femer</MenuItem>
                                </Select>
                            </div>

                        </div>
                    </div>

                    <div className="shtopacient-content-form-submit">
                        <button className="shtopacient-content-form-items-submit-btn fs-14 fw-500">
                            Ruaj
                        </button>
                    </div>

                </form>
                <div className="logo-transparent" >
                    <img className="img-res" src={LogoTransparent} alt="" />
                </div>
            </section>
            <Footer />
        </div>
        </>
    )
}
