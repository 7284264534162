import React, { useState, useContext, useEffect } from 'react'
import Footer from '../../../utilities/footer';
import axios from 'axios'
import AlertContext from '../../../context/AlertContext';
import FormaDoktorit from './FormaDoktorit';
import FotoPacient from './FotoPacient';
import Loading from '../../../utilities/Loading';

export default function SinglePacient({ match }) {

    const [loading,setLoading] = useState(true)
    const alertContext = useContext(AlertContext);
    const [pacient, setPacient] = useState({});
    const [fazat, setFazat] = useState([]);
    const [activeFaza, setActiveFaza] = useState(1);
    const [fazaContent, setFazaContent] = useState({})
    const [notes, setNotes] = useState("");
    const [messages, setMessages] = useState([])
    const [openForma, setOpenForma] = useState(false);
    const [propertyName, setProperty] = useState({
        key: 'data',
        direction: 'descending'
    });
    const [openFotoPacient, setOpenFotoPacient] = useState(false);
    const [activeIndex, setAcitveIndex] = useState(0)

    const closeForma = () => {
        setOpenForma(false);
    }
    const [rishikimPop, openRishikimPop] = useState(false)

    console.log(fazaContent)

    useEffect(() => {

        axios.post('https://panel.at-aligners.com/server/mjek/getSinglePacient', { pacient_id: match.params.id }).then(res => {
            setPacient(res.data[0])
        })

        axios.post('https://panel.at-aligners.com/server/mjek/getFazatSinglePacient', { pacient_id: match.params.id }).then(res => {
            if(res.status === 200){
                setLoading(false)
            }
            setFazat(res.data)
            setActiveFaza(res.data[0].faza_id)
            setFazaContent(res.data[0])
        })


    }, [match.params.id])


    const getPacient = () => {

        axios.post('https://panel.at-aligners.com/server/mjek/getFazatSinglePacient', { pacient_id: match.params.id }).then(res => {
            if(res.status === 200){
                setLoading(false)
            }
            setFazat(res.data)
            setActiveFaza(res.data[activeIndex].faza_id)
            setFazaContent(res.data[activeIndex])
        })

    }

    useEffect(() => {


        axios.post('https://panel.at-aligners.com/server/mjek/getNotes', { pacient_id: parseInt(match.params.id), faza_id: activeFaza }).then(res => {
            setMessages(res.data)
        })


    }, [match.params.id, activeFaza])

    if (propertyName !== null) {
        messages.sort((a, b) => {
            if (a[propertyName.key] < b[propertyName.key]) {
                return propertyName.direction === 'descending' ? -1 : 1;
            }
            if (a[propertyName.key] > b[propertyName.key]) {
                return propertyName.direction === 'descending' ? 1 : -1;
            }
            return 0;
        });
    }

    const sendNotes = () => {

        const payload = {

            pacient_id: match.params.id,
            faza_id: activeFaza,
            note_from: localStorage.getItem('id'),
            text: notes

        }


        axios.post('https://panel.at-aligners.com/server/mjek/addNotes', payload).then(res => {
            if (res.data.status === 1) {
                setNotes('')
                alertContext.setAlert(`${res.data.message}`, 'success')
                axios.post('https://panel.at-aligners.com/server/mjek/getNotes', { pacient_id: parseInt(match.params.id), faza_id: activeFaza }).then(res => {
                    setMessages(res.data)
                })
                axios.post('https://panel.at-aligners.com/server/user/addNotification',
                    {
                        to_user: pacient.mjek_lab,
                        message: `${pacient.mjek_klinika} ju dergoi mesazh   (${pacient.emri} ${pacient.mbiemri}) `,
                        link: `/lab/pacientet/${match.params.id}`,
                        type: 3
                    })
            } else {
                alertContext.setAlert(`${res.data.message}`, 'error')
            }
        })

    }


    const textRender = (status) => {

        if (status === 1) {
            return 'Ne pritje'
        } else if (status === 2) {
            return '3D Laborator'
        } else if (status === 3) {
            return 'Derguar'
        } else {
            return "Mbyllur"
        }
    }

    const colorRender = (status) => {
        if (status === 1) {
            return '#2677F1'
        } else if (status === 2) {
            return '#5FB597'
        } else if (status === 3) {
            return '#73C850'
        } else {
            return "#FF0000"
        }
    }

    return (
        <>
            {loading && <Loading />}
            {openFotoPacient && <FotoPacient fazaId={activeFaza} pacientId={match.params.id} closeFotoPacient={() => setOpenFotoPacient(false)} />}
            <div>
                <div className="single-user" >
                    <header className="single-user-header" >
                        <div className="single-user-header-left">
                            <p className="single-user-header-left-title fs-22 fw-bold">Trajtimi i pacientit</p>
                            <p className="single-user-header-left-subtitle fs-16 fw-normal">{pacient.emri} {pacient.mbiemri}</p>
                        </div>
                        <div className="single-user-header-right">
                            <div className="single-user-header-right-content" >
                                <p className="single-user-header-right-status fs-16 fw-normal">
                                    Status Pacient :  <span style={{ color: colorRender(pacient.status) }} >{textRender(pacient.status)}</span>
                                </p>

                                <p className="single-user-header-right-shipment fs-16 fw-normal">
                                    Shipment : <span> {pacient.dergesa} </span>
                                </p>
                            </div>
                        </div>
                    </header>

                    <div className="single-user-body">
                        <div className="single-user-body-header">
                            <div className="single-user-body-header-left">
                                {fazat.map((faza, index) => (
                                    <div key={faza.faza_id} onClick={() => {
                                        setActiveFaza(faza.faza_id)
                                        setFazaContent(faza)
                                        setAcitveIndex(index)

                                    }} className={activeFaza === faza.faza_id ? "single-user-body-header-left-faza active-faza" : "single-user-body-header-left-faza"} >
                                        <span className="fs-22 fw-bold" >{faza.faza_name}</span>
                                    </div>
                                ))}

                            </div>
                            <div className="single-user-body-header-right">

                                <button disabled={pacient.kerkes_rishikim === 1 ? true : false} className="single-user-body-header-right-rishikim fs-22 fw-bold" onClick={() => openRishikimPop(!rishikimPop)}>
                                    Kerko rishikim
                                    {rishikimPop &&
                                        <div className="rishikim-pop">
                                            <p className="fs-14 fw-500" >Jeni te sigurt per rishikim?</p>
                                            <div className="rishikim-pop-buttons">
                                                <button className="rishikim-pop-buttons-po-btn fs-14 fw-normal"
                                                    onClick={() => {
                                                        axios.post('https://panel.at-aligners.com/server/mjek/kerkesRishikim', { pacient_id: match.params.id }).then(res => {
                                                            if (res.data.status === 1) {
                                                                alertContext.setAlert(`${res.data.message}`, 'success')
                                                                axios.post('https://panel.at-aligners.com/server/mjek/getSinglePacient', { pacient_id: match.params.id }).then(res => {
                                                                    setPacient(res.data[0])
                                                                })
                                                                axios.post('https://panel.at-aligners.com/server/user/addNotification',
                                                                    {
                                                                        to_user: pacient.mjek_lab,
                                                                        message: `${pacient.mjek_klinika} kerkon rishikim per  (${pacient.emri} ${pacient.mbiemri}) `,
                                                                        link: `/lab/pacientet/${match.params.id}`,
                                                                        type: 3
                                                                    })
                                                            }
                                                        })
                                                    }}
                                                >Po</button>
                                                <button className="rishikim-pop-buttons-jo-btn fs-14 fw-normal" onClick={() => openRishikimPop(false)} >Jo</button>
                                            </div>
                                        </div>
                                    }
                                </button>
                            </div>
                        </div>

                        <div className="single-user-body-content">
                            <div className="single-user-body-content-right">
                                <div className="single-user-body-content-right-top">
                                    <p className="single-user-body-content-right-top-title fs-20 fw-500">Kerkesa e trajtimit te planit</p>
                                    {fazaContent.forma_doktorit === "" ? <button className="single-user-body-content-right-top-button fs-14 fw-normal" onClick={() => setOpenForma(true)}>Ploteso formen online</button>
                                        : <a href={`https://panel.at-aligners.com/server/files/${fazaContent.forma_doktorit}`} className="single-user-body-content-right-top-button fs-14 fw-normal" rel="noreferrer" target="_blank" >Shiko formen</a>}

                                    <button onClick={() => setOpenFotoPacient(true)} className="single-user-body-content-right-top-button fs-14 fw-normal">Ngarko Foto</button>
                                </div>
                                <div className="single-user-body-content-right-bottom">
                                    <p className="single-user-body-content-right-bottom-title fs-16 fw-bold">
                                        Notes
                                    </p>
                                    <div className="single-user-body-content-right-bottom-notes">


                                        {messages.map(message => (

                                            <p className="single-user-body-content-right-bottom-notes-message fs-14 fw-600" >
                                                <span>
                                                    {message.lab_emer === null ? message.mjek_klinika : message.lab_emer} ({message.data})
                                                </span> &nbsp;

                                                {message.text}
                                            </p>

                                        ))}

                                    </div>


                                    <div className="single-user-body-content-right-bottom-notes-form">
                                        <input className="single-user-body-content-right-bottom-notes-form-input" onChange={(e) => setNotes(e.target.value)} value={notes} type="text" name="" id="" />
                                        <button onClick={sendNotes} className="single-user-body-content-right-bottom-notes-form-btn fs-12 fw-normal" >Dergo</button>
                                    </div>


                                </div>
                            </div>

                            <div className="single-user-body-content-left">
                                <p className="single-user-body-content-left-title fs-20 fw-500">Plani i trajtimit ({fazaContent.faza_name})</p>

                                {fazaContent.plani_trajtimit === "" ?
                                    <button className="fs-14 fw-normal" disabled >Plani i trajtimit</button>
                                    : <a className="fs-14 fw-normal" href={`https://panel.at-aligners.com/server/files/${fazaContent.plani_trajtimit}`} rel="noreferrer" target="_blank" download >
                                        Shiko Planin e Trajtimit
                                    </a>}


                                {fazaContent.pamje_3d === "" ? <button className="fs-14 fw-normal" disabled>Pamje 3D</button> : <a rel="noreferrer" href={fazaContent.pamje_3d} target="_blank" className="fs-14 fw-normal"  >Pamje 3D</a>}

                                {fazaContent.derguar_plan === 1 && fazaContent.status_mjek === 0 &&
                                    <div className="single-user-body-content-left-prano">
                                        <button
                                            className="single-user-body-content-left-prano-btn fs-14 fw-normal"
                                            onClick={() => {
                                                axios.post("https://panel.at-aligners.com/server/mjek/reviewPlan", { review: 1, faza_id: activeFaza }).then(res => {

                                                    if (res.data.status === 1) {
                                                        alertContext.setAlert(`${res.data.message}`, 'success')
                                                        axios.post('https://panel.at-aligners.com/server/mjek/getFazatSinglePacient', { pacient_id: match.params.id }).then(res => {
                                                            setFazat(res.data)
                                                            setActiveFaza(res.data[activeIndex].faza_id)
                                                            setFazaContent(res.data[activeIndex])
                                                            axios.post('https://panel.at-aligners.com/server/user/addNotification',
                                                                {
                                                                    to_user: pacient.mjek_lab,
                                                                    message: `${pacient.mjek_klinika} pranoi planin e trajtimit  (${pacient.emri} ${pacient.mbiemri} ${fazaContent.faza_name}) `,
                                                                    link: `/lab/pacientet/${match.params.id}`,
                                                                    type: 1
                                                                })
                                                        })
                                                    }


                                                })
                                            }}
                                        >
                                            Prano
                                        </button>
                                        <div className="single-user-body-content-left-prano-bottom">
                                            <button
                                                className="single-user-body-content-left-prano-bottom-rishiko-btn fs-14 fw-normal"
                                                onClick={() => {
                                                    axios.post("https://panel.at-aligners.com/server/mjek/reviewPlan", { review: 2, faza_id: activeFaza, plan: "", pamje: "" }).then(res => {
                                                        if (res.data.status === 1) {

                                                            alertContext.setAlert(`${res.data.message}`, 'info')
                                                            axios.post('https://panel.at-aligners.com/server/mjek/getFazatSinglePacient', { pacient_id: match.params.id }).then(res => {
                                                                setFazat(res.data)
                                                                setActiveFaza(res.data[activeIndex].faza_id)
                                                                setFazaContent(res.data[activeIndex])
                                                                axios.post('https://panel.at-aligners.com/server/user/addNotification',
                                                                    {
                                                                        to_user: pacient.mjek_lab,
                                                                        message: `${pacient.mjek_klinika} kerkoi rishikim per  planin e trajtimit  (${pacient.emri} ${pacient.mbiemri} ${fazaContent.faza_name}) `,
                                                                        link: `/lab/pacientet/${match.params.id}`,
                                                                        type: 3
                                                                    })
                                                            })
                                                        }

                                                    })
                                                }}
                                            >
                                                Rishiko
                                            </button>
                                            <button
                                                className="single-user-body-content-left-prano-bottom-refuzo-btn fs-14 fw-normal"
                                                onClick={() => {
                                                    axios.post("https://panel.at-aligners.com/server/mjek/reviewPlan", { review: 3, faza_id: activeFaza }).then(res => {

                                                        if (res.data.status === 1) {
                                                            alertContext.setAlert(`${res.data.message}`, 'error')
                                                            axios.post('https://panel.at-aligners.com/server/mjek/getFazatSinglePacient', { pacient_id: match.params.id }).then(res => {
                                                                setFazat(res.data)
                                                                setActiveFaza(res.data[activeIndex].faza_id)
                                                                setFazaContent(res.data[activeIndex])
                                                            })
                                                            axios.post('https://panel.at-aligners.com/server/user/addNotification',
                                                                {
                                                                    to_user: pacient.mjek_lab,
                                                                    message: `${pacient.mjek_klinika} rrefuzoi planin e trajtimit  (${pacient.emri} ${pacient.mbiemri} ${fazaContent.faza_name}) `,
                                                                    link: `/lab/pacientet/${match.params.id}`,
                                                                    type: 2
                                                                })

                                                        }
                                                    })
                                                }}
                                            >Refuzo</button>
                                        </div>
                                    </div>
                                }

                            </div>


                        </div>

                    </div>
                    <Footer />
                    {openForma && <FormaDoktorit openLoading={() => setLoading(true)} closeLoading={() => setLoading(false)} pacId={match.params.id} klinika={pacient.mjek_klinika} labId={pacient.mjek_lab} refreshData={getPacient} closeForma={closeForma} fazaID={activeFaza} emriPac={pacient.emri} mbiemriPac={pacient.mbiemri} activeIndex={activeIndex} />}


                </div>
            </div>
        </>
    )
}
