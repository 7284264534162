import React, { useState, useContext } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import AlertContext from '../../../context/AlertContext';
import axios from 'axios';

export default function Link3d({ pamje_link, closeLink, fazaId, refreshData }) {

    const alertContext = useContext(AlertContext);
    const [link, setLink] = useState(pamje_link);

    const saveLink = () => {
        axios.post('https://panel.at-aligners.com/server/lab/addLink', { faza_id: fazaId, pamje_3d: link }).then(res => {
            if (res.data.status === 1) {
                closeLink();
                alertContext.setAlert(`${res.data.message}`, "success");
                refreshData()
            } else {
                alertContext.setAlert(`${res.data.message}`, "error");
            }
        })
    }

    return (
        <ClickAwayListener onClickAway={closeLink}>
            <div className="link-pop" >
                <p className="link-pop-title fs-14 fw-normal">Vendos URL</p>
                <div className="link-pop-input">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16.35" height="16.373" viewBox="0 0 16.35 16.373">
                        <g id="Group_1407" data-name="Group 1407" transform="translate(-10349.573 -4455.921)">
                            <path id="Path_810" data-name="Path 810" d="M1324.125,662.775c.035-.17.065-.341.107-.509a3.581,3.581,0,0,1,.922-1.687c1.143-1.145,2.286-2.29,3.454-3.41a4.73,4.73,0,0,1,2.354-1.274,3.036,3.036,0,0,1,2.9.885,1,1,0,0,1,.022,1.375,1.012,1.012,0,0,1-1.358.14.767.767,0,0,1-.087-.07,1.231,1.231,0,0,0-1.484-.223,3.694,3.694,0,0,0-1.116.785q-1.6,1.575-3.185,3.16a1.77,1.77,0,0,0-.342,1.981,1.85,1.85,0,0,0,3,.511c.261-.259.52-.519.782-.776a1.019,1.019,0,0,1,1.75.626.86.86,0,0,1-.227.709c-.413.421-.826.847-1.277,1.226a3.487,3.487,0,0,1-1.875.771.457.457,0,0,0-.089.029h-.767a.9.9,0,0,0-.106-.03,3.784,3.784,0,0,1-3.219-2.725,7.033,7.033,0,0,1-.157-.694Z" transform="translate(9025.448 3805.272)" />
                            <path id="Path_811" data-name="Path 811" d="M1531.864,498.183a3.68,3.68,0,0,1-1.091,2.672q-1.677,1.687-3.379,3.35a4.823,4.823,0,0,1-2.129,1.295,3.062,3.062,0,0,1-3.158-.947.992.992,0,0,1,.049-1.4,1.019,1.019,0,0,1,1.39-.027c.036.032.069.066.1.1a1.115,1.115,0,0,0,1.305.244,3.133,3.133,0,0,0,.989-.689q1.694-1.67,3.379-3.35a1.8,1.8,0,0,0-.218-2.719,1.91,1.91,0,0,0-1.1-.454,1.372,1.372,0,0,0-1.066.415c-.35.342-.692.693-1.045,1.032a1.013,1.013,0,0,1-1.7-.418.911.911,0,0,1,.2-.938,15.688,15.688,0,0,1,1.37-1.337,3.294,3.294,0,0,1,3.6-.489,3.936,3.936,0,0,1,2.453,3.006,2.625,2.625,0,0,1,.043.316C1531.87,497.959,1531.864,498.071,1531.864,498.183Z" transform="translate(8834.057 3961.721)" />
                        </g>
                    </svg>
                    <input type="text" value={link} onChange={(e) => setLink(e.target.value)} />
                </div>
                <div className="link-pop-buttons">
                    <button className="fs-14 fw-600" onClick={saveLink} >Ruaj</button>
                    <a href={pamje_link} className="fs-14 fw-600" >Shiko</a>
                </div>
            </div>
        </ClickAwayListener>
    )
}
