import React, { useState, useContext, useEffect } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import axios from 'axios'
import AlertContext from '../context/AlertContext';
import Player from './Player';

export default function SupportiKlinik({ closeSupport }) {

    const alertContext = useContext(AlertContext)
    const [video, setVideo] = useState("");
    const [fileName, setFileName] = useState("");
    const [videos, setVideos] = useState([]);
    const [openVideoPLayer,setOpenVideoPlayer] = useState(false)
    const [srcVideoPlayer,setSrcVideoPlayer] = useState("")




    useEffect(() => {
        axios.get('https://panel.at-aligners.com/server/lab/getAllVideos').then(res => {
            setVideos(res.data)
        })
    }, [])

    const addVideo = () => {

        const form = new FormData();
        form.append('video', video);
        form.append('name', fileName);

        axios.post('https://panel.at-aligners.com/server/lab/addVideo', form).then(res => {
            if (res.data.status === 1) {
                setVideo("")
                setFileName("")
                alertContext.setAlert(`${res.data.message}`, 'success')
                axios.get('https://panel.at-aligners.com/server/lab/getAllVideos').then(res => {
                    setVideos(res.data)
                })
            } else {
                alertContext.setAlert(`${res.data.message}`, 'error')
            }
        })
    }
    return (
        <>
        {openVideoPLayer && <Player videoSrc={srcVideoPlayer} closeVideo={() => setOpenVideoPlayer(false)} />}
        <ClickAwayListener onClickAway={closeSupport} >
            <div className="suporti-klinik" >
                <div className="suporti-klinik-top">
                    {videos.map(item => (
                        <div className="suporti-klinik-top-item">
                            <video onClick={(e) => {
                                setSrcVideoPlayer(e.target.src)
                                setOpenVideoPlayer(true)
                            }} src={`https://panel.at-aligners.com/server/files/${item.files}`} ></video>
                            <p>{item.file_name}</p>
                        </div>
                    ))}
                </div>

                <div className="suporti-klinik-bottom">

                    {video === "" ?
                        <>
                            <input
                                type="file"
                                hidden
                                id="file"
                                onChange={(e) => {
                                    setVideo(e.target.files[0])
                                }}
                            />

                            <label className="suporti-klinik-bottom-btn-ngarko fs-14 fw-600" htmlFor="file"  >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="33" viewBox="0 0 16 33">
                                    <text id="_" data-name="+" transform="translate(8 25)" fill="#fff" font-size="24" font-family="Poppins-Light, Poppins" font-weight="300"><tspan x="-7.86" y="0">+</tspan></text>
                                </svg> Ngarko</label>
                        </>
                        :
                        <>
                            <input className="suporti-klinik-bottom-input" onChange={(e) => setFileName(e.target.value)} type="text" placeholder="Emri i video" />
                            <button className="suporti-klinik-bottom-button-perfundo fs-14 fw-600" onClick={addVideo} >Perfundo Ngarkimin</button>
                        </>
                    }
                </div>
            </div>
        </ClickAwayListener>
        </>
    )
}
