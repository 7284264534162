import React, { useState } from 'react'

export default function NofullaSiper({ color }) {

    const [line1, setLine1] = useState({ show: false, color: color })
    const [line2, setLine2] = useState({ show: false, color: color })
    const [line3, setLine3] = useState({ show: false, color: color })
    const [line4, setLine4] = useState({ show: false, color: color })
    const [line5, setLine5] = useState({ show: false, color: color })
    const [line6, setLine6] = useState({ show: false, color: color })
    const [line7, setLine7] = useState({ show: false, color: color })
    const [line8, setLine8] = useState({ show: false, color: color })
    const [line9, setLine9] = useState({ show: false, color: color })
    const [line10, setLine10] = useState({ show: false, color: color })
    const [line11, setLine11] = useState({ show: false, color: color })
    const [line12, setLine12] = useState({ show: false, color: color })
    const [line13, setLine13] = useState({ show: false, color: color })
    const [line14, setLine14] = useState({ show: false, color: color })
    const [line15, setLine15] = useState({ show: false, color: color })

    return (
        <>
            <svg style={{ margin: ' 20px 0' }} id="Layer_1" className="tooth-upper" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 355.88 37.62">
                <defs>

                </defs>

                {line1.show && <line onClick={() => setLine1({ show: false })} class="cls-1" x1="38.68" y1="37.67" x2="38.68" y2="0.05" stroke={line1.color} transform="translate(-13)" />}
                {line2.show && <line onClick={() => setLine2({ show: false })} class="cls-1" x1="38.68" y1="37.67" x2="38.68" y2="0.05" stroke={line2.color} transform="translate(13)" />}
                {line3.show && <line onClick={() => setLine3({ show: false })} class="cls-1" x1="38.68" y1="37.67" x2="38.68" y2="0.05" stroke={line3.color} transform="translate(41)" />}
                {line4.show && <line onClick={() => setLine4({ show: false })} class="cls-1" x1="38.68" y1="37.67" x2="38.68" y2="0.05" stroke={line4.color} transform="translate(62)" />}
                {line5.show && <line onClick={() => setLine5({ show: false })} class="cls-1" x1="38.68" y1="37.67" x2="38.68" y2="0.05" stroke={line5.color} transform="translate(82)" />}
                {line6.show && <line onClick={() => setLine6({ show: false })} class="cls-1" x1="38.68" y1="37.67" x2="38.68" y2="0.05" stroke={line6.color} transform="translate(100)" />}
                {line7.show && <line onClick={() => setLine7({ show: false })} class="cls-1" x1="38.68" y1="37.67" x2="38.68" y2="0.05" stroke={line7.color} transform="translate(118)" />}
                {line8.show && <line onClick={() => setLine8({ show: false })} class="cls-1" x1="38.68" y1="37.67" x2="38.68" y2="0.05" stroke={line8.color} transform="translate(140)" />}
                {line9.show && <line onClick={() => setLine9({ show: false })} class="cls-1" x1="38.68" y1="37.67" x2="38.68" y2="0.05" stroke={line9.color} transform="translate(161)" />}
                {line10.show && <line onClick={() => setLine10({ show: false })} class="cls-1" x1="38.68" y1="37.67" x2="38.68" y2="0.05" stroke={line10.color} transform="translate(178)" />}
                {line11.show && <line onClick={() => setLine11({ show: false })} class="cls-1" x1="38.68" y1="37.67" x2="38.68" y2="0.05" stroke={line11.color} transform="translate(198)" />}
                {line12.show && <line onClick={() => setLine12({ show: false })} class="cls-1" x1="38.68" y1="37.67" x2="38.68" y2="0.05" stroke={line12.color} transform="translate(217)" />}
                {line13.show && <line onClick={() => setLine13({ show: false })} class="cls-1" x1="38.68" y1="37.67" x2="38.68" y2="0.05" stroke={line13.color} transform="translate(239)" />}
                {line14.show && <line onClick={() => setLine14({ show: false })} class="cls-1" x1="38.68" y1="37.67" x2="38.68" y2="0.05" stroke={line14.color} transform="translate(265)" />}
                {line15.show && <line onClick={() => setLine15({ show: false })} class="cls-1" x1="38.68" y1="37.67" x2="38.68" y2="0.05" stroke={line15.color} transform="translate(291)" />}

                <path
                    onClick={() => {
                        setLine1({
                            show: !line1.show,
                            color: color
                        })
                    }}
                    class="st5" d="M19.2,7.9c-2.4-1.2-4.9-1.8-7.6-1.9c-1.4,0-2.8,0.3-4.1,0.8c-2.6,1.2-4.6,3.3-5.6,6C-0.6,21.7,1.8,25,1.8,25
	c0.8,1.5,2.2,2.5,3.9,2.7C8.8,28,11.9,25,11.9,25l0.2-0.2l0.3,0.2c2.2,1.9,4.3,2.5,6.2,2c4.1-1.2,6-7.7,6.1-7.7
	C25.1,14.7,23,10.4,19.2,7.9z"/>
                <path d="M25.5,18.5c0,0.4-0.1,0.7-0.1,1.1c0-0.1,0-0.2,0-0.2C25.5,19,25.5,18.8,25.5,18.5z" />
                <path d="M51.7,17.5c-0.1,1.2,0,2.5,0.2,3.7C52,20,52,18.7,51.7,17.5z" />
                <path d="M78.8,18.9c0-0.6,0-1.2,0-1.8c-0.1,0.8-0.2,1.6-0.2,2.4l0.2-0.4V18.9z" />
                <path d="M100.2,14.7v7.8C100.6,19.9,100.6,17.3,100.2,14.7z" />
                <path d="M121.3,26.2l-0.6-11.8c0,0-0.1-0.2-0.1-0.6l0.3,13L121.3,26.2L121.3,26.2z" />
                <path d="M139.2,26.8l-0.2-13c0,0.1-0.1,0.3-0.1,0.4v13.5C139,27.5,139.1,27.2,139.2,26.8L139.2,26.8z" />
                <path d="M156.6,15.5c0,0.2-0.1,0.5-0.1,0.7v10.2c0.1-0.7,0.1-1.3,0.1-2V15.5z" />
                <path d="M178.5,14.4L178.5,14.4c-0.1,0.7-0.2,1.4-0.3,2.2v10.2c0.2-0.9,0.3-1.7,0.3-2.6V14.4z" />
                <path d="M199.4,17.3c0,0,0-0.5,0-1.2c-0.4,1.3-0.6,2.7-0.7,4.1l0.2,9.8c0,0.1,0,0.2,0,0.3c0,0,0-0.1,0-0.2L199.4,17.3z" />
                <path d="M216.7,12.6v15.9c0,0.3,0.1,0.5,0.3,0.7l-0.2-13.9C216.9,14.4,216.8,13.5,216.7,12.6z" />
                <path d="M236,16c0-0.7,0-1.3-0.1-2c-0.1,0.7-0.2,1.3-0.2,2v9.7l0.1-0.2L236,16z" />
                <path d="M255.9,13.9v-0.1l-0.1,0.5v10.3c0-0.2,0.1-0.3,0.1-0.5V13.9z" />
                <path d="M277.6,17.1c-0.1,1.1-0.1,2.3,0.1,3.4v0.1v0.1c0-0.1,0-0.1,0-0.2L277.6,17.1z" />

                {/* Line 1 */}
                <path
                    onClick={() => {
                        setLine1({
                            show: !line1.show,
                            color: color
                        })
                    }}
                    class="st6" d="M9,5.5c0,0-6,2.5-7.3,6.8C0.4,16.6-0.2,21.6,1.1,24c1.3,2.4,2.7,4.5,6.3,3.8c2.6-0.5,4-1.7,4.5-2.3v-20
	C11.1,5.4,10.1,5.4,9,5.5z"/>
                <path
                    onClick={() => {
                        setLine1({
                            show: !line1.show,
                            color: color
                        })
                    }}
                    class="st7" d="M23.1,10.9c0,0-2.7-3.3-5.2-4c-1.9-0.5-3.2-1.3-6-1.4v20c0.2-0.2,0.3-0.4,0.3-0.4s3.9,3.2,6.1,2.2
	s5.7-3.6,6.5-7.6S23.1,10.9,23.1,10.9z"/>
                <path
                    onClick={() => {
                        setLine1({
                            show: !line1.show,
                            color: color
                        })
                    }}
                    class="st6" d="M38.8,6.1c0,0-7.5-0.8-9.4,1.7c-1.9,2.5-2.7,6.8-2.7,6.8s-1.2,5.2-0.8,6.9c0.4,1.7,2.3,5.3,4.7,6.1
	c2.4,0.8,4.1,0.8,6-0.3c1.6-0.9,1.4-1.5,2.2-1.5L38.8,6.1C38.8,6.1,38.8,6.1,38.8,6.1z"/>
                {/* End Line 1 */}


                {/*  Line 2 */}
                <path
                    onClick={() => {
                        setLine2({
                            show: !line2.show,
                            color: color
                        })
                    }}
                    class="st7" d="M51.6,18.4c-0.8-4.9-3.2-9.2-3.2-9.2s-2.8-4.3-9.6-3.1v19.7c0.2,0,0.4,0,0.7,0c1.6,0.2,5.2,2,5.7,2.6
	s2.8-1.6,2.8-1.6S52.4,23.3,51.6,18.4z"/>


                <path
                    onClick={() => {
                        setLine2({
                            show: !line2.show,
                            color: color
                        })
                    }}
                    class="st6" d="M64.5,3.8c0,0-6.9-0.7-11.2,7.9C49,20.3,55,26.9,55,26.9s3.8,3,7.4,0.3c3.6-2.7,2.1-2.9,2.9-3c0,0,0.1,0,0.1,0
	V3.8C65.1,3.8,64.8,3.8,64.5,3.8z"/>

                {/* End Line 2 */}


                {/* Line 3 */}

                <path
                    onClick={() => {
                        setLine3({
                            show: !line3.show,
                            color: color
                        })
                    }}
                    class="st6" d="M89,3.9c0,0-5.9-0.4-8.6,6.3c0,0-1.8,9.6-1.4,13.3l0.7,1.3c0,0,6.9,5,7.8,5.1c0.5,0.1,1.2,0.3,2,0.4V3.8
	C89.3,3.9,89.2,3.9,89,3.9z"/>

                <path
                    onClick={() => {
                        setLine3({
                            show: !line3.show,
                            color: color
                        })
                    }}
                    class="st7" d="M78.1,13.9C77.3,9.8,73,6,73,6s-3.5-1.9-7.6-2.2v20.4c0.9,0,3.1,1,3.1,1s6.1,1.1,8.3-3.5
	C79,17.1,78.9,18,78.1,13.9z"/>


                {/* End Line 3 */}

                {/* Line 4 */}

                <path
                    onClick={() => {
                        setLine4({
                            show: !line4.show,
                            color: color
                        })
                    }}
                    class="st7" d="M100,14.8c0,0-2-9-6.9-10.7c0,0-2.1-0.4-3.6-0.3v26.4c0.6,0.1,1.2,0.1,1.9-0.1c1.5-0.4,6.4-2.4,7.8-5.1
	S100,14.8,100,14.8z"/>
                <path
                    onClick={() => {
                        setLine4({
                            show: !line4.show,
                            color: color
                        })
                    }}
                    class="st6" d="M110.1,4.8c0,0-6.5,0.5-8.4,5.1c-1.9,4.6-1,12.3-1,12.3v3.5c0,0,3.1,5.3,9.2,5.6c0.1,0,0.2,0,0.2,0L110.1,4.8
	C110.1,4.8,110.1,4.8,110.1,4.8z"/>
                {/* End Line 4 */}


                {/*  Line 5 */}


                <path
                    onClick={() => {
                        setLine5({
                            show: !line5.show,
                            color: color
                        })
                    }}
                    class="st7" d="M120.1,12.2c-0.7-1.1-3-7.2-10-7.4v26.5c6,0.2,9.4-2.7,11-5.7C121.1,25.6,120.8,13.3,120.1,12.2z" />
                <path
                    onClick={() => {
                        setLine5({
                            show: !line5.show,
                            color: color
                        })
                    }}
                    class="st6" d="M121,14c-0.8,11.2,0.8,12.9,0.8,12.9s6.1,7.7,7.5,8.2c0.2,0.1,0.5,0.1,0.8,0V2.1C128.6,2.3,121.7,4.1,121,14z"
                />

                {/* End Line 5 */}


                {/* Line 6  */}
                <path
                    onClick={() => {
                        setLine6({
                            show: !line6.show,
                            color: color
                        })
                    }}
                    class="st7" d="M138.7,8.6c-0.1-0.1-1.4-7.2-8.3-6.6c0,0-0.1,0-0.3,0v33.1c1.6-0.3,4.2-2.2,4.2-2.2s3.7-2.7,4.7-6.6L138.7,8.6z
	"/>
                <path
                    onClick={() => {
                        setLine6({
                            show: !line6.show,
                            color: color
                        })
                    }}

                    class="st6" d="M139.1,14.2l0.1,14.2c0,0,3.5,5,8.1,5.2c0.1,0,0.3,0,0.4,0V4.9C146.3,5.1,141.1,6.2,139.1,14.2z" />
                {/* End Line 6  */}

                {/* Line 7 */}
                <path
                    onClick={() => {
                        setLine7({
                            show: !line7.show,
                            color: color
                        })
                    }}
                    class="st7" d="M156,12.2c0,0-1.5-6.3-7.9-7.3c0,0-0.1,0-0.4,0v28.7c4.3,0.1,6.9-1.6,8-5.1C156.8,24.9,156,12.2,156,12.2z" />
                <path
                    onClick={() => {
                        setLine7({
                            show: !line7.show,
                            color: color
                        })
                    }}
                    class="st6" d="M157.1,15.9c-0.1,10.1,0,14.7,0,14.7s4.8,4.5,11.1,3.4V2.8C167.1,3.2,157.2,6.4,157.1,15.9z" />
                {/* End Line 7 */}

                {/* Line 8 */}
                <path
                    onClick={() => {
                        setLine8({
                            show: !line8.show,
                            color: color
                        })
                    }}

                    class="st7" d="M178,12.9c-0.7-1.3-3.3-8.1-9.7-10.1c0,0,0,0-0.1,0V34c0.6-0.1,1.3-0.3,1.9-0.5c7.2-2.6,7.7-4.5,7.9-7.2
	C178.2,23.6,178.7,14.2,178,12.9z"/>



                <path
                    onClick={() => {
                        setLine8({
                            show: !line8.show,
                            color: color
                        })
                    }}
                    class="st6" d="M188.2,2.5c0,0-9.1,2.3-9.4,12.3s-1.5,13.8,3.5,16.2c1.8,0.9,3.9,1.6,5.9,2L188.2,2.5
	C188.2,2.5,188.2,2.5,188.2,2.5z"/>

                {/* End Line 8 */}



                {/* Line 9 */}

                <path
                    onClick={() => {
                        setLine9({
                            show: !line9.show,
                            color: color
                        })
                    }}

                    class="st7" d="M198.8,13.8c-0.5-1.5-1.1-8.9-10.6-11.3V33c3.5,0.6,7.1,0.3,9.9-2l0.7-6.4C198.8,24.6,199.3,15.3,198.8,13.8z"
                />
                <path
                    onClick={() => {
                        setLine9({
                            show: !line9.show,
                            color: color
                        })
                    }}
                    class="st6" d="M199.7,15.9c0,0-2.2,12.8-0.4,14.5c1.6,1.5,5.2,3.9,8.8,4.2V4.5C206.7,5.3,201.6,8.7,199.7,15.9z" />
                {/* End Line 9 */}


                {/* Line 10 */}

                <path
                    onClick={() => {
                        setLine10({
                            show: !line10.show,
                            color: color
                        })
                    }}
                    class="st7" d="M216,10.8c0,0-1.3-6.2-7.6-6.5c0,0-0.1,0.1-0.3,0.2v30.1c0.5,0,1,0,1.4,0c4-0.4,6.4-2.9,7.3-5.8 C217.7,25.9,216,10.8,216,10.8z" />
                <path
                    onClick={() => {
                        setLine10({
                            show: !line10.show,
                            color: color
                        })
                    }}
                    class="st6" d="M217.1,9.6c-0.1,7.6,0.1,18.9,0.1,18.9s5,6,8.7,6.7V2.5C223.9,2.5,217.2,3.2,217.1,9.6z" />
                {/* End Line 10 */}

                {/* Line 11 */}

                <path
                    onClick={() => {
                        setLine11({
                            show: !line11.show,
                            color: color
                        })
                    }}
                    class="st7" d="M235.4,12.2c0,0-1-8.5-8.9-9.7c0,0-0.2,0-0.6,0v32.7c0.2,0,0.3,0.1,0.5,0.1c3.8,0.3,8.8-9.1,9-9.8
	C235.6,24.8,235.4,12.2,235.4,12.2z"/>


                <path
                    onClick={() => {
                        setLine11({
                            show: !line11.show,
                            color: color
                        })
                    }}
                    class="st6" d="M236.2,14.2L236,26c0,0,3.4,5,9.2,4.9V5C245,5,237.4,5.2,236.2,14.2z" />

                {/*End Line 11 */}

                {/* Line 12 */}
                <path
                    onClick={() => {
                        setLine12({
                            show: !line12.show,
                            color: color
                        })
                    }}
                    class="st7" d="M255.4,12.4c0,0-1.1-7.2-10.2-7.4c0,0,0,0,0,0v25.9c0.3,0,0.7,0,1-0.1c6.4-0.8,8.9-5.2,9.3-6.6
	C255.9,22.8,255.4,12.4,255.4,12.4z"/>



                <path
                    onClick={() => {
                        setLine12({
                            show: !line12.show,
                            color: color
                        })
                    }}
                    class="st6" d="M256.3,13.7v11.2c0,0,4.3,5.4,10.4,6V4.2C265.6,4.2,258.3,4.3,256.3,13.7z" />
                {/* End Line 12 */}


                {/* Line 13 */}

                <path
                    onClick={() => {
                        setLine13({
                            show: !line13.show,
                            color: color
                        })
                    }}
                    class="st7" d="M276.8,10.9c0-0.2-1.3-5.5-9.9-6.7c0,0-0.1,0-0.2,0v26.7c0.5,0.1,1.1,0.1,1.7,0.1c0,0,7.1-0.9,9-10L276.8,10.9z
	"/>
                <path
                    onClick={() => {
                        setLine13({
                            show: !line13.show,
                            color: color
                        })
                    }}
                    class="st6" d="M290.4,4.1l-6.9,0.1c0,0-7.1,7-5.1,16.5c0,0,3,5.5,7.2,4.6c4.2-0.9,0.5-1.8,4.9-1.7c0,0,0.1,0,0.2-0.1V4.1
	H290.4z"/>

                {/* End Line 13 */}

                {/* Line 14 */}
                <path
                    onClick={() => {
                        setLine14({
                            show: !line14.show,
                            color: color
                        })
                    }}
                    class="st7" d="M303.6,15.8c-1.1-8.7-4.4-11.3-4.4-11.3l-1.7-0.4h-6.8v19.4c0.5-0.1,1.5,0.1,2.8,2.3c1.7,2.9,3.9,2.9,6.1-0.4
	C299.6,25.4,304.7,24.5,303.6,15.8z"/>



                <path
                    onClick={() => {
                        setLine14({
                            show: !line14.show,
                            color: color
                        })
                    }}
                    class="st6" d="M314.4,5c0,0-7.4-1.5-10.1,11.4c0,0-1.2,8.2,4.8,10.5c0,0,2.1,0.2,4.7-1.7c0,0,1.4-0.1,2.8,0V5.1L314.4,5z" />
                {/*End Line 14 */}

                {/* Line 15 */}

                <path
                    onClick={() => {
                        setLine15({
                            show: !line15.show,
                            color: color
                        })
                    }}
                    class="st7" d="M327.7,11.9c-1.4-3.1-2.8-6.4-2.8-6.4l-5.6-0.4l-2.7-0.1v20.1c1.2,0,2.5,0.2,2.8,0.5c0.7,0.8,2.7,2.7,4.3,2.2
	s3-3.2,3-3.2s3.4-1.3,3.6-4.3S329.1,15,327.7,11.9z"/>



                <path
                    onClick={() => {
                        setLine15({
                            show: !line15.show,
                            color: color
                        })
                    }}
                    class="st6" d="M340.2,6h-2.6c0,0-6,5.2-6.6,10.6c-0.6,5.4,2.8,9,5,10.1c2.2,1.1,4.7-1.3,4.7-1.3s1.3,0,2.7,0.1V5.9
	C341.7,6,340.2,6,340.2,6z"/>
                <path
                    onClick={() => {
                        setLine15({
                            show: !line15.show,
                            color: color
                        })
                    }}
                    class="st7" d="M355.3,19.1c-0.8-4.2-1.7-8-3-9.5s-3.2-3.8-4.2-3.8c-0.5,0-2.7,0.1-4.7,0.1v19.6c1.4,0.1,2.8,0.4,3.5,1.1
	c1.3,1.3,5.3-0.2,6-0.8C353.6,25.2,356.1,23.3,355.3,19.1z"/>

                <path
                    onClick={() => {
                        setLine15({
                            show: !line15.show,
                            color: color
                        })
                    }}
                    class="st5" d="M348,6.5h-10.1c-0.9,0.8-7.1,6.3-6.6,12.4c0,0,0.9,5.3,3.8,7.2c1,0.7,2.1,0.9,3.3,0.6c0.1,0,0.3-0.1,0.4-0.2
	c1.2-0.9,2.6-1.4,4.1-1.4c1.7,0,3.4,0.6,4.8,1.6c0.1,0.1,0.3,0.1,0.4,0.1c0.9,0,3.9,0.1,5.7-1.9c1.3-1.4,1.7-3.5,1.2-6.3
	C354.9,18.5,354.6,9.5,348,6.5z"/>

                {/* End Line 15 */}




                <path d="M304,17.3c-0.1,0.7-0.2,1.5-0.2,2.3v0.1c0,0,0,0.3,0.1,0.6C304,19.3,304,18.3,304,17.3z" />

                <path d="M330.5,19c0,0,0.1,0.3,0.2,0.8c0-0.5-0.1-1-0.2-1.5C330.5,18.5,330.5,18.7,330.5,19z" />
                <path class="st8" d="M130.4,2.2c0,0-8.6,0.8-9.4,12s0.8,12.9,0.8,12.9s6.1,7.7,7.5,8.2s5-2.2,5-2.2s3.7-2.7,4.7-6.6l-0.3-17.7
	C138.6,8.7,137.3,1.6,130.4,2.2z"/>
                <path class="st8" d="M148.1,5.1c0,0-6.7,0-9,9.3l0.1,14.2c0,0,3.5,5,8.1,5.2s7.3-1.5,8.4-5.1s0.3-16.3,0.3-16.3S154.5,6.1,148.1,5.1
	z"/>
                <path class="st8" d="M188.2,2.7c0,0-9.1,2.3-9.4,12.3s-1.5,13.8,3.5,16.2s11.4,3.6,15.8,0l0.7-6.4c0,0,0.5-9.3,0-10.8
	S197.7,5.1,188.2,2.7z"/>
                <path class="st8" d="M208.4,4.5c0,0-6.5,3.3-8.7,11.6c0,0-2.2,12.8-0.4,14.5s6.2,4.6,10.2,4.2s6.4-2.9,7.3-5.8S216,11,216,11
	S214.7,4.8,208.4,4.5z"/>
                <path class="st8" d="M226.5,2.7c0,0-9.3-0.5-9.4,7.1s0.1,18.9,0.1,18.9s5.4,6.5,9.2,6.8s8.8-9.1,9-9.8s0-13.3,0-13.3
	S234.4,3.9,226.5,2.7z"/>
                <path class="st8" d="M245.2,5.2c0,0-7.8,0.1-9,9.2L236,26.2c0,0,3.8,5.6,10.2,4.8s8.9-5.2,9.3-6.6s-0.1-11.8-0.1-11.8
	S254.3,5.4,245.2,5.2z"/>
                <path class="st8" d="M266.9,4.4c0,0-8.4-0.7-10.6,9.5v11.2c0,0,5.1,6.4,12.1,6.1c0,0,7.1-0.9,9-10l-0.6-10.1
	C276.8,10.9,275.5,5.6,266.9,4.4z"/>
                <path class="st8" d="M290.4,4.3l-6.9,0.1c0,0-7.1,7-5.1,16.5c0,0,3,5.5,7.2,4.6s0.5-1.8,4.9-1.7c0,0,1.3-0.7,3,2.2s3.9,2.9,6.1-0.4
	c0,0,5.1-0.9,4-9.6s-4.4-11.3-4.4-11.3l-1.7-0.4L290.4,4.3z"/>
                <path class="st8" d="M314.4,5.2c0,0-7.4-1.5-10.1,11.4c0,0-1.2,8.2,4.8,10.5c0,0,2.1,0.2,4.7-1.7c0,0,4.9-0.3,5.6,0.5
	s2.7,2.7,4.3,2.2s3-3.2,3-3.2s3.4-1.3,3.6-4.3s-1.2-5.4-2.6-8.5s-2.8-6.4-2.8-6.4l-5.6-0.4L314.4,5.2z"/>
                <path class="st8" d="M340.2,6.2h-2.6c0,0-6,5.2-6.6,10.6s2.8,9,5,10.1s4.7-1.3,4.7-1.3s4.9-0.1,6.2,1.2s5.3-0.2,6-0.8
	s3.2-2.5,2.4-6.7s-1.7-8-3-9.5S349.1,6,348.1,6S340.2,6.2,340.2,6.2z"/>
                <path class="st8" d="M110.1,5c0,0-6.5,0.5-8.4,5.1s-1,12.3-1,12.3v3.5c0,0,3.1,5.3,9.2,5.6s9.6-2.7,11.2-5.7c0,0-0.3-12.3-1-13.4
	S117.1,5.2,110.1,5z"/>
                <path class="st8" d="M89,4.1c0,0-5.9-0.4-8.6,6.3c0,0-1.8,9.6-1.4,13.3l0.7,1.3c0,0,6.9,5,7.8,5.1s2.4,0.7,3.9,0.3s6.4-2.4,7.8-5.1
	S100,15,100,15s-2-9-6.9-10.7C93.1,4.3,90.5,3.8,89,4.1z"/>
                <path class="st8" d="M64.5,4c0,0-6.9-0.7-11.2,7.9S55,27.1,55,27.1s3.8,3,7.4,0.3c3.6-2.7,2.1-2.9,2.9-3s3.2,1,3.2,1
	s6.1,1.1,8.3-3.5s2.1-3.7,1.3-7.8S73,6.2,73,6.2S68.9,4,64.5,4z"/>
                <path class="st8" d="M38.8,6.3c0,0-7.5-0.8-9.4,1.7s-2.7,6.8-2.7,6.8s-1.2,5.2-0.8,6.9s2.3,5.3,4.7,6.1s4.1,0.8,6-0.3
	s1.3-1.7,2.9-1.5s5.2,2,5.7,2.6S48,27,48,27s4.4-3.5,3.6-8.4s-3.2-9.2-3.2-9.2S45.6,5.1,38.8,6.3z"/>
                <path class="st8" d="M9,5.7c0,0-6,2.5-7.3,6.8s-1.9,9.3-0.6,11.7s2.7,4.5,6.3,3.8s4.8-2.7,4.8-2.7s3.9,3.2,6.1,2.2s5.7-3.6,6.5-7.6
	s-1.7-8.8-1.7-8.8s-2.7-3.3-5.2-4S14,5.4,9,5.7z"/>


                <path class="st9" d="M168.2,2.7c0,0-11.1,3-11.2,13.1s0,14.7,0,14.7s5.8,5.5,13,2.9s7.7-4.5,7.9-7.2s0.7-12.1,0-13.4
	S174.6,4.7,168.2,2.7z"/>
            </svg>
        </>
    )
}
