import React, { useState, useContext, useEffect } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import axios from 'axios'
import AlertContext from '../../../context/AlertContext';
import Player from '../../../utilities/Player';

export default function SupportVideos({closeSupport}) {


    const [videos, setVideos] = useState([]);
    const [openVideoPLayer,setOpenVideoPlayer] = useState(false)
    const [srcVideoPlayer,setSrcVideoPlayer] = useState("")




    useEffect(() => {
        axios.get('https://panel.at-aligners.com/server/lab/getAllVideos').then(res => {
            setVideos(res.data)
        })
    }, [])

    return (
        <>
        {openVideoPLayer && <Player videoSrc={srcVideoPlayer} closeVideo={() => setOpenVideoPlayer(false)} />}
        <ClickAwayListener onClickAway={closeSupport} >
            <div className="suporti-klinik " >
                <div className="suporti-klinik-top support-klinik-mjek-top">
                    {videos.map(item => (
                        <div className="suporti-klinik-top-item">
                            <video onClick={(e) => {
                                setSrcVideoPlayer(e.target.src)
                                setOpenVideoPlayer(true)
                            }} src={`https://panel.at-aligners.com/server/files/${item.files}`} ></video>
                            <p>{item.file_name}</p>
                        </div>
                    ))}
                </div>
            </div>
        </ClickAwayListener>
        </>
    )
}
