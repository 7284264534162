import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Person from '../../../images/person.svg';
import List from '../../../images/list-text.svg';
import Add from '../../../images/add.svg';
import Logout from '../../../images/logout.svg';
import axios from 'axios';
import UserLogo from '../../../images/icon-login.png'
import CollapseContext from '../../../context/CollapseContex';
import AlertContext from '../../../context/AlertContext';
import Loading from '../../../utilities/Loading';

export default function Sidebar({ logout }) {

    const alertContext = useContext(AlertContext)
    const collapseContex = useContext(CollapseContext)
    const { value } = collapseContex;
    const [activeLink, setActiveLink] = useState('mjek')
    const [user, setUser] = useState({})
    const [imageProfile, setImageProfile] = useState("")
    const [loading, setLoading] = useState(false)

    useEffect(() => {

        if (window.location.pathname === '/mjek') {
            setActiveLink('mjek')
        } else if (window.location.pathname === '/mjek/shtoPaciente') {
            setActiveLink('shtoPac')
        } else if (window.location.pathname === '/mjek/profili') {
            setActiveLink('Profili')
        }

        axios.post('https://panel.at-aligners.com/server/mjek/getProfile', { token: JSON.parse(localStorage.getItem('token')) }).then(res => {
            setUser(res.data[0])
        })

    }, [])

    const updateProfilePhoto = () => {

        const foto = new FormData();
        foto.append('foto', imageProfile);
        foto.append('user_id', JSON.parse(localStorage.getItem('id')))

        setLoading(true)

        axios.post('https://panel.at-aligners.com/server/user/uploadProfilePicture', foto).then(res => {
            if (res.data.status === 1) {
                setLoading(false)
                setImageProfile("")
                alertContext.setAlert(`${res.data.message}`, 'success')
                axios.post('https://panel.at-aligners.com/server/mjek/getProfile', { token: JSON.parse(localStorage.getItem('token')) }).then(res => {
                    setUser(res.data[0])
                })
            }

        })


    }

    return (
        <>
            {loading && <Loading />}
            {value === true &&
                <div className="sidebar-opa" onClick={() => collapseContex.setFalse()} ></div>
            }
            <div className={value === true ? "sidebar sidebar-open " : "sidebar"} >

                <div style={{ width: '100%' }}>

                    <div className="sidebar-profile">


                        <label htmlFor="profile" className="sidebar-profile-image">
                            <input type="file" name="" hidden id="profile" onChange={(e) => {
                                setImageProfile(e.target.files[0])
                            }} />
                            <img className="img-res" src={user.user_image_profile === "" ? UserLogo : `https://panel.at-aligners.com/server/files/${user.user_image_profile}`} alt="" />
                        </label>
                        {imageProfile !== "" &&
                            <div className="foto-buttons">
                                <button className="foto-buttons-ngarko fs-14 fw-500" onClick={() => {
                                    updateProfilePhoto()
                                }} > Ngarko Foton</button>
                                <button className="foto-buttons-anullo fs-14 fw-500" onClick={() => {
                                    setImageProfile("")
                                }} >Anullo</button>
                            </div>
                        }

                        <p className="sidebar-profile-name fs-16 fw-bold" >{user.mjek_pergjegjes} {user.mjek_mbiemer}</p>
                        <p className="sidebar-profile-msg fs-12 fw-300" >Mire se vini!</p>
                    </div>

                    <div className="sidebar-links">
                        <li className={activeLink === 'mjek' ? 'sidebar-link-active' : ''} >
                            <img src={List} alt="" />
                            <Link onClick={() => {
                                setActiveLink('mjek')
                                collapseContex.setFalse()
                            }} className="sidebar-links-link fs-16 fw-normal " to="/mjek/pacientet" >Lista e Pacienteve</Link>

                        </li>

                        <li className={activeLink === 'shtoPac' ? 'sidebar-link-active' : ''}>

                            <img src={Add} alt="" />
                            <Link onClick={() => {
                                setActiveLink('shtoPac')
                                collapseContex.setFalse()
                            }} className="sidebar-links-link fs-16 fw-normal" to="/mjek/shtoPaciente" >Shto Pacient</Link></li>
                        <li className={activeLink === 'Profili' ? 'sidebar-link-active' : ''} >
                            <img src={Person} alt="" />
                            <Link onClick={() => {
                                setActiveLink('Profili')
                                collapseContex.setFalse()
                            }} className="sidebar-links-link fs-16 fw-normal" to="/mjek/profili" >Profili Im</Link></li>
                    </div>
                </div>




                <div className="sidebar-logout" onClick={logout} >
                    <li> <img src={Logout} alt="" /> <p className="fs-16 fw-normal" >Logut</p></li>
                </div>

            </div>
        </>
    )

}
