import './App.css';
import Login from './pages/login/login'
import Mjek from './pages/mjek/Mjek';
import Lab from './pages/laborator/Lab';
import Admin from './pages/admin/Admin';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './utilities/PrivateRoute';
import CollpaseState from './context/CollapseState';
import AlertState from './context/AlertState';


function App() {
  return (
    <CollpaseState>
      <AlertState>
        <div className="App">
          <Switch>
            <Route exact path="/" component={Login} />
            <PrivateRoute path="/admin" component={Admin} />
            <PrivateRoute path="/lab" component={Lab} />
            <PrivateRoute path="/mjek" component={Mjek} />
          </Switch>
        </div>
      </AlertState>
    </CollpaseState>
  );
}


export default App;