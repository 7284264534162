import React from 'react'
import LoadingGif from '../images/loading-buffering.gif';
export default function Loading() {
    return (
        <div className="loading">
            <div className="loading-opa"></div>
            <div className="loading-content">
                
                <p className="fs-22 fw-500" >Ju lutem prisni </p> 
                <div className="loading-content-loading" >
                <img className="img-res" src={LoadingGif} alt="" />
                </div>
                
            </div>
        </div>
    )
}
