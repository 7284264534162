import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios';
import AlertContext from '../../../context/AlertContext';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Loading from '../../../utilities/Loading';

export default function FotoPacient({ pacientId, fazaId, closeFotoPacient }) {

    const alertContext = useContext(AlertContext);
    const [foto, setFoto] = useState("");
    const [fotoName, setFotoName] = useState("");
    const [galeria, setGlaeria] = useState([]);
    const [bigImage, setBigImage] = useState("")
    const [deletePop, setDeletePop] = useState(-1)
    const [edit, setEdit] = useState(-1)
    const [editText, setEditText] = useState("")
    const [deleteFoto, setDeleteFoto] = useState(-1)
    const [loading,setLoading] = useState(true)

    useEffect(() => {

        axios.post('https://panel.at-aligners.com/server/mjek/getFotoPacient', { faza_id: fazaId, pacient_id: pacientId }).then(res => {
            if(res.status === 200){
                setLoading(false)
            }
            setGlaeria(res.data)
            if (res.data.length !== 0) {
                setBigImage(res.data[0].foto)
            }

        })
    }, [])

    const addFoto = () => {

        const form = new FormData();
        form.append('foto', foto)
        form.append('name', fotoName)
        form.append('faza_id', fazaId)
        form.append('pacient_id', pacientId)
        setLoading(true)

        axios.post('https://panel.at-aligners.com/server/mjek/addFotoPacient', form).then(res => {
            if (res.data.status === 1) {
                setFotoName("")
                setFoto("")
                axios.post('https://panel.at-aligners.com/server/mjek/getFotoPacient', { faza_id: fazaId, pacient_id: pacientId }).then(res => {
                    setGlaeria(res.data)
                    setBigImage(res.data[0].foto)
                    
                })
                alertContext.setAlert(`${res.data.message}`, 'success')
                setLoading(false)
            }
        })
    }


    return (
        <>
        {loading && <Loading />}
        <div className="foto-pacient" >
            <div className="foto-pacient-opa" onClick={closeFotoPacient} ></div>
            <div className="foto-pacient-content">
                <div className="foto-pacient-content-top">
                    <div className="foto-pacient-content-top-left">
                        <p className="foto-pacient-content-top-left-title fs-16 fw-500" >Galeria e fotove</p>

                        <div className="foto-pacient-content-top-left-galeria">
                            <div className="foto-pacient-content-top-left-galeria-image-1">
                                <img className="img-res" src={`https://panel.at-aligners.com/server/files/${bigImage}`} alt="" />
                            </div>
                            <div className="foto-pacient-content-top-left-galeria-fotot">
                                {galeria.map((foto, index) => (
                                    <div className="foto-pacient-content-top-left-galeria-fotot-item" onClick={() => setBigImage(foto.foto)} >
                                        <img className="img-res" src={`https://panel.at-aligners.com/server/files/${foto.foto}`} alt="" />
                                        <div className="foto-pacient-content-top-left-galeria-fotot-delete" onClick={() => setDeleteFoto(index)} >
                                            <svg id="delete" xmlns="http://www.w3.org/2000/svg" width="13.577" height="17.452" viewBox="0 0 13.577 17.452">
                                                <path id="Path_807" data-name="Path 807" d="M126.518,1.053h2.917v.491h1.053V.985A.985.985,0,0,0,129.5,0h-3.055a.986.986,0,0,0-.984.985v.559h1.053Zm0,0" transform="translate(-121.188 0)" fill="#fff" />
                                                <path id="Path_808" data-name="Path 808" d="M50.843,167.75H40.954a.464.464,0,0,0-.462.5l.827,10.222a1.1,1.1,0,0,0,1.094,1.01h6.972a1.1,1.1,0,0,0,1.094-1.01l.827-10.222A.464.464,0,0,0,50.843,167.75Zm-7.494,10.644h-.033a.527.527,0,0,1-.525-.494l-.518-8.392a.527.527,0,0,1,1.051-.065l.518,8.392A.527.527,0,0,1,43.349,178.394Zm3.082-.526a.527.527,0,0,1-1.053,0v-8.392a.527.527,0,1,1,1.053,0Zm3.094-8.361L49.03,177.9a.527.527,0,0,1-.525.5h-.031a.527.527,0,0,1-.495-.557l.495-8.392a.527.527,0,0,1,1.052.062Zm0,0" transform="translate(-39.11 -162.032)" fill="#fff" />
                                                <path id="Path_809" data-name="Path 809" d="M13.554,77.706l-.346-1.037a.67.67,0,0,0-.635-.458H1a.67.67,0,0,0-.635.458L.023,77.706a.435.435,0,0,0,.412.572H13.142a.429.429,0,0,0,.23-.066.434.434,0,0,0,.182-.506Zm0,0" transform="translate(0 -73.614)" fill="#fff" />
                                            </svg>
                                        </div>
                                        {deleteFoto === index &&
                                            <ClickAwayListener onClickAway={() => setDeleteFoto(-1)} >
                                                <div className="fshi-modal-foto" >
                                                    <p className="fs-14 fw-normal" >Fshi pergjithmone</p>
                                                    <div className="fshi-modal-buttons">
                                                        <button
                                                            className="fshi-modal-buttons-fshi fs-14 fw-600"
                                                            onClick={() => {
                                                                axios.post('https://panel.at-aligners.com/server/mjek/deleteFotoPacient', { foto_id: foto.id }).then(res => {
                                                                    if (res.data.status === 1) {
                                                                        axios.post('https://panel.at-aligners.com/server/mjek/getFotoPacient', { faza_id: fazaId, pacient_id: pacientId }).then(res => {
                                                                            setGlaeria(res.data)
                                                                            if (res.data.length !== 0) {
                                                                                setBigImage(res.data[0].foto)
                                                                            } else {
                                                                                setBigImage("")
                                                                            }

                                                                        })
                                                                        alertContext.setAlert(`${res.data.message}`, 'success')
                                                                        setDeleteFoto(-1)
                                                                    }
                                                                })
                                                            }}
                                                        >Po</button>
                                                        <button
                                                            className="fshi-modal-buttons-anullo fs-14 fw-600"
                                                            onClick={() => setDeleteFoto(-1)} >Jo</button>
                                                    </div>
                                                </div>
                                            </ClickAwayListener>

                                        }
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                    <div className="foto-pacient-content-top-right">
                        <div className="foto-pacient-content-top-right-header">
                            <p className="fs-16 fw-500" >Shto foto duke klikuar butonin plus</p>
                            <label className="fs-22 fw-300" htmlFor="foto" >+</label>
                            <input type="file" id="foto" hidden onChange={(e) => {
                                setFoto(e.target.files[0])
                            }} />
                        </div>
                        <div className="foto-pacient-content-top-right-items">


                            {galeria.map((foto, index) => (
                                <div className="foto-pacient-content-top-right-items-item" key={foto.id}>

                                    {edit === index ? <input onChange={(e) => setEditText(e.target.value)} className="foto-pacient-content-top-right-items-item-edit-input" type="text" value={editText} /> : <p className="fs-14 fw-500" >{foto.foto_name}</p>}


                                    {
                                        edit === index ?
                                            <div className="foto-pacient-content-top-right-items-item-buttons" >
                                                <button className="foto-pacient-content-top-right-items-item-buttons-ruaj fs-14 fw-600"

                                                    onClick={() => {
                                                        axios.post("https://panel.at-aligners.com/server/mjek/updateFotoName", { foto_id: foto.id, foto_name: editText }).then(res => {

                                                            if (res.data.status === 1) {
                                                                axios.post('https://panel.at-aligners.com/server/mjek/getFotoPacient', { faza_id: fazaId, pacient_id: pacientId }).then(res => {
                                                                    setGlaeria(res.data)
                                                                    if (res.data.length !== 0) {
                                                                        setBigImage(res.data[0].foto)
                                                                    } else {
                                                                        setBigImage("")
                                                                    }

                                                                })
                                                                alertContext.setAlert(`${res.data.message}`, 'success')
                                                                setEdit(-1)
                                                                setEditText("")
                                                            } else {
                                                                alertContext.setAlert(`${res.data.message}`, 'error')
                                                            }

                                                        })
                                                    }}
                                                >Ruaj</button>
                                                <button
                                                    className="foto-pacient-content-top-right-items-item-buttons-anullo fs-14 fw-600"
                                                    onClick={() => setEdit(-1)}
                                                >Anullo</button>
                                            </div>
                                            :
                                            <div className="foto-pacient-content-top-right-items-item-icons">
                                                <div className="foto-pacient-content-top-right-items-item-icons-icon" onClick={() => {
                                                    setEdit(index)
                                                    setEditText(foto.foto_name)
                                                }}   >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="19.416" height="19.322" viewBox="0 0 19.416 19.322">
                                                        <g id="draw" transform="translate(-0.001 -1.289)">
                                                            <path id="Path_806" data-name="Path 806" d="M12.074,4.513l3.95,3.95-10,10-3.947-3.95Zm6.947-.953L17.26,1.8a1.748,1.748,0,0,0-2.469,0L13.1,3.487l3.95,3.95,1.968-1.968A1.347,1.347,0,0,0,19.021,3.561ZM.012,20.063a.449.449,0,0,0,.544.535l4.4-1.067-3.947-3.95Z" transform="translate(0)" fill="#9a9a9a" />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <div className="foto-pacient-content-top-right-items-item-icons-icon"
                                                    onClick={() => {

                                                        setDeletePop(index)
                                                    }}

                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.893" height="23" viewBox="0 0 17.893 23">
                                                        <g id="delete" transform="translate(0)">
                                                            <path id="Path_807" data-name="Path 807" d="M126.853,1.388H130.7v.647h1.388V1.3a1.3,1.3,0,0,0-1.3-1.3h-4.026a1.3,1.3,0,0,0-1.3,1.3v.737h1.388Zm0,0" transform="translate(-119.828 0)" fill="#9a9a9a" />
                                                            <path id="Path_808" data-name="Path 808" d="M54.134,167.75H41.1a.611.611,0,0,0-.609.661l1.09,13.472a1.447,1.447,0,0,0,1.442,1.331h9.188a1.447,1.447,0,0,0,1.442-1.331l1.09-13.472a.611.611,0,0,0-.609-.661Zm-9.877,14.027h-.044a.694.694,0,0,1-.692-.652l-.683-11.06a.694.694,0,0,1,1.386-.085l.683,11.06a.694.694,0,0,1-.65.736Zm4.062-.693a.694.694,0,0,1-1.388,0v-11.06a.694.694,0,1,1,1.388,0ZM52.4,170.065l-.652,11.06a.694.694,0,0,1-.692.653h-.041a.694.694,0,0,1-.652-.734l.652-11.06a.694.694,0,0,1,1.386.082Zm0,0" transform="translate(-38.671 -160.214)" fill="#9a9a9a" />
                                                            <path id="Path_809" data-name="Path 809" d="M17.863,78.181l-.456-1.367a.883.883,0,0,0-.837-.6H1.323a.883.883,0,0,0-.837.6L.03,78.181a.573.573,0,0,0,.543.754H17.32a.565.565,0,0,0,.3-.088.572.572,0,0,0,.24-.666Zm0,0" transform="translate(0 -72.788)" fill="#9a9a9a" />
                                                        </g>
                                                    </svg>
                                                </div>

                                            </div>

                                    }


                                    {deletePop === index &&
                                        <ClickAwayListener onClickAway={() => setDeletePop(-1)} >
                                            <div className="fshi-modal" >
                                                <p className="fs-14 fw-normal" >Fshi pergjithmone</p>
                                                <div className="fshi-modal-buttons">
                                                    <button
                                                        className="fshi-modal-buttons-fshi fs-14 fw-600"
                                                        onClick={() => {
                                                            axios.post('https://panel.at-aligners.com/server/mjek/deleteFotoPacient', { foto_id: foto.id }).then(res => {
                                                                if (res.data.status === 1) {
                                                                    axios.post('https://panel.at-aligners.com/server/mjek/getFotoPacient', { faza_id: fazaId, pacient_id: pacientId }).then(res => {
                                                                        setGlaeria(res.data)
                                                                        if (res.data.length !== 0) {
                                                                            setBigImage(res.data[0].foto)
                                                                        } else {
                                                                            setBigImage("")
                                                                        }

                                                                    })
                                                                    alertContext.setAlert(`${res.data.message}`, 'success')
                                                                    setDeletePop(-1)
                                                                }
                                                            })
                                                        }}
                                                    >Po</button>
                                                    <button
                                                        className="fshi-modal-buttons-anullo fs-14 fw-600"
                                                        onClick={() => setDeletePop(-1)} >Jo</button>
                                                </div>
                                            </div>
                                        </ClickAwayListener>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="foto-pacient-content-bottom">
                    {foto !== "" && <input onChange={(e) => setFotoName(e.target.value)} type="text" className="foto-pacient-content-bottom-input" placeholder="Emri i fotos" />}
                    <button className="foto-pacient-content-bottom-ngarko-btn fs-14 fw-500" onClick={addFoto} disabled={foto === "" ? true : false}  >Ngarko</button>
                    <button className="foto-pacient-content-bottom-anullo-btn fs-14 fw-500" onClick={closeFotoPacient} >Anullo</button>
                </div>
            </div>
        </div>
        </>
    )
}
