import React, { useRef, useEffect, useState , useContext } from 'react'
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useReactToPrint } from "react-to-print";
import Pagination from '@material-ui/lab/Pagination';
import Footer from '../../../utilities/footer';
import axios from 'axios'
import { Link } from 'react-router-dom'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Loading from '../../../utilities/Loading';
import AlertContext from '../../../context/AlertContext'

export default function Klinikat() {

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });
    const [klinikat, setKinikat] = useState([]);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [itemPage, setItempage] = useState(10);
    const start = (page - 1) * itemPage;
    const end = page * itemPage;
    const [loading, setLoading] = useState(true)
    const [activeIndex, setActiveIndex] = useState(-1)
    const [editPop, setEditPop] = useState(false)
    const [editContent, setEditContent] = useState({})
    const [editEmer,setEditEmer] = useState("")
    const [editMbiemer,setEditMbiemer] = useState("")
    const [editEmail,setEditEmail] = useState("")
    const [editTelefon,setEditTelefon] = useState("")
    const [editKlinika,setEditKlinika] = useState("")
    const [editAdresa,setEditAdresa] = useState("")
    const [editQyteti,setEditQyteti] = useState("")
    const [editNipt,setEditNipt] = useState("")
    const [editId,setEditId] = useState("")
    const [editPassword,setEditPassword] = useState("")

    useEffect(() => {

        axios.post('https://panel.at-aligners.com/server/lab/getClinics', { lab_id: localStorage.getItem('id') }).then(res => {
            if (res.status === 200) {
                setLoading(false)
            }
            setKinikat(res.data)
        })


    }, [])

    console.log(editContent)

    const handleChange = (event, value) => {
        setPage(value);
    };
    const alertContext = useContext(AlertContext)
    const filteredKlinikat = klinikat.filter(klinik =>
        klinik.id.toString().toLowerCase().includes(search.toLowerCase()) ||
        klinik.klinika.toString().toLowerCase().includes(search.toLowerCase()) ||
        klinik.telefon.toString().toLowerCase().includes(search.toLowerCase()) ||
        klinik.qyteti.toString().toLowerCase().includes(search.toLowerCase()) ||
        klinik.emer.toString().toLowerCase().includes(search.toLowerCase()) ||
        klinik.mbiemer.toString().toLowerCase().includes(search.toLowerCase())
    )

        const updateProfile = (e) => {
                
            e.preventDefault()
            setLoading(true)
            const payload = {

                emer : editEmer,
                mbiemer : editMbiemer,
                email : editEmail,
                telefon : editTelefon,
                klinika : editKlinika,
                adresa : editAdresa,
                nipt : editNipt,
                qyteti : editQyteti,
                user_id : editId
            }

            axios.post('https://panel.at-aligners.com/server/mjek/updateProfile',payload).then(res => {
                            if(res.data.status === 1){
                                setEditPop(false)
                                setEditContent({})
                                setEditEmer("")
                                setEditMbiemer("")
                                setEditEmail("")
                                setEditTelefon("")
                                setEditKlinika("")
                                setEditAdresa("")
                                setEditQyteti("")
                                setEditNipt("")
                                setEditId("")
                                alertContext.setAlert(`${res.data.message}`,'success')
                                axios.post('https://panel.at-aligners.com/server/lab/getClinics', { lab_id: localStorage.getItem('id') }).then(res => {
                                    if (res.status === 200) {
                                        setLoading(false)
                                    }
                                    setKinikat(res.data)
                                })
                            }else{
                                alertContext.setAlert(`${res.data.message}`,'error')
                            }       
            })
        }


        const onChnagePassword = (e) => {

            e.preventDefault()
            setLoading(true)

            axios.post('https://panel.at-aligners.com/server/user/changePassword', { password : editPassword , user_id : editId }).then(res => {
                if(res.data.status === 1){
                    setEditPassword("")
                    setLoading(false)
                    alertContext.setAlert(`${res.data.message}`,'success')
                    setEditPop(false)
                    setEditContent({})
                    setEditEmer("")
                    setEditMbiemer("")
                    setEditEmail("")
                    setEditTelefon("")
                    setEditKlinika("")
                    setEditAdresa("")
                    setEditQyteti("")
                    setEditNipt("")
                    setEditId("")
                }else{
                    alertContext.setAlert(`${res.data.message}`,'error')
                }
            })


        }

    return (
        <>
            {editPop &&
                <div className="edit-klinika-pop" >
                        <div className="edit-klinika-pop-opa"
                        onClick={() =>{
                            setEditContent({})
                            setEditPop(false)
                        }}
                        ></div>
                        <div className="edit-klinika-pop-content">
                                        <div className="edit-klinika-pop-content-left">
                                            <form action="#" className="edit-klinika-pop-content-left-form" onSubmit={updateProfile} >
                                                <div className="edit-klinika-pop-content-left-item">
                                                    <label className="edit-klinika-pop-content-left-item-label" htmlFor="#">Emer</label>
                                                    <input type="text"  className="edit-klinika-pop-content-left-item-input" value={editEmer} onChange={(e) => setEditEmer(e.target.value)}  />
                                                </div>
                                                <div className="edit-klinika-pop-content-left-item">
                                                    <label className="edit-klinika-pop-content-left-item-label" htmlFor="#">Mbiemer</label>
                                                    <input type="text"  className="edit-klinika-pop-content-left-item-input" value={editMbiemer} onChange={(e) => setEditMbiemer(e.target.value)} />
                                                </div>
                                                <div className="edit-klinika-pop-content-left-item">
                                                    <label className="edit-klinika-pop-content-left-item-label" htmlFor="#">Email</label>
                                                    <input type="text"  className="edit-klinika-pop-content-left-item-input"  value={editEmail} onChange={(e) => setEditEmail(e.target.value)} />
                                                </div>
                                                <div className="edit-klinika-pop-content-left-item">
                                                    <label className="edit-klinika-pop-content-left-item-label" htmlFor="#">Telefon</label>
                                                    <input type="text"  className="edit-klinika-pop-content-left-item-input"  value={editTelefon} onChange={(e) => setEditTelefon(e.target.value)} />
                                                </div>
                                                <div className="edit-klinika-pop-content-left-item">
                                                    <label className="edit-klinika-pop-content-left-item-label" htmlFor="#">Klinika</label>
                                                    <input type="text"  className="edit-klinika-pop-content-left-item-input" value={editKlinika}  onChange={(e) => setEditKlinika(e.target.value)} />
                                                </div>
                                                <div className="edit-klinika-pop-content-left-item">
                                                    <label className="edit-klinika-pop-content-left-item-label" htmlFor="#">Adresa</label>
                                                    <input type="text"  className="edit-klinika-pop-content-left-item-input" value={editAdresa} onChange={(e) => setEditAdresa(e.target.value)} />
                                                </div>
                                                <div className="edit-klinika-pop-content-left-item">
                                                    <label className="edit-klinika-pop-content-left-item-label" htmlFor="#">Qyteti</label>
                                                    <input type="text"  className="edit-klinika-pop-content-left-item-input" value={editQyteti} onChange={(e) => setEditQyteti(e.target.value)} />
                                                </div>
                                                <div className="edit-klinika-pop-content-left-item">
                                                    <label className="edit-klinika-pop-content-left-item-label" htmlFor="#">Nipt</label>
                                                    <input type="text"  className="edit-klinika-pop-content-left-item-input"  value={editNipt} onChange={(e) => setEditNipt(e.target.value)} />
                                                </div>

                                                <button className="edit-klinika-pop-content-left-form-button-submit" >Ruaj</button>
                                            </form>
                                        </div>
                                        <div className="edit-klinika-pop-content-right">
                                            <form action="#" className="edit-klinika-pop-content-right-form" onSubmit={onChnagePassword} >
                                            <label htmlFor="#" className="edit-klinika-pop-content-right-form-label" >Password</label>
                                            <input type="password" className="edit-klinika-pop-content-right-form-input" onChange={(e) => setEditPassword(e.target.value)} />
                                            <button className="edit-klinika-pop-content-right-form-submit-btn">Ndrysho password</button>
                                            </form>
                                        </div>
                        </div>
                </div>
            }


            {loading && <Loading />}
            <div className="lista-klinikave" >
                <div className="lista-klinikave-header">
                    <div className="lista-klinikave-header-left">
                        <p className="lista-klinikave-header-left-title fs-22 fw-bold">Lista e Klinikave ({klinikat.length})</p>
                    </div>
                    <div className="lista-klinikave-header-right">
                        <Input type="search"
                            placeholder="Search..."
                            onChange={(e) => {
                                setSearch(e.target.value)
                            }}
                            startAdornment={

                                <InputAdornment position="start">
                                    <svg id="Vector_Smart_Object" data-name="Vector Smart Object" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <path id="Path_33" data-name="Path 33" d="M12.864,11.321h-.813l-.288-.278a6.7,6.7,0,1,0-.72.72l.278.288v.813L16.467,18,18,16.467Zm-6.175,0A4.631,4.631,0,1,1,11.321,6.69,4.625,4.625,0,0,1,6.69,11.321Z" fill="#ccd4e0" />
                                    </svg>

                                </InputAdornment>
                            }
                            className="lista-klinikave-header-right-search"
                        />
                        <button className="lista-klinikave-header-right-print fs-16 fw-500" onClick={handlePrint}>
                            <svg id="printer_1_" data-name="printer (1)" xmlns="http://www.w3.org/2000/svg" width="18.438" height="16.71" viewBox="0 0 18.438 16.71">
                                <path id="Path_520" data-name="Path 520" d="M99.74,2.881H88.36a.36.36,0,0,1-.36-.36V1.585A1.585,1.585,0,0,1,89.585,0h8.931A1.585,1.585,0,0,1,100.1,1.585v.936A.36.36,0,0,1,99.74,2.881Z" transform="translate(-84.831 0)" fill="#fff" />
                                <path id="Path_521" data-name="Path 521" d="M16.494,112H1.945A1.947,1.947,0,0,0,0,113.945v6.77a1.947,1.947,0,0,0,1.945,1.945H3.169v-2.881a2.6,2.6,0,0,1,2.593-2.593h6.914a2.6,2.6,0,0,1,2.593,2.593v2.881h1.224a1.947,1.947,0,0,0,1.945-1.945v-6.77A1.947,1.947,0,0,0,16.494,112ZM3.458,116.322a.864.864,0,0,1,0-1.729h0a.864.864,0,0,1,0,1.729Z" transform="translate(0 -107.967)" fill="#fff" />
                                <path id="Path_522" data-name="Path 522" d="M143.779,304h-6.914a.864.864,0,0,0-.864.864V308.9a.864.864,0,0,0,.864.864h6.914a.864.864,0,0,0,.864-.864v-4.033A.864.864,0,0,0,143.779,304Z" transform="translate(-131.102 -293.052)" fill="#fff" />
                            </svg>
                            Print
                        </button>
                    </div>
                </div>
                <section className="lista-klinikave-datatable" ref={componentRef}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="fs-22" >ID</TableCell>
                                <TableCell>Klinika</TableCell>
                                <TableCell>Numri kontaktit</TableCell>
                                <TableCell>Qyteti</TableCell>
                                <TableCell>Dok.Pergjegjes</TableCell>
                                <TableCell>Veprime</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredKlinikat.slice(start, end).map((klinika, index) => (
                                <TableRow>
                                    <TableCell>#{klinika.user_id}</TableCell>
                                    <TableCell> <Link style={{ color: 'black', textDecoration: 'none' }} to={`/lab/klinika/${klinika.user_id}`} >{klinika.klinika}</Link></TableCell>
                                    <TableCell className="table-col" >
                                        <svg id="Group_1360" data-name="Group 1360" xmlns="http://www.w3.org/2000/svg" width="16.162" height="16.196" viewBox="0 0 16.162 16.196">
                                            <path id="Path_790" data-name="Path 790" d="M16.278,11.887l-2.26-2.26a1.5,1.5,0,0,0-2.5.565,1.538,1.538,0,0,1-1.776.969,6.97,6.97,0,0,1-4.2-4.2A1.463,1.463,0,0,1,6.51,5.187a1.5,1.5,0,0,0,.565-2.5L4.815.424a1.612,1.612,0,0,0-2.18,0L1.1,1.958c-1.534,1.614.161,5.893,3.955,9.687s8.072,5.57,9.687,3.955l1.534-1.534A1.612,1.612,0,0,0,16.278,11.887Z" transform="translate(-0.539 0)" fill="#2677f1" />
                                        </svg>
                                        {klinika.telefon}
                                    </TableCell>
                                    <TableCell className="table-col">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.697" height="16.826" viewBox="0 0 11.697 16.826">
                                            <path id="placeholder" d="M-.4,0A5.855,5.855,0,0,0-6.25,5.849C-6.25,9.9-.4,16.826-.4,16.826S5.447,9.7,5.447,5.849A5.855,5.855,0,0,0-.4,0ZM1.363,7.561A2.488,2.488,0,0,1-.4,8.291a2.488,2.488,0,0,1-1.765-.73,2.5,2.5,0,0,1,0-3.529A2.479,2.479,0,0,1-.4,3.3a2.48,2.48,0,0,1,1.765.731A2.5,2.5,0,0,1,1.363,7.561Zm0,0" transform="translate(6.25)" fill="#5fb597" />
                                        </svg>

                                        {klinika.qyteti}
                                    </TableCell>
                                    <TableCell> {klinika.emer} {klinika.mbiemer} </TableCell>
                                    <TableCell>
                                        <div style={{ position: 'relative' }} >
                                            <EditOutlinedIcon style={{ color: '#303030', cursor: 'pointer' }}

                                                onClick={() => {
                                                    setEditPop(true)
                                                    setEditContent(klinika)
                                                    setEditEmer(klinika.emer)
                                                    setEditMbiemer(klinika.mbiemer)
                                                    setEditEmail(klinika.email)
                                                    setEditTelefon(klinika.telefon)
                                                    setEditKlinika(klinika.klinika)
                                                    setEditAdresa(klinika.adresa)
                                                    setEditQyteti(klinika.qyteti)
                                                    setEditNipt(klinika.nipt)
                                                    setEditId(klinika.user_id)
                                                }}
                                            />
                                            <DeleteOutlineOutlinedIcon style={{ color: '#ff0000', cursor: 'pointer' }} onClick={() => setActiveIndex(index)} />
                                            {activeIndex === index &&
                                                <div className="delete-user-pop">
                                                    <p className="fs-14 fw-600" >Doni te fshni kliniken?</p>
                                                    <div className="delete-user-pop-buttons" >
                                                        <button className="delete-user-pop-buttons-po fs-12 fw-500" onClick={() => {
                                                            setLoading(true)
                                                            axios.post('https://panel.at-aligners.com/server/user/deleteUser', { user_id: klinika.user_id }).then(res => {
                                                                if (res.data.status === 1) {
                                                                    setActiveIndex(-1)
                                                                    axios.post('https://panel.at-aligners.com/server/lab/getClinics', { lab_id: localStorage.getItem('id') }).then(res => {
                                                                        if (res.status === 200) {
                                                                            setLoading(false)
                                                                        }
                                                                        setKinikat(res.data)

                                                                    })
                                                                }
                                                            })
                                                        }} >Po</button>
                                                        <button className="delete-user-pop-buttons-jo fs-12 fw-500" onClick={() => setActiveIndex(-1)} >Jo</button>
                                                    </div>
                                                </div>}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}


                        </TableBody>
                    </Table>
                </section>
                <div className="table-pagination">
                    <Pagination count={Math.ceil(filteredKlinikat.length / itemPage)} showFirstButton showLastButton onChange={handleChange} />
                </div>
                <Footer />
            </div>
        </>
    )
}
