import React, { useState } from 'react'
export default function NofullaPosht({ color }) {
    const [line1, setLine1] = useState({ show: false, color: color })
    const [line2, setLine2] = useState({ show: false, color: color })
    const [line3, setLine3] = useState({ show: false, color: color })
    const [line4, setLine4] = useState({ show: false, color: color })
    const [line5, setLine5] = useState({ show: false, color: color })
    const [line6, setLine6] = useState({ show: false, color: color })
    const [line7, setLine7] = useState({ show: false, color: color })
    const [line8, setLine8] = useState({ show: false, color: color })
    const [line9, setLine9] = useState({ show: false, color: color })
    const [line10, setLine10] = useState({ show: false, color: color })
    const [line11, setLine11] = useState({ show: false, color: color })
    const [line12, setLine12] = useState({ show: false, color: color })
    const [line13, setLine13] = useState({ show: false, color: color })
    const [line14, setLine14] = useState({ show: false, color: color })
    const [line15, setLine15] = useState({ show: false, color: color })
    return (
        <>
            <svg style={{ margin: ' 20px 0' }} className="tooth-lower" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 356.04 42">
                <defs>
                </defs>
                {line1.show && <line class="cls-3" x1="44.68" y1="39.81" x2="44.68" y2="2.19" stroke={line1.color} transform="translate(-15)" />}
                {line2.show && <line class="cls-3" x1="44.68" y1="39.81" x2="44.68" y2="2.19" stroke={line2.color} transform="translate(15)" />}
                {line3.show && <line class="cls-3" x1="44.68" y1="39.81" x2="44.68" y2="2.19" stroke={line3.color} transform="translate(44)" />}
                {line4.show && <line class="cls-3" x1="44.68" y1="39.81" x2="44.68" y2="2.19" stroke={line4.color} transform="translate(64)" />}
                {line5.show && <line class="cls-3" x1="44.68" y1="39.81" x2="44.68" y2="2.19" stroke={line5.color} transform="translate(84)" />}
                {line6.show && <line class="cls-3" x1="44.68" y1="39.81" x2="44.68" y2="2.19" stroke={line6.color} transform="translate(103)" />}
                {line7.show && <line class="cls-3" x1="44.68" y1="39.81" x2="44.68" y2="2.19" stroke={line7.color} transform="translate(118)" />}
                {line8.show && <line class="cls-3" x1="44.68" y1="39.81" x2="44.68" y2="2.19" stroke={line8.color} transform="translate(134)" />}
                {line9.show && <line class="cls-3" x1="44.68" y1="39.81" x2="44.68" y2="2.19" stroke={line9.color} transform="translate(149)" />}
                {line10.show && <line class="cls-3" x1="44.68" y1="39.81" x2="44.68" y2="2.19" stroke={line10.color} transform="translate(164)" />}
                {line11.show && <line class="cls-3" x1="44.68" y1="39.81" x2="44.68" y2="2.19" stroke={line11.color} transform="translate(183.5)" />}
                {line12.show && <line class="cls-3" x1="44.68" y1="39.81" x2="44.68" y2="2.19" stroke={line12.color} transform="translate(203)" />}
                {line13.show && <line class="cls-3" x1="44.68" y1="39.81" x2="44.68" y2="2.19" stroke={line13.color} transform="translate(223)" />}
                {line14.show && <line class="cls-3" x1="44.68" y1="39.81" x2="44.68" y2="2.19" stroke={line14.color} transform="translate(252)" />}
                {line15.show && <line class="cls-3" x1="44.68" y1="39.81" x2="44.68" y2="2.19" stroke={line15.color} transform="translate(282)" />}

                {/* Line 1 */}

                <path
                    onClick={() => {
                        setLine1({
                            show: !line1.show,
                            color
                        })
                    }}
                    class="st7" d="M12.4,10.8l-0.2,0.1L7.7,9.5l-1.7,1L1,14c0,0-1.5,2.9-0.7,5.4S1.6,27,6.5,29.8c2.3,1.3,5.6,1.6,8.3,1.5V11.5
	C13.6,11.2,12.4,10.8,12.4,10.8z"/>

                <path
                    onClick={() => {
                        setLine1({
                            show: !line1.show,
                            color
                        })
                    }}
                    class="st8" d="M21.9,10.1c0,0-3.4,0.7-4,1.5c-0.3,0.4-1.8,0.2-3.1-0.1v19.8c3-0.1,5.4-0.7,5.4-0.7s3.6-0.1,4.9-2.5
	c1.3-2.4,1.5-1.7,2.7-6c1.2-4.3,2.1-7,1.2-8.3S25.3,10.2,21.9,10.1z"/>

                <path
                    onClick={() => {
                        setLine1({
                            show: !line1.show,
                            color
                        })
                    }}

                    class="st7" d="M42.1,10.7c-1-1-1-0.6-1-0.6s-1.6-1.4-3.9-0.1s-6,2.8-6.4,3.8c-0.4,1-2.8,7.9,3.3,14.7c0,0,3.6,2.7,10.5,2.9
	v-20C43.5,11.4,42.5,11.1,42.1,10.7z"/>

                {/* End Line 1 */}

                {/* Line 2 */}
                <path
                    onClick={() => {
                        setLine2({
                            show: !line2.show,
                            color
                        })
                    }}
                    class="st8" d="M55.9,11.2c0,0-2.9-1.7-5.9-0.6c-1.7,0.6-3.8,0.9-5.4,0.8v20c1.9,0.1,4.1-0.1,6.6-0.5c0,0,6.3-1.8,7.6-10.4
	S55.9,11.2,55.9,11.2z"/>

                <path
                    onClick={() => {
                        setLine2({
                            show: !line2.show,
                            color
                        })
                    }}
                    class="st7" d="M72.2,10.3c-0.1-0.1-3-1.4-4.4-0.7c-1.4,0.7-1.4,0.6-1.4,0.6c-1.2,0.2-8.2,1.5-6.3,8.3
	c2,7.4,3.7,10.7,10.5,12.6c0,0,2.1,0,4.6-0.1V10.8C73.6,10.7,72.3,10.4,72.2,10.3z"/>


                {/* End Line 2 */}

                {/* Line 3 */}
                <path
                    onClick={() => {
                        setLine3({
                            show: !line3.show,
                            color
                        })
                    }}
                    class="st8" d="M84.3,10.5c0,0-4.2-0.8-6.4,0c-0.8,0.3-1.8,0.4-2.7,0.3V31c3.4-0.1,7.7-0.5,8.8-1.2c2-1.3,4.1-3.7,4.6-9.1
	C89.1,15.3,88.7,12.5,84.3,10.5z"/>

                <path
                    onClick={() => {
                        setLine3({
                            show: !line3.show,
                            color
                        })
                    }}
                    class="st7" d="M98.5,8.5l-2.3,0.1L90.9,12c0,0-2.1,2.3-1.7,6.5s1.5,11.8,3.9,13.7c1.2,0.9,3.5,1.2,5.7,1.3V8.7L98.5,8.5z" />

                {/* End Line 3 */}

                {/* Line 4 */}

                <path
                    onClick={() => {
                        setLine4({
                            show: !line4.show,
                            color
                        })
                    }}
                    class="st8" d="M104.7,12l-4.5-2.6l-1.4-0.7v24.8c2.3,0.1,4.4-0.2,4.4-0.2s2.5-1.5,3-3s2.5-8.8,2.5-8.8l-1.2-8.1L104.7,12z" />
                <path
                    onClick={() => {
                        setLine4({
                            show: !line4.show,
                            color
                        })
                    }}
                    class="st7" d="M113.5,9.6c-3.7,2.6-3.8,3-3.8,3s-2.3,4.1,0.1,12.3c2.4,8.2,2.9,7.3,3.7,7.8c0.6,0.3,2.4,0.7,4.4,0.8V8.5
	C116.7,8.3,115.1,8.5,113.5,9.6z"/>

                {/* End Line 4 */}

                {/* Line 5 */}
                <path
                    onClick={() => {
                        setLine5({
                            show: !line5.show,
                            color
                        })
                    }}
                    class="st8" d="M126.2,12.6c-1.1-0.4-4.9-1.9-5.3-2.5c-0.2-0.3-1.4-1.3-3-1.6v24.9c0.9,0,1.8,0,2.7-0.2
	c2.7-0.5,3.8-0.5,4.3-1.5s3.2-7.5,3.1-8.6c-0.1-1.1-0.4-8.6-0.4-8.6S127.3,13,126.2,12.6z"/>
                <path
                    onClick={() => {
                        setLine5({
                            show: !line5.show,
                            color
                        })
                    }}
                    class="st7" d="M136.5,7.6l-2.6,1l-5.1,4.2l-0.6,4.9c0,0-0.1,13.4,4.7,17.5c0,0,2.2,1.2,4.3,1.1c0.1,0,0.2,0,0.2,0V7.7
	L136.5,7.6z"/>

                {/* End Line 5 */}


                {/* Line 6 */}
                <path
                    onClick={() => {
                        setLine6({
                            show: !line6.show,
                            color
                        })
                    }}
                    class="st8" d="M144.7,11.4c-1.9-1.3-5.6-3.5-5.6-3.5l-1.7-0.2v28.6c1.9-0.1,2.9,0.1,2.9,0.1s4.3-0.9,5.9-8.1
	c1.6-7.2,1.5-12.2,1.1-13.4C146.9,13.7,146.6,12.7,144.7,11.4z"/>
                <path
                    onClick={() => {
                        setLine6({
                            show: !line6.show,
                            color
                        })
                    }}
                    class="st7" d="M154.4,7.4l-1.3-0.2c0,0-5.8-0.9-5.7,3.3c0.1,4.2,1.8,14.6,1.8,14.6s0.7,7.1,3.4,8.2c0.6,0.3,1.3,0.3,1.9,0.3
	L154.4,7.4C154.4,7.4,154.4,7.4,154.4,7.4z"/>

                {/* End Line 6 */}

                {/* Line 7 */}
                <path
                    onClick={() => {
                        setLine7({
                            show: !line7.show,
                            color
                        })
                    }}
                    class="st8" d="M160.1,7.8c-2.8-0.8-5.1-0.5-5.6-0.4v26.2c2.1-0.1,4-1.4,4-1.4s3.5-5.4,3.4-14.6
	C161.9,17.6,163.3,8.7,160.1,7.8z"/>
                <path
                    onClick={() => {
                        setLine7({
                            show: !line7.show,
                            color
                        })
                    }}
                    class="st7" d="M166.1,7.3c0,0-3.7-0.4-2.5,7.6c1.2,8,1.6,15.7,4.4,17.8c0.9,0.7,1.9,0.9,2.8,0.8V7.4L166.1,7.3z" />
                {/* End Line 7 */}

                {/* Line 8 */}
                <path
                    onClick={() => {
                        setLine8({
                            show: !line8.show,
                            color
                        })
                    }}
                    class="st8" d="M175.9,7.5l-5.1-0.1v26.1c1.9-0.1,3.6-1.3,3.6-1.3s2.7-4.3,2.9-7.5s1.2-11.2,1.2-11.2S178.9,8.6,175.9,7.5z" />
                <path
                    onClick={() => {
                        setLine8({
                            show: !line8.show,
                            color
                        })
                    }}
                    class="st7" d="M178.9,9.3c-0.7,2.9,0.4,10.2,0.4,10.2s0.1,10.5,3.6,13c1.1,0.8,2.2,1.1,3.1,1.1V7.2
	C185.2,7.1,179.6,6.6,178.9,9.3z"/>


                {/* End Line 8 */}

                {/* Line 9 */}
                <path
                    onClick={() => {
                        setLine9({
                            show: !line9.show,
                            color
                        })
                    }}
                    class="st8" d="M190.4,7.1c-2.8-0.3-4.3,0.1-4.3,0.1s0,0-0.1,0v26.4c2,0,3.3-1.2,3.3-1.2s2.3-3.3,3.2-11.1
	c0.9-7.8,1.1-11.4,1.1-11.4S193.2,7.4,190.4,7.1z"/>
                <path
                    onClick={() => {
                        setLine9({
                            show: !line9.show,
                            color
                        })
                    }}
                    class="st7" d="M199.5,7.2c0,0-4.6-0.3-5,2.2c-0.4,2.5-0.7,3.8-0.1,8c0.6,4.2,0.7,11.2,3.1,14.5c1,1.4,2.5,1.7,3.9,1.6V7.2
	C200.3,7.2,199.5,7.2,199.5,7.2z"/>
                {/* End Line 9 */}

                {/* Line 10 */}
                <path
                    onClick={() => {
                        setLine10({
                            show: !line10.show,
                            color
                        })
                    }}
                    class="st8" d="M209,9.9c-0.5-2.2-1.9-2.8-3.8-2.7c-1,0.1-2.6,0-3.8,0v26.3c1.7-0.2,3.2-1,3.2-1s2.3-3.2,3.1-9.4
	C208.5,16.9,209.5,12.1,209,9.9z"/>
                <path
                    onClick={() => {
                        setLine10({
                            show: !line10.show,
                            color
                        })
                    }}
                    class="st7" d="M214,10.1c0,0-4.9,2.2-5.1,6c-0.2,3.8-0.6,8.7,0.9,12.2s2.8,7.5,7.2,8.3c0.3,0.1,0.6,0.1,0.9,0.1V8.1
	C216.1,8.6,214,10.1,214,10.1z"/>
                {/* End Line 10 */}

                {/* Line 11 */}

                <path
                    onClick={() => {
                        setLine11({
                            show: !line11.show,
                            color
                        })
                    }}
                    class="st8" d="M228,15c-0.5-1.7-3-4-4.4-4.9c-1.4-0.9-3-2.3-4.8-2.2c-0.3,0-0.6,0.1-0.9,0.2v28.6c3.7,0.4,5.2-1.2,5.2-1.2
	s3.5-2.1,4.2-11S228.5,16.7,228,15z"/>
                <path
                    onClick={() => {
                        setLine11({
                            show: !line11.show,
                            color
                        })
                    }}
                    class="st7" d="M236.5,8.8c0,0-4,3.1-5.8,3.4s-1.8,2.1-2.1,4c-0.3,1.9-0.7,4.5-0.1,7.1c0.6,2.6,1.1,6.4,3,8.1
	c1.9,1.7,5.2,1.7,5.2,1.7l0.9-0.1V8.4C236.9,8.6,236.5,8.8,236.5,8.8z"/>

                {/* End Line 11 */}



                {/* Line 12 */}

                <path
                    onClick={() => {
                        setLine12({
                            show: !line12.show,
                            color
                        })
                    }}
                    class="st8" d="M243.8,10.1c0,0-2.5-2.2-4.4-2c-0.8,0.1-1.4,0.2-1.8,0.3V33l4.2-0.2c0,0,2.9-0.9,3.6-4.4s1.8-7.4,1.8-7.4
	s0.7-6.1,0.1-7.4C246.7,12.3,243.8,10.1,243.8,10.1z"/>
                <path
                    onClick={() => {
                        setLine12({
                            show: !line12.show,
                            color
                        })
                    }}
                    class="st7" d="M255.7,8.9c0,0-5,3-5.9,3.5c-0.9,0.5-1.8,2.7-1.8,4.5c0,1.8-0.5,6.7,1.1,10.6c1.6,3.9,2,5.5,6.7,5.6
	c0.6,0,1.1,0,1.6,0V8.3C256,8.6,255.7,8.9,255.7,8.9z"/>

                {/* End Line 12 */}

                {/* Line 13 */}
                <path
                    onClick={() => {
                        setLine13({
                            show: !line13.show,
                            color
                        })
                    }}
                    class="st8" d="M264.8,11.4c-2-1.5-3.8-3.4-6.2-3.2c-0.5,0-0.8,0.1-1.2,0.1v24.8c3.4,0,4.6-0.4,5.2-0.8
	c0.6-0.5,2.2-2.1,2.7-4.6s2.3-8.8,2-11.2S266.8,12.9,264.8,11.4z"/>

                <path
                    onClick={() => {
                        setLine13({
                            show: !line13.show,
                            color
                        })
                    }}
                    class="st7" d="M281.4,10.9c0,0-6.7-1.6-9.4-0.2c-2.7,1.4-4.3,4.3-4.1,7.2c0.2,2.9,0.8,8.2,2.7,10.5c1.8,2.2,6.8,2.8,11.2,2.8
	V10.9C281.6,10.9,281.5,10.9,281.4,10.9z"/>

                {/* End Line 13 */}

                {/* Line 14 */}


                <path
                    onClick={() => {
                        setLine14({
                            show: !line14.show,
                            color
                        })
                    }}
                    class="st8" d="M297,14.5c-0.2-0.5-1-2-4.4-3.3c-4.1-1.6-5.3-1.8-7-1.4c-1.5,0.3-2.9,1-3.8,1.1v20.3c0.3,0,0.6,0,0.8,0
	c4.5-0.1,8.6-0.5,11.3-5.3c2.1-3.7,2.8-8.3,3.1-10.3C297,15.3,297,15,297,14.5z"/>
                <path
                    onClick={() => {
                        setLine14({
                            show: !line14.show,
                            color
                        })
                    }}
                    class="st7" d="M308.9,11.9c-0.2-0.2-1.5-0.4-1.5-0.4s-1.9-2.2-6.1-0.9c-3.1,1-4,2.6-4.3,3.9c0,0.1,0.1,0.2,0.1,0.2
	s0,0.4-0.1,0.9c0,0,0,0,0,0.1c0.1,1.2,0.2,3.3,1.3,6.3s1.5,5.9,3.1,7c1.6,1.1,4.6,2,8.6,2.1c0.3,0,0.7,0,1,0V11.6
	C310.1,11.7,309,12,308.9,11.9z"/>

                {/* End Line 14 */}


                {/* Line 15 */}
                <path
                    onClick={() => {
                        setLine15({
                            show: !line15.show,
                            color
                        })
                    }}
                    class="st8" d="M320.6,10.5c-1.3-0.8-2.9-1.2-3.7-0.9c-0.8,0.3-4.2,2.2-5,2c-0.2-0.1-0.5,0-0.9,0v19.5c3.6,0.1,7.1,0,8.9-1.4
	c1.9-1.5,4.8-4.7,5.6-9c0.8-4.3,0.6-5.9,0.1-6.4S321.9,11.3,320.6,10.5z"/>

                <path
                    onClick={() => {
                        setLine15({
                            show: !line15.show,
                            color
                        })
                    }}
                    class="st7" d="M337.4,11.6c0,0-2.3-2-4.8-1.3s-6,1.7-5.9,4.9c0.1,3.2,2.5,11,2.5,11s1.1,3.6,6,4.5c1.7,0.3,3.7,0.4,5.5,0.4
	V11.9C339.7,12,338.1,12.1,337.4,11.6z"/>


                <path
                    onClick={() => {
                        setLine15({
                            show: !line15.show,
                            color
                        })
                    }}
                    class="st8" d="M354.8,13.8l-5.2-3.8L347,9.5l-5.4,2.2c0,0-0.4,0.1-0.9,0.2v19.2c3.3,0,6.1-0.3,6.1-0.3s5.7-1.3,7.8-8.3
	S354.8,13.8,354.8,13.8z"/>

                {/* End Line 15 */}

                <path class="st5" d="M29.6,17.1V17V17.1z" />
                <path class="st6" d="M29.6,17.1L29.6,17.1L29.6,17.1z" />
                <path class="st6" d="M29.6,17.1L29.6,17.1L29.6,17.1z" />
                <path class="st9" d="M96.2,8.6L90.9,12c0,0-2.1,2.3-1.7,6.5s1.5,11.8,3.9,13.7s10.1,1.1,10.1,1.1s2.5-1.5,3-3s2.5-8.8,2.5-8.8
	l-1.2-8.1l-2.8-1.4l-4.5-2.6l-1.7-0.9L96.2,8.6z"/>
                <path class="st9" d="M109.7,12.6c0,0-2.3,4.1,0.1,12.3s2.9,7.3,3.7,7.8s4.4,1.1,7.1,0.6s3.8-0.5,4.3-1.5s3.2-7.5,3.1-8.6
	s-0.4-8.6-0.4-8.6s-0.3-1.6-1.4-2s-4.9-1.9-5.3-2.5s-3.7-3.1-7.4-0.5S109.7,12.6,109.7,12.6z"/>
                <path class="st9" d="M133.9,8.6l-5.1,4.2l-0.6,4.9c0,0-0.1,13.4,4.7,17.5c0,0,2.2,1.2,4.3,1.1s3.1,0.1,3.1,0.1s4.3-0.9,5.9-8.1
	s1.5-12.2,1.1-13.4s-0.7-2.2-2.6-3.5s-5.6-3.5-5.6-3.5l-2.6-0.3L133.9,8.6z"/>
                <path class="st9" d="M153.1,7.2c0,0-5.8-0.9-5.7,3.3s1.8,14.6,1.8,14.6s0.7,7.1,3.4,8.2s5.9-1.1,5.9-1.1s3.5-5.4,3.4-14.6
	c0,0,1.4-8.9-1.8-9.8s-5.7-0.4-5.7-0.4L153.1,7.2z"/>
                <path class="st9" d="M175.9,7.5l-9.8-0.2c0,0-3.7-0.4-2.5,7.6s1.6,15.7,4.4,17.8s6.4-0.5,6.4-0.5s2.7-4.3,2.9-7.5s1.2-11.2,1.2-11.2
	S178.9,8.6,175.9,7.5z"/>
                <path class="st9" d="M186.1,7.2c0,0-6.5-0.8-7.2,2.1s0.4,10.2,0.4,10.2s0.1,10.5,3.6,13s6.4-0.1,6.4-0.1s2.3-3.3,3.2-11.1
	s1.1-11.4,1.1-11.4s-0.4-2.5-3.2-2.8S186.1,7.2,186.1,7.2z"/>
                <path class="st9" d="M199.5,7.2c0,0-4.6-0.3-5,2.2s-0.7,3.8-0.1,8s0.7,11.2,3.1,14.5s7.1,0.6,7.1,0.6s2.3-3.2,3.1-9.4
	s1.8-11,1.3-13.2s-1.9-2.8-3.8-2.7S199.5,7.2,199.5,7.2z"/>
                <path class="st9" d="M214,10.1c0,0-4.9,2.2-5.1,6s-0.6,8.7,0.9,12.2s2.8,7.5,7.2,8.3s6.1-1.1,6.1-1.1s3.5-2.1,4.2-11
	s1.2-7.8,0.7-9.5s-3-4-4.4-4.9s-3-2.3-4.8-2.2S214,10.1,214,10.1z"/>
                <path class="st9" d="M236.5,8.8c0,0-4,3.1-5.8,3.4s-1.8,2.1-2.1,4s-0.7,4.5-0.1,7.1s1.1,6.4,3,8.1s5.2,1.7,5.2,1.7l5.1-0.3
	c0,0,2.9-0.9,3.6-4.4s1.8-7.4,1.8-7.4s0.7-6.1,0.1-7.4s-3.5-3.5-3.5-3.5s-2.5-2.2-4.4-2S236.5,8.8,236.5,8.8z"/>
                <path class="st9" d="M255.7,8.9c0,0-5,3-5.9,3.5s-1.8,2.7-1.8,4.5s-0.5,6.7,1.1,10.6s2,5.5,6.7,5.6s6.2-0.3,6.8-0.8s2.2-2.1,2.7-4.6
	s2.3-8.8,2-11.2s-0.5-3.6-2.5-5.1S261,8,258.6,8.2S255.7,8.9,255.7,8.9z"/>
                <path class="st9" d="M281.4,10.9c0,0-6.7-1.6-9.4-0.2s-4.3,4.3-4.1,7.2s0.8,8.2,2.7,10.5s7.5,2.9,12,2.8s8.6-0.5,11.3-5.3
	s3.2-11.2,3.2-11.2s-0.4-1.9-4.5-3.5s-5.3-1.8-7-1.4S282.2,11,281.4,10.9z"/>
                <path class="st9" d="M307.4,11.5c0,0-1.9-2.2-6.1-0.9s-4.4,3.9-4.3,5.1s0.2,3.3,1.3,6.3s1.5,5.9,3.1,7s4.6,2,8.6,2.1s8,0.1,9.9-1.4
	s4.8-4.7,5.6-9s0.6-5.9,0.1-6.4s-3.7-3-5-3.8s-2.9-1.2-3.7-0.9s-4.2,2.2-5,2s-2.8,0.5-3,0.3S307.4,11.5,307.4,11.5z"/>
                <path class="st9" d="M337.4,11.6c0,0-2.3-2-4.8-1.3s-6,1.7-5.9,4.9s2.5,11,2.5,11s1.1,3.6,6,4.5s11.6,0.1,11.6,0.1s5.7-1.3,7.8-8.3
	s0.2-8.7,0.2-8.7l-5.2-3.8L347,9.5l-5.4,2.2C341.6,11.8,338.5,12.3,337.4,11.6z"/>
                <path class="st9" d="M66.4,10.2L66.4,10.2c-1.2,0.2-8.2,1.5-6.3,8.3c2,7.4,3.7,10.7,10.5,12.6c0,0,11.4,0,13.4-1.3s4.1-3.7,4.6-9.1
	s0.1-8.2-4.3-10.2c0,0-4.2-0.8-6.4,0s-5.6-0.1-5.7-0.2s-3-1.4-4.4-0.7S66.4,10.2,66.4,10.2z"/>
                <path class="st9" d="M41.1,10.1c0,0-1.6-1.4-3.9-0.1s-6,2.8-6.4,3.8s-2.8,7.9,3.3,14.7c0,0,5.9,4.4,17.1,2.4c0,0,6.3-1.8,7.6-10.4
	s-2.9-9.3-2.9-9.3S53,9.5,50,10.6s-6.9,1.1-7.9,0.1S41.1,10.1,41.1,10.1z"/>
                <path class="st9" d="M12.2,10.9L7.7,9.5l-1.7,1L1,14c0,0-1.5,2.9-0.7,5.4S1.6,27,6.5,29.8s13.7,0.8,13.7,0.8s3.6-0.1,4.9-2.5
	s1.5-1.7,2.7-6s2.1-7,1.2-8.3s-3.7-3.6-7.1-3.7c0,0-3.4,0.7-4,1.5s-5.5-0.8-5.5-0.8L12.2,10.9z"/>

            </svg>
        </>
    )
}
