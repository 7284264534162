import React, { useState, useEffect , useContext} from 'react'
import CollapseLogo from '../../../images/collapse.svg'
import Badge from '@material-ui/core/Badge';
import SupportiKlinik from '../../../utilities/SupportiKlinik';
import Notifications from '../../../utilities/Notifications';
import axios from 'axios'
import UserLogo from '../../../images/icon-login.png'
import CollapseContext from '../../../context/CollapseContex';

export default function Header({ logout }) {

    const collapseContex = useContext(CollapseContext)
    const [openSupport, setOpenSupport] = useState(false)
    const [notfications, openNotfications] = useState(false)
    const [njoftime, setNjfotime] = useState([])
    const [user,setUser] = useState({})

    useEffect(() => {

        axios.post('https://panel.at-aligners.com/server/user/getNotifications', { user_id: JSON.parse(localStorage.getItem("id")) }).then(res => {
            setNjfotime(res.data)
        })

        let token = JSON.parse(localStorage.getItem('token'));

        axios.post('https://panel.at-aligners.com/server/lab/getProfile', {token: token }).then(res => {
            setUser(res.data[0])

        })


        setInterval(() => {


            axios.post('https://panel.at-aligners.com/server/user/getNotifications', { user_id: JSON.parse(localStorage.getItem("id")) }).then(res => {
                setNjfotime(res.data)
            })

        }, 60000);

    }, [])

    const unwatchedIds = njoftime.filter(njof => njof.watched === 0).map(njof2 => njof2.id);

    return (
        <div className="dash-header" >
            <div className="dash-header-left">

                {openSupport && <SupportiKlinik closeSupport={() => setOpenSupport(false)} />}
                <button className="suport-klinik-btn" onClick={() => setOpenSupport(true)} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="21.651" height="21.631" viewBox="0 0 21.651 21.631">
                        <path id="Path_84" data-name="Path 84" d="M2494.072-1667.94v-3.894l.329-.041c.618-.073,1.236-.146,1.854-.215a.166.166,0,0,0,.161-.123c.159-.419.321-.837.492-1.251a3.862,3.862,0,0,1,.236-.452.114.114,0,0,0-.007-.143q-.631-.934-1.258-1.872c-.015-.022-.029-.045-.024-.052l2.806-2.853.774.563c.4.293.805.585,1.206.88a.1.1,0,0,0,.128.005,6.471,6.471,0,0,1,1.289-.624c.153-.05.312-.089.469-.127a.091.091,0,0,0,.083-.088q.19-1.156.387-2.311c0-.01,0-.021.009-.036h3.921c.016.14.033.281.049.423q.108.968.214,1.935a.076.076,0,0,0,.067.074,7.157,7.157,0,0,1,1.777.758.092.092,0,0,0,.12-.01q.95-.694,1.9-1.386l.081-.056,2.8,2.847.026-.015-.055.077q-.689.933-1.38,1.866a.125.125,0,0,0-.015.15c.212.446.422.893.626,1.344a2.779,2.779,0,0,1,.129.392.093.093,0,0,0,.084.079q.8.131,1.608.265l.761.128v3.894l-.481.1c-.5.1-.991.2-1.486.3a.114.114,0,0,0-.073.067,7.275,7.275,0,0,1-.771,1.771c-.029.05-.013.076.015.113l1.394,1.886.05.071-2.84,2.812-.857-.623q-.554-.4-1.107-.809a.1.1,0,0,0-.135-.006,6.311,6.311,0,0,1-1.4.658c-.116.035-.234.067-.353.092a.1.1,0,0,0-.089.092c-.128.779-.26,1.556-.393,2.347h-3.975c-.059,0-.088-.007-.1-.077-.122-.75-.25-1.5-.373-2.247a.155.155,0,0,0-.111-.136c-.518-.212-1.033-.428-1.549-.643a.115.115,0,0,1-.029-.014c-.158-.126-.289-.073-.442.032-.574.4-1.159.777-1.738,1.166a.086.086,0,0,1-.134-.012q-1.362-1.359-2.729-2.713c-.017-.017-.033-.035-.051-.054l.851-1.269c.153-.229.306-.459.462-.687a.1.1,0,0,0,0-.122,6.291,6.291,0,0,1-.642-1.315c-.047-.146-.085-.3-.122-.445a.077.077,0,0,0-.073-.069q-1.137-.186-2.273-.375Zm10.688-5.916c-.159.02-.458.036-.747.1a3.847,3.847,0,0,0-2.643,1.9,4.005,4.005,0,0,0-.467,2.772,3.815,3.815,0,0,0,1.915,2.756,4.077,4.077,0,0,0,3.027.438,3.829,3.829,0,0,0,2.675-2.064,4.04,4.04,0,0,0,.407-2.362,3.86,3.86,0,0,0-1.038-2.267A4.121,4.121,0,0,0,2504.76-1673.856Z" transform="translate(-2494.072 1680.571)" fill="#c9ced6" />
                    </svg>

                    Suporti per kliniken
                </button>
            </div>
            <div className="dash-header-right">

                <div className="dash-header-right-imageProfile">
                    <img src={user.user_image_profile === ""  ? UserLogo : `https://panel.at-aligners.com/server/files/${user.user_image_profile}`} className="img-res" alt="" />
                </div>


                <div className="dash-header-right-bell" onClick={() => {
                    openNotfications(!notfications)

                    if (njoftime.filter(njoftim => njoftim.watched === 0).length !== 0) {
                        axios.post('https://panel.at-aligners.com/server/user/watchNotification', { id: unwatchedIds }).then(res => {
                            axios.post('https://panel.at-aligners.com/server/user/getNotifications', { user_id: JSON.parse(localStorage.getItem("id")) }).then(res => {
                                setNjfotime(res.data)
                            })


                        })
                    }


                }} >
                    <Badge badgeContent={njoftime.filter(njoftim => njoftim.watched === 0).length === 0 ? 0 : njoftime.filter(njoftim => njoftim.watched === 0).length} color="error">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26.652" height="29.387" viewBox="0 0 26.652 29.387" className="bell-desktop" >
                            <g id="Group_1459" data-name="Group 1459" transform="translate(1 -1.739)">
                                <path id="Path_25" data-name="Path 25" d="M23.543,10.217a8.217,8.217,0,1,0-16.435,0C7.109,19.8,3,22.543,3,22.543H27.652S23.543,19.8,23.543,10.217Z" transform="translate(-3 0.739)" fill="none" stroke="#c9ced6" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                <path id="Path_26" data-name="Path 26" d="M15.009,21a2.74,2.74,0,0,1-4.739,0" transform="translate(-0.313 7.76)" fill="none" stroke="#c9ced6" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                            </g>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24.652" height="28.392" viewBox="0 0 24.652 28.392" className="bell-mobile">
                            <g id="Group_1172" data-name="Group 1172" transform="translate(0 -2.739)">
                                <g id="ICON_-_notifications" data-name="ICON - notifications" transform="translate(0 0)">
                                    <g id="Vector_Smart_Object" data-name="Vector Smart Object" transform="translate(0 0)">
                                        <path id="Path_25" data-name="Path 25" d="M23.543,10.217a8.217,8.217,0,1,0-16.435,0C7.109,19.8,3,22.543,3,22.543H27.652S23.543,19.8,23.543,10.217Z" transform="translate(-3 0.739)" fill="#fff" />
                                        <path id="Path_26" data-name="Path 26" d="M15.009,21a2.74,2.74,0,0,1-4.739,0" transform="translate(-0.313 7.761)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                                    </g>
                                </g>
                            </g>
                        </svg>



                    </Badge>
                    {notfications && <Notifications closeNot={() => openNotfications(false)} />}

                </div>

                <div className="dash-header-right-logout" onClick={logout} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23.913" viewBox="0 0 23 23.913">
                        <g id="Group_2" data-name="Group 2" transform="translate(1 1)">
                            <path id="Path_80" data-name="Path 80" d="M8,22.913H3.334a2.284,2.284,0,0,1-1.65-.714A2.484,2.484,0,0,1,1,20.478V3.435a2.484,2.484,0,0,1,.683-1.722A2.284,2.284,0,0,1,3.334,1H8" transform="translate(-1 -1)" fill="none" stroke="#c9ced6" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                            <path id="Path_81" data-name="Path 81" d="M12.074,16.729l5.833-6.086L12.074,4.556" transform="translate(3.093 0.314)" fill="none" stroke="#c9ced6" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                            <path id="Path_82" data-name="Path 82" d="M20.111,9h-14" transform="translate(0.889 1.957)" fill="none" stroke="#c9ced6" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                        </g>
                    </svg>
                </div>
                <div className="dash-header-right-collapse" onClick={() => collapseContex.setTrue()} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="26" viewBox="0 0 28 26">
                        <g id="Group_1665" data-name="Group 1665" transform="translate(-527 -48)">
                            <rect id="Rectangle_530" data-name="Rectangle 530" width="13" height="4" rx="2" transform="translate(527 48)" fill="#fff" />
                            <rect id="Rectangle_534" data-name="Rectangle 534" width="21" height="4" rx="2" transform="translate(527 70)" fill="#fff" />
                            <rect id="Rectangle_533" data-name="Rectangle 533" width="28" height="4" rx="2" transform="translate(527 59)" fill="#fff" />
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    )
}
