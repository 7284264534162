import React, {useState,useContext} from 'react'
import Footer from '../../../utilities/footer';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import Alert from '../../../utilities/Alerts';
import AlertContext  from '../../../context/AlertContext';

export default function ShtoKlinike() {

    const [username,setUsername] = useState("")
    const [emer,setEmer] = useState("")
    const [mbiemer,setMbiemer] = useState("")
    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")
    const [telefon,setTelefon] = useState("")
    const [klinika,setKlinika] = useState("")
    const [adresa,setAdresa] = useState("")
    const [qyteti,setQyteti] = useState("")
    const [nipt,setNipt] = useState("")
    const alertContext = useContext(AlertContext)


    const onSubmit = (e) => {
            e.preventDefault();
            axios.post('https://panel.at-aligners.com/server/user/addMjek',{
                username : username,
                password : password,
                image_profile : "",
                emer : emer,
                mbiemer : mbiemer,
                email : email,
                telefon : telefon,
                klinika : klinika,
                adresa : adresa,
                qyteti : qyteti,
                nipt : nipt,
                lab_id: JSON.parse(localStorage.getItem('id'))
            }).then(res => {
                if(res.data.status === 0){
                    alertContext.setAlert(`${res.data.message}`, "error");
                }else{
                    alertContext.setAlert(`${res.data.message}`, "success");
                    setUsername("")
                    setEmer("")
                    setMbiemer("")
                    setEmail("")
                    setPassword("")
                    setTelefon("")
                    setKlinika("")
                    setAdresa("")
                    setQyteti("")
                    setNipt("")

                }
            })
    }

    return (
        <>
        <Alert />
        <div className="shtoklinike" >
            <div className="shtoklinike-header">
                <p className="shtoklinike-header-title fs-22 fw-bold">Shto Klinike</p>
            </div>
            <section className="shtoklinike-content">
                <form className="shtoklinike-content-form" onSubmit={onSubmit}>
                    <div className="shtoklinike-content-form-item">
                        <label for="username" className="shtoklinike-content-form-item-label">Username</label>
                        <TextField variant="outlined" id="username" onChange={(e) =>setUsername(e.target.value)} value={username} placeholder="admin..." />
                    </div>


                    <div className="shtoklinike-content-form-item">
                        <label for="emri" className="shtoklinike-content-form-item-label">Emer</label>
                        <TextField variant="outlined" id="emri" onChange={(e) =>setEmer(e.target.value)} value={emer}  placeholder="Emri" />
                    </div>

                    <div className="shtoklinike-content-form-item">
                        <label for="mbiemri" className="shtoklinike-content-form-item-label">Mbiemer</label>
                        <TextField variant="outlined" id="mbiemri" onChange={(e) =>setMbiemer(e.target.value)} value={mbiemer} placeholder="Mbiemri" />
                    </div>

                    <div className="shtoklinike-content-form-item">
                        <label className="shtoklinike-content-form-item-label" for="email">Email</label>
                        <TextField variant="outlined" id="email" onChange={(e) =>setEmail(e.target.value)} value={email} placeholder="prove@gmail.com" />
                    </div>
                    <div className="shtoklinike-content-form-item">
                        <label className="shtoklinike-content-form-item-label" for="email">Password</label>
                        <TextField variant="outlined" id="email" onChange={(e) =>setPassword(e.target.value)} value={password} placeholder="*******" />
                    </div>

                    <div className="shtoklinike-content-form-item">
                        <label for="cel" className="shtoklinike-content-form-item-label">Telefon</label> 
                        <TextField variant="outlined" id="cel" onChange={(e) =>setTelefon(e.target.value)} value={telefon} placeholder="068 9666 3332" />
                    </div>

                    <div className="shtoklinike-content-form-item">
                        <label for="klinik" className="shtoklinike-content-form-item-label">Klinika</label>
                        <TextField variant="outlined" id="klinik" onChange={(e) =>setKlinika(e.target.value)} value={klinika} placeholder="Dental X" />
                    </div>

                    <div className="shtoklinike-content-form-item">
                        <label for="adresa" className="shtoklinike-content-form-item-label">Adresa</label>
                        <TextField variant="outlined" id="adresa" onChange={(e) =>setAdresa(e.target.value)} value={adresa} placeholder="Rruga ...." />
                    </div>

                    <div className="shtoklinike-content-form-item">
                        <label for="qytet" className="shtoklinike-content-form-item-label">Qyteti</label>
                        <TextField variant="outlined" id="qytet" onChange={(e) =>setQyteti(e.target.value)} value={qyteti} placeholder="Tirane" />
                    </div>

                    <div className="shtoklinike-content-form-item">
                        <label for="nipt" className="shtoklinike-content-form-item-label">NIPT</label>
                        <TextField variant="outlined" id="nipt" onChange={(e) =>setNipt(e.target.value)} value={nipt} placeholder="xxx - xxx - xxx" />
                    </div>

                    <div className="shtoklinike-content-form-sub">
                        <button className="shtoklinike-content-form-sub-btn fs-14 fw-500">
                            Ruaj
                        </button>
                    </div>

                </form>
            </section>

            <Footer />
        </div>
        </>
    )
}
