import React, { useState, useEffect, useContext } from 'react'
import Footer from '../../../utilities/footer';
import axios from 'axios';
import FormaLab from './FormaLab';
import Alerts from '../../../utilities/Alerts';
import Link3d from './Link3d';
import AlertContext from '../../../context/AlertContext';
import FotoPacient from './FotoPacient';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Loading from '../../../utilities/Loading';
import {Link} from 'react-router-dom';

export default function SinglePacient({ match }) {

    const alertContext = useContext(AlertContext);
    const [pacient, setPacient] = useState({});
    const [fazat, setFazat] = useState([]);
    const [activeFaza, setActiveFaza] = useState(0);
    const [fazaContent, setFazaContent] = useState({})
    const [openTrajtimi, setOpenTrajtimi] = useState(false)
    const [openLink, setOpenLink] = useState(false);
    const [notes, setNotes] = useState("");
    const [messages, setMessages] = useState([])
    const [propertyName, setProperty] = useState({
        key: 'data',
        direction: 'descending'
    });
    const [openFotoPacient, setOpenFotoPacient] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0)
    const [extraFaza, openExtraFaza] = useState(false)
    const [loading,setLoading] = useState(true)

    console.log(pacient)

    if (propertyName !== null) {
        messages.sort((a, b) => {
            if (a[propertyName.key] < b[propertyName.key]) {
                return propertyName.direction === 'descending' ? -1 : 1;
            }
            if (a[propertyName.key] > b[propertyName.key]) {
                return propertyName.direction === 'descending' ? 1 : -1;
            }
            return 0;
        });
    }
    const textRender = (status) => {

        if (status === 1) {
            return 'Ne pritje'
        } else if (status === 2) {
            return '3D Laborator'
        } else if (status === 3) {
            return 'Derguar'
        } else {
            return "Mbyllur"
        }
    }

    const colorRender = (status) => {
        if (status === 1) {
            return '#2677F1'
        } else if (status === 2) {
            return '#5FB597'
        } else if (status === 3) {
            return '#73C850'
        } else {
            return "#FF0000"
        }
    }


    const mjektextRender = (status) => {

        if (status === 0) {
            return 'Ne pritje'
        } else if (status === 1) {
            return 'Pranuar'
        } else if (status === 2) {
            return 'Rishikim'
        } else {
            return "Mbyllur"
        }

    }

    const mjekcolorRender = (status) => {
        if (status === 0) {
            return '#2677F1'
        } else if (status === 1) {
            return '#5FB597'
        } else if (status === 2) {
            return '#73C850'
        } else {
            return "#FF0000"
        }
    }

    let rishikimLength = fazat.filter(faza => faza.type === 2).length;
    let fazaLength = fazat.filter(faza => faza.type === 1).length;

    useEffect(() => {
        axios.post('https://panel.at-aligners.com/server/lab/getSinglePacient', { pacient_id: match.params.id }).then(res => {
     
            setPacient(res.data[0])
        })
        axios.post('https://panel.at-aligners.com/server/lab/getFazatSinglePacient', { pacient_id: match.params.id }).then(res => {
            if(res.status === 200){
                setLoading(false)
            }
            setFazat(res.data)
            setActiveFaza(res.data[0].faza_id)
            setFazaContent(res.data[0])
        })

    }, [match.params.id])



    useEffect(() => {

        axios.post('https://panel.at-aligners.com/server/lab/getNotes', { pacient_id: parseInt(match.params.id), faza_id: activeFaza }).then(res => {
            setMessages(res.data)
        })

    }, [activeFaza, match.params.id])



    const closeLink = () => {
        setOpenLink(false)
    }

    const closeTrajtimi = () => {
        setOpenTrajtimi(false)
    }

    const getPacient = () => {
        axios.post('https://panel.at-aligners.com/server/lab/getFazatSinglePacient', { pacient_id: match.params.id }).then(res => {
            if(res.status === 200){
                setLoading(false)
            }
            setFazat(res.data)
            setActiveFaza(res.data[activeIndex].faza_id)
            setFazaContent(res.data[activeIndex])
        })
    }


    const sendNotes = () => {

        const payload = {

            pacient_id: match.params.id,
            faza_id: activeFaza,
            note_from: localStorage.getItem('id'),
            text: notes

        }


        axios.post('https://panel.at-aligners.com/server/lab/addNotes', payload).then(res => {
            if (res.data.status === 1) {
                setNotes('')
                alertContext.setAlert(`${res.data.message}`, 'success')
                axios.post('https://panel.at-aligners.com/server/lab/getNotes', { pacient_id: match.params.id, faza_id: activeFaza }).then(res => {
                    setMessages(res.data)
                })
                axios.post('https://panel.at-aligners.com/server/user/addNotification', 
                {
                    to_user : pacient.mjek_id , 
                    message : `Laboratori ju dergoi mesazh (${pacient.emri} ${pacient.mbiemri} ${fazaContent.faza_name}) ` , 
                    link : `/mjek/pacientet/${match.params.id}` , 
                    type : 3 
                })
            } else {
                alertContext.setAlert(`${res.data.message}`, 'error')
            }
        })

    }

    return (
        <>
            <Alerts />
            {loading && <Loading /> }
            {openTrajtimi && <FormaLab openLoading={() => setLoading(true)} closeLoading={() => setLoading(false)} emriPac={pacient.emri} mbiemriPac={pacient.mbiemri} fazaId={activeFaza} pacientId={pacient.id} closeTrajtimi={closeTrajtimi} getPacient={getPacient} />}
            {openFotoPacient && <FotoPacient fazaId={activeFaza} pacientId={match.params.id} closeFotoPacient={() => setOpenFotoPacient(false)} />}
            <div className="single-user" >
                <header className="single-user-header" >
                    <div className="single-user-header-left">
                        <p className="single-user-header-left-title fs-22 fw-bold">Trajtimi i pacientit</p>
                        <p className="single-user-header-left-subtitle fs-16 fw-normal">{pacient.emri} {pacient.mbiemri} / <Link style={{color:'inherit',textDecoration:'none'}} to={`/lab/klinika/${pacient.mjek_id}`} >{pacient.mjek_klinika} </Link> </p>
                    </div>
                    <div className="single-user-header-right">
                        <div className="single-user-header-right-content" >
                            {fazaContent.derguar_plan === 1 && <p className="single-user-header-right-mjek-status fs-16 fw-normal" >Miratimi i mjekut: <span style={{ color: mjekcolorRender(fazaContent.status_mjek) }} >{mjektextRender(fazaContent.status_mjek)}</span> </p>}

                            <p className="single-user-header-right-status fs-16 fw-normal">
                                Status Pacient: <span style={{ color: colorRender(pacient.status) }} >{textRender(pacient.status)}</span>
                            </p>

                            <p className="single-user-header-right-shipment fs-16 fw-normal">
                                Shipment : <span>{pacient.dergesa}</span>
                            </p>
                        </div>
                    </div>
                </header>

                <div className="single-user-body">
                    <div className="single-user-body-header">
                        <div className="single-user-body-header-left">
                            {fazat.map((faza, index) => (
                                <div key={faza.faza_id} onClick={() => {
                                    setActiveFaza(faza.faza_id)
                                    setFazaContent(faza)
                                    setActiveIndex(index)

                                }} className={activeFaza === faza.faza_id ? "single-user-body-header-left-faza active-faza" : "single-user-body-header-left-faza"} >
                                    <span className="fs-22 fw-bold" >{faza.faza_name}</span>
                                </div>
                            ))}

                        </div>
                        <div className="single-user-body-header-right">
                            <div className="single-user-body-header-right-add-btn" onClick={() => openExtraFaza(!extraFaza)} >
                                <p className="fs-28 fw-300" >+</p>
                                {extraFaza &&

                                    <ClickAwayListener onClickAway={() => openExtraFaza(false)}>
                                        <div className="extra-faza">
                                            <p className="fs-14 fw-600" >Deshironi te shtoni faze te re?</p>
                                            <div className="extra-faza-buttons">
                                                <button className="extra-faza-buttons-shto-btn"
                                                    onClick={() => {
                                                        axios.post('https://panel.at-aligners.com/server/lab/addExtraFaza', { pacient_id: match.params.id, faza_name: `Faza ${fazaLength + 1}  ` }).then(res => {
                                                            if (res.data.status === 1) {
                                                                alertContext.setAlert(`${res.data.message}`, 'success');
                                                                axios.post('https://panel.at-aligners.com/server/lab/getFazatSinglePacient', { pacient_id: match.params.id }).then(res => {
                                                                    setFazat(res.data)

                                                                })
                                                                axios.post('https://panel.at-aligners.com/server/user/addNotification', 
                                                                {
                                                                    to_user : pacient.mjek_id , 
                                                                    message : `Laboratori krijoi faze te per (${pacient.emri} ${pacient.mbiemri}) ` , 
                                                                    link : `/mjek/pacientet/${match.params.id}` , 
                                                                    type : 3 
                                                                })
                                                            }
                                                        })
                                                    }}
                                                >
                                                    Po
                                                </button>
                                                <button className="extra-faza-buttons-anullo-btn" onClick={() => openExtraFaza(false)} > Jo</button>
                                            </div>
                                        </div>
                                    </ClickAwayListener>
                                }
                            </div>

                            {pacient.kerkes_rishikim === 1 ?
                                <div className="rishikim-buttons">
                                    <button className="rishikim-buttons-prano fs-16 fw-500 " 
                                    onClick={() => {
                                        axios.post('https://panel.at-aligners.com/server/lab/createRishikim', 
                                        { pacient_id : match.params.id , 
                                            faza_name : `Rishikim ${fazat.filter(faza => faza.type === 2).length + 1}`,
                                            rishikim_status : 1
                                        } ).then(res => {
                                            if(res.data.status === 1){
                                                alertContext.setAlert(`${res.data.message}`,'success')
                                                axios.post('https://panel.at-aligners.com/server/lab/getFazatSinglePacient', { pacient_id: match.params.id }).then(res => {
                                                    setFazat(res.data)

                                                })
                                                axios.post('https://panel.at-aligners.com/server/lab/getSinglePacient', { pacient_id: match.params.id }).then(res => {
                                                    setPacient(res.data[0])
                                                })
                                                axios.post('https://panel.at-aligners.com/server/user/addNotification', 
                                                {
                                                    to_user : pacient.mjek_id , 
                                                    message : `Laboratori pranoi rishikim per (${pacient.emri} ${pacient.mbiemri}) ` , 
                                                    link : `/mjek/pacientet/${match.params.id}` , 
                                                    type : 1 
                                                })

                                            }
                                        })
                                    }}
                                    >Prano rishikim</button>
                                    <button className="rishikim-buttons-anullo fs-16 fw-500"
                                    onClick={() => {
                                        axios.post('https://panel.at-aligners.com/server/lab/createRishikim', 
                                        { pacient_id : match.params.id , 
                                            faza_name : `Rishikim ${rishikimLength + 1}`,
                                            rishikim_status : 0
                                        } ).then(res => {
                                            if(res.data.status === 1){
                                                alertContext.setAlert(`${res.data.message}`,'error')
                                                axios.post('https://panel.at-aligners.com/server/lab/getFazatSinglePacient', { pacient_id: match.params.id }).then(res => {
                                                    setFazat(res.data)

                                                })
                                                axios.post('https://panel.at-aligners.com/server/lab/getSinglePacient', { pacient_id: match.params.id }).then(res => {
                                                    setPacient(res.data[0])
                                                })
                                                axios.post('https://panel.at-aligners.com/server/user/addNotification', 
                                                {
                                                    to_user : pacient.mjek_id , 
                                                    message : `Laboratori nuk pranoi rishikim per (${pacient.emri} ${pacient.mbiemri}) ` , 
                                                    link : `/mjek/pacientet/${match.params.id}` , 
                                                    type : 2 
                                                })

                                            }
                                        })
                                    }}
                                    
                                    >Anullo rishikim</button>
                                </div> :
                                <button className="single-user-body-header-right-rishikim fs-22 fw-bold">
                                    Nuk ka kerkes rishikimi
                                </button>

                            }


                        </div>
                    </div>

                    <div className="single-user-body-content">


                        <div className="single-user-body-content-left">
                            <p className="single-user-body-content-left-title fs-20 fw-500">Plani i trajtimit ({fazaContent.faza_name})</p>

                            {fazaContent.plani_trajtimit === "" ?
                                <button className="fs-14 fw-normal" onClick={() => setOpenTrajtimi(true)} >Krijo planin e trajtimit</button>
                                : <a className="fs-14 fw-normal" href={`https://panel.at-aligners.com/server/files/${fazaContent.plani_trajtimit}`} rel="noreferrer" target="_blank" download >
                                    Shiko Planin e Trajtimit
                                </a>}


                            <button className="fs-14 fw-normal" onClick={() => setOpenLink(true)} >Vendos Pamjen 3D {openLink && <Link3d refreshData={getPacient} fazaId={activeFaza} closeLink={closeLink} pamje_link={fazaContent.pamje_3d} />} </button>

                            {fazaContent.derguar_plan === 0 &&
                                <button
                                    className="single-user-body-content-left-submit-btn fs-14 fw-normal"
                                    disabled={fazaContent.plani_trajtimit === "" || fazaContent.pamje_3d === "" ? true : false}
                                    onClick={() => {
                                        axios.post('https://panel.at-aligners.com/server/lab/dergoPlan', { faza_id: activeFaza }).then(res => {
                                            if (res.data.status === 1) {
                                                alertContext.setAlert(`${res.data.message}`, 'success')
                                                axios.post('https://panel.at-aligners.com/server/lab/getFazatSinglePacient', { pacient_id: match.params.id }).then(res => {
                                                    setFazat(res.data)
                                                    setActiveFaza(res.data[activeIndex].faza_id)
                                                    setFazaContent(res.data[activeIndex])
                                                })
                                                axios.post('https://panel.at-aligners.com/server/user/addNotification', 
                                                {
                                                    to_user : pacient.mjek_id , 
                                                    message : `Laboratori dergoi planin e trajtimit (${pacient.emri} ${pacient.mbiemri} ${fazaContent.faza_name}) ` , 
                                                    link : `/mjek/pacientet/${match.params.id}` , type : 1 
                                                })
                                            }
                                        })
                                    }}
                                >
                                    Dergo
                                </button>
                            }


                        </div>
                        <div className="single-user-body-content-right">
                            <div className="single-user-body-content-right-top">
                                <p className="single-user-body-content-right-top-title fs-20 fw-500">Kerkesa e trajtimit te planit</p>

                                {fazaContent.forma_doktorit === "" ? <button className="single-user-body-content-right-top-button fs-14 fw-normal" disabled >Shiko formen</button>
                                    : <a download rel="noreferrer" target="_blank" href={`https://panel.at-aligners.com/server/files/${fazaContent.forma_doktorit}`} className="single-user-body-content-right-top-button fs-14 fw-normal">Shiko formen</a>}


                                <button className="single-user-body-content-right-top-button fs-14 fw-normal" onClick={() => setOpenFotoPacient(true)} >Shiko fotot</button>
                            </div>
                            <div className="single-user-body-content-right-bottom">
                                <p className="single-user-body-content-right-bottom-title fs-16 fw-bold">
                                    Notes
                                </p>
                                <div className="single-user-body-content-right-bottom-notes">
                                    {messages.map(message => (

                                        <p className="single-user-body-content-right-bottom-notes-message fs-14 fw-600" >
                                            <span>
                                                {message.lab_emer === null ? message.mjek_klinika : message.lab_emer} ({message.data})
                                            </span> &nbsp;

                                            {message.text}
                                        </p>

                                    ))}

                                </div>


                                <div className="single-user-body-content-right-bottom-notes-form">
                                    <input className="single-user-body-content-right-bottom-notes-form-input" onChange={(e) => setNotes(e.target.value)} value={notes} type="text" name="" id="" />
                                    <button className="single-user-body-content-right-bottom-notes-form-btn fs-12 fw-normal" onClick={sendNotes} >Dergo</button>
                                </div>


                            </div>
                        </div>

                    </div>

                </div>
                <Footer />

            </div>
        </>
    )
}
