import React, { useRef, useEffect, useState, useContext } from 'react'
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useReactToPrint } from "react-to-print";
import Pagination from '@material-ui/lab/Pagination';
import Footer from '../../../utilities/footer';
import axios from 'axios'
import { Link } from 'react-router-dom'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import AlertContext from '../../../context/AlertContext';
import DatePicker from 'react-date-picker';
import Loading from '../../../utilities/Loading';

export default function Pacientet() {

    const alertContext = useContext(AlertContext)
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });
    const [pacientet, setPacientet] = useState([]);
    const [statusDropdown, setStatusDropDown] = useState(-1)
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [itemPage, setItempage] = useState(10);
    const start = (page - 1) * itemPage;
    const end = page * itemPage;
    const [activeIndex, setActiveIindex] = useState(-1)
    const [loading,setLoading] = useState(true)

    console.log(pacientet)

    useEffect(() => {

        axios.post('https://panel.at-aligners.com/server/mjek/getPacients', { mjek_id: JSON.parse(localStorage.getItem('id')) }).then(res => {
            if(res.status === 200){
                setLoading(false)
            }
            setPacientet(res.data)
        })

    }, [])

    const handleChange = (event, value) => {
        setPage(value);
    };

    const textRender = (status) => {

        if (status === "1") {
            return 'Ne pritje'
        } else if (status === "2") {
            return '3D Laborator'
        } else if (status === "3") {
            return 'Derguar'
        } else {
            return "Mbyllur"
        }

    }

    const colorRender = (status) => {
        if (status === "1") {
            return '#2677F1'
        } else if (status === "2") {
            return '#5FB597'
        } else if (status === "3") {
            return '#73C850'
        } else {
            return "#FF0000"
        }
    }

    const filteredPacient = pacientet.filter(pacient =>
        pacient.id.toString().toLowerCase().includes(search.toLowerCase()) ||
        pacient.emri.toString().toLowerCase().includes(search.toLowerCase()) ||
        pacient.mbiemri.toString().toLowerCase().includes(search.toLowerCase())
    )


    return (
        <>
        {loading && <Loading /> }
        <div className="lista-klinikave" >
            <div className="lista-klinikave-header">
                <div className="lista-klinikave-header-left">
                    <p className="lista-klinikave-header-left-title fs-22 fw-bold">Lista e Pacienteve ({pacientet.length})</p>
                </div>
                <div className="lista-klinikave-header-right">
                    <Input type="search"
                        placeholder="Search..."
                        onChange={(e) => setSearch(e.target.value)}
                        startAdornment={
                            <InputAdornment position="start">
                                <svg id="Vector_Smart_Object" data-name="Vector Smart Object" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                    <path id="Path_33" data-name="Path 33" d="M12.864,11.321h-.813l-.288-.278a6.7,6.7,0,1,0-.72.72l.278.288v.813L16.467,18,18,16.467Zm-6.175,0A4.631,4.631,0,1,1,11.321,6.69,4.625,4.625,0,0,1,6.69,11.321Z" fill="#ccd4e0" />
                                </svg>

                            </InputAdornment>
                        }
                        className="lista-klinikave-header-right-search"
                    />
                    <button className="lista-klinikave-header-right-print fs-16 fw-500" onClick={handlePrint}>
                        <svg id="printer_1_" data-name="printer (1)" xmlns="http://www.w3.org/2000/svg" width="18.438" height="16.71" viewBox="0 0 18.438 16.71">
                            <path id="Path_520" data-name="Path 520" d="M99.74,2.881H88.36a.36.36,0,0,1-.36-.36V1.585A1.585,1.585,0,0,1,89.585,0h8.931A1.585,1.585,0,0,1,100.1,1.585v.936A.36.36,0,0,1,99.74,2.881Z" transform="translate(-84.831 0)" fill="#fff" />
                            <path id="Path_521" data-name="Path 521" d="M16.494,112H1.945A1.947,1.947,0,0,0,0,113.945v6.77a1.947,1.947,0,0,0,1.945,1.945H3.169v-2.881a2.6,2.6,0,0,1,2.593-2.593h6.914a2.6,2.6,0,0,1,2.593,2.593v2.881h1.224a1.947,1.947,0,0,0,1.945-1.945v-6.77A1.947,1.947,0,0,0,16.494,112ZM3.458,116.322a.864.864,0,0,1,0-1.729h0a.864.864,0,0,1,0,1.729Z" transform="translate(0 -107.967)" fill="#fff" />
                            <path id="Path_522" data-name="Path 522" d="M143.779,304h-6.914a.864.864,0,0,0-.864.864V308.9a.864.864,0,0,0,.864.864h6.914a.864.864,0,0,0,.864-.864v-4.033A.864.864,0,0,0,143.779,304Z" transform="translate(-131.102 -293.052)" fill="#fff" />
                        </svg>

                        Print
                    </button>
                </div>
            </div>
            <section className="lista-klinikave-datatable" ref={componentRef}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="fs-22" >ID</TableCell>
                            <TableCell>Emri i Pacientit</TableCell>
                            <TableCell>Data e Regjistrimit</TableCell>
                            <TableCell>Statusi</TableCell>
                            <TableCell>Dergesa</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {filteredPacient.slice(start, end).map((pacient, index) => (
                            <TableRow>
                                <TableCell><Link style={{ color: 'black', textDecoration: 'none' }} to={`/mjek/pacientet/${pacient.id}`} >#{pacient.id}</Link>  </TableCell>
                                <TableCell> <Link style={{ color: 'black', textDecoration: 'none' }} to={`/mjek/pacientet/${pacient.id}`}> {pacient.emri} {pacient.mbiemri} </Link> </TableCell>
                                <TableCell className="table-col" >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.444" height="15.96" viewBox="0 0 14.444 15.96">
                                        <g id="calendar_4_" data-name="calendar (4)" transform="translate(-24.263)">
                                            <path id="Path_500" data-name="Path 500" d="M135.621,4.031a.5.5,0,0,1-.5-.5V.5a.5.5,0,1,1,1,0V3.531A.5.5,0,0,1,135.621,4.031Z" transform="translate(-107.396 0)" fill="#2677f1" />
                                            <path id="Path_501" data-name="Path 501" d="M344.372,4.031a.5.5,0,0,1-.5-.5V.5a.5.5,0,1,1,1,0V3.531A.5.5,0,0,1,344.372,4.031Z" transform="translate(-309.627 0)" fill="#2677f1" />
                                            <path id="Path_502" data-name="Path 502" d="M24.263,221.975v6.9A2.134,2.134,0,0,0,26.395,231H36.576a2.134,2.134,0,0,0,2.132-2.132v-6.9Z" transform="translate(0 -215.042)" fill="#2677f1" />
                                            <path id="Path_503" data-name="Path 503" d="M38.707,52.943V50.657a2.134,2.134,0,0,0-2.132-2.132h-.331V50.54a1.5,1.5,0,1,1-3,0V48.525H29.724V50.54a1.5,1.5,0,1,1-3,0V48.525h-.331a2.134,2.134,0,0,0-2.132,2.132v2.286H38.707Z" transform="translate(0 -47.009)" fill="#2677f1" />
                                        </g>
                                    </svg>
                                    {pacient.data_regj}
                                </TableCell>

                                <TableCell style={{ position: 'relative' }} >
                                    <p className="status-text" style={{ color: colorRender(pacient.status) }} >
                                        <span className="status-pulse" style={{ backgroundColor: colorRender(pacient.status) }} ></span>
                                        {textRender(pacient.status)}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5.711" viewBox="0 0 9 5.711" onClick={(e) => setStatusDropDown(index)} >
                                            <path id="Path_792" data-name="Path 792" d="M4.5,0,9,5.711H0Z" transform="translate(9 5.711) rotate(180)" fill={colorRender(pacient.status)} />
                                        </svg>
                                    </p>
                                    {statusDropdown === index &&
                                        <ClickAwayListener onClickAway={() => setStatusDropDown(-1)}>
                                            <div className="status-dropdwon" >
                                                <p className="fs-16 fw-300"
                                                    onClick={() => {
                                                        axios.post("https://panel.at-aligners.com/server/mjek/changePacientStatus", { pacient_id: pacient.id, status: 1 }).then(res => {
                                                            if (res.data.status === 1) {
                                                                alertContext.setAlert(`${res.data.message}`, 'success')
                                                                setStatusDropDown(-1)
                                                                axios.post('https://panel.at-aligners.com/server/mjek/getPacients', { mjek_id: JSON.parse(localStorage.getItem('id')) }).then(res => {
                                                                    setPacientet(res.data)
                                                                })
                                                            } else {
                                                                alertContext.setAlert(`${res.data.message}`, 'error')
                                                            }
                                                        })
                                                    }}
                                                >
                                                    Ne pritje
                                                </p>
                                                <p className="fs-16 fw-300"
                                                    onClick={() => {
                                                        axios.post("https://panel.at-aligners.com/server/mjek/changePacientStatus", { pacient_id: pacient.id, status: 2 }).then(res => {
                                                            if (res.data.status === 1) {
                                                                alertContext.setAlert(`${res.data.message}`, 'success')
                                                                setStatusDropDown(-1)
                                                                axios.post('https://panel.at-aligners.com/server/mjek/getPacients', { mjek_id: JSON.parse(localStorage.getItem('id')) }).then(res => {
                                                                    setPacientet(res.data)
                                                                })
                                                            } else {
                                                                alertContext.setAlert(`${res.data.message}`, 'error')
                                                            }
                                                        })
                                                    }}
                                                >
                                                    3D Laborator
                                                </p>
                                                <p className="fs-16 fw-300"
                                                    onClick={() => {
                                                        axios.post("https://panel.at-aligners.com/server/mjek/changePacientStatus", { pacient_id: pacient.id, status: 3 }).then(res => {
                                                            if (res.data.status === 1) {
                                                                alertContext.setAlert(`${res.data.message}`, 'success')
                                                                setStatusDropDown(-1)
                                                                axios.post('https://panel.at-aligners.com/server/mjek/getPacients', { mjek_id: JSON.parse(localStorage.getItem('id')) }).then(res => {
                                                                    setPacientet(res.data)
                                                                })
                                                            } else {
                                                                alertContext.setAlert(`${res.data.message}`, 'error')
                                                            }
                                                        })
                                                    }}

                                                >
                                                    Derguar</p>
                                                <p className="fs-16 fw-300"

                                                    onClick={() => {
                                                        axios.post("https://panel.at-aligners.com/server/mjek/changePacientStatus", { pacient_id: pacient.id, status: 4 }).then(res => {
                                                            if (res.data.status === 1) {
                                                                alertContext.setAlert(`${res.data.message}`, 'success')
                                                                setStatusDropDown(-1)
                                                                axios.post('https://panel.at-aligners.com/server/mjek/getPacients', { mjek_id: JSON.parse(localStorage.getItem('id')) }).then(res => {
                                                                    setPacientet(res.data)
                                                                })
                                                            } else {
                                                                alertContext.setAlert(`${res.data.message}`, 'error')
                                                            }
                                                        })
                                                    }}
                                                >
                                                    Anulluar</p>
                                            </div>
                                        </ClickAwayListener>}

                                </TableCell>
                                <TableCell>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.237" height="18.233" viewBox="0 0 18.237 18.233" style={{ marginRight: '10px', cursor: 'pointer' }}  >
                                        <g id="approval" transform="translate(0 -0.005)">
                                            <path id="Path_508" data-name="Path 508" d="M15.939,11a4.939,4.939,0,1,0,4.939,4.939A4.945,4.945,0,0,0,15.939,11Zm2.141,3.983-2.47,2.85a.57.57,0,0,1-.41.2h-.021a.567.567,0,0,1-.4-.167l-1.33-1.33a.57.57,0,1,1,.806-.806l.9.9,2.068-2.387a.57.57,0,1,1,.861.748Z" transform="translate(-2.641 -2.64)" fill="#2db824" />
                                            <path id="Path_509" data-name="Path 509" d="M10.393,1.209l-6.9,3.48L.75,3.36,7.528.062a.564.564,0,0,1,.494,0Z" transform="translate(-0.18)" fill="#2db824" />
                                            <path id="Path_510" data-name="Path 510" d="M16.08,3.94,9.059,7.367,6.43,6.09,6.05,5.9l6.907-3.48.38.19Z" transform="translate(-1.453 -0.58)" fill="#2db824" />
                                            <path id="Path_511" data-name="Path 511" d="M7.036,13.076a6.51,6.51,0,0,0-.2,1.6,6.388,6.388,0,0,0,.2,1.581v.106L.312,12.9A.578.578,0,0,1,0,12.392V5.72L2.85,7.1V9.535a.57.57,0,0,0,1.14,0V7.665l.38.182,2.675,1.3Z" transform="translate(0 -1.372)" fill="#2db824" />
                                            <path id="Path_512" data-name="Path 512" d="M17.791,8.5a6.335,6.335,0,0,0-1.907-.289,6.432,6.432,0,0,0-5.114,2.523v-1.6L17.784,5.73c.008.638.008,1.884.008,2.774Z" transform="translate(-2.586 -1.375)" fill="#2db824" />
                                        </g>
                                    </svg>

                                    {pacient.ergesa === "" || activeIndex === index ?
                                        <DatePicker
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            onChange={(e) => {
                                                axios.post('https://panel.at-aligners.com/server/lab/updateDergesa', { dergesa: e, pacient_id: pacient.id }).then(res => {
                                                    if (res.data.status === 1) {

                                                        axios.post('https://panel.at-aligners.com/server/mjek/getPacients', { mjek_id: JSON.parse(localStorage.getItem('id')) }).then(res => {
                                                            setPacientet(res.data)
                                                        })
                                                        setActiveIindex(-1)
                                                    }
                                                })
                                            }}

                                            clearIcon={false}
                                            selected={pacient.pacient_dergesa}
                                        />

                                        :
                                        <>
                                            {pacient.dergesa}
                                            <svg style={{ marginLeft: '10px', cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="9" height="5.711" viewBox="0 0 9 5.711" onClick={(e) => setActiveIindex(index)} >
                                                <path id="Path_792" data-name="Path 792" d="M4.5,0,9,5.711H0Z" transform="translate(9 5.711) rotate(180)" fill={'#5FB597'} />
                                            </svg>
                                        </>
                                    }
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </section>
            <div className="table-pagination">
                <Pagination count={Math.ceil(filteredPacient.length / itemPage)} showFirstButton showLastButton onChange={handleChange} />
            </div>
            <Footer />
        </div>
        </>
    )
}
